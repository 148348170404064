import { isSafari } from './browser_compat';

const v = 84;
const debug = true;

function headers_to_a(h) {
  let rtn = {};
  for (let pair of h.entries()) {
    let key = pair[0];
    let val = pair[1];
    if (!rtn[key]) rtn[key] = val;
    else if (Array.isArray(rtn[key])) rtn[key].push(val);
    else rtn[key] = [rtn[key], val];
  }
  return rtn;
}

function dumpheaders(r, msg) {
  for (let pair of r.headers.entries()) {
    cl('...header', msg, pair[0], pair[1]);
  }
}

function cl(msg, ...args) {
  debug && console.log(msg, v, ...args);
}

function clrequest(msg, request) {
  debug &&
    console.log(
      `${msg} request`,
      v,
      request.method,
      request.url,
      request.destination,
      headers_to_a(request.headers)
    );
}

function clrequestfull(msg, request) {
  // test here for safari due to https://bugs.webkit.org/show_bug.cgi?id=203617
  debug && !isSafari && console.log(`${msg} request full`, v, request);
}

function clresponse(msg, resp) {
  debug &&
    console.log(
      `${msg} response`,
      v,
      resp.status,
      resp.type,
      headers_to_a(resp.headers)
    );
}

function clcache(msg, cacheName, request, response) {
  debug && console.log(`cache ${msg}`, v, cacheName, request.url);
}

export { v, debug, cl, clrequest, clrequestfull, clresponse, clcache };
