import React from 'react';

import {
  Camera24,
  DrillDown24,
  PressureFilled24,
  Table24,
  TaskView24,
  TrafficCone24,
} from '@carbon/icons-react';
import { Mountain24, Road24 } from './icons';

export function icon_for(tool) {
  if (!tool || !tool.icon) return <Table24 />;
  if (tool.icon === 'camera') return <Camera24 />;
  if (tool.icon === 'drilldown') return <DrillDown24 />; // For Boring Log.
  if (tool.icon === 'incident') return <TrafficCone24 />; // For Incident Log.
  if (tool.icon === 'pressure') return <PressureFilled24 />; // For Concrete Report.
  if (tool.icon === 'taskview') return <TaskView24 />; // For Daily Observation.
  if (tool.icon === 'mountain') return <Mountain24 />; // for Soil and Aggregate.
  if (tool.icon === 'road') return <Road24 />; // for Asphalt.
  if (tool.icon === 'table') return <Table24 />;
  return <Table24 />;
}

export function report_name_for_report_type(report_type) {
  return (
    {
      asphalt_reports: 'Asphalt Report',
      boring_logs: 'Boring Log',
      ccst_reports: 'Concrete Report',
      incident_reports: 'Incident Report',
      observation_reports: 'Observation Report',
      photo_logs: 'Photo Log',
      soil_reports: 'Soil and Aggregate Report',
    }[report_type] || 'Unknown Report Type'
  );
}
