import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import api from '../../api';
import { Route, Switch } from 'react-router-dom';
import { full_redux_state } from '../../redux/connectors';
import { isStoreEmpty } from '../../localforage';

import Project from '../Project';
import ProjectPack from '../Project/Pack';
import ProjectsIndex from './ProjectsIndex';
import LoginForm from '../LoginForm';

function Projects({ user, offline, logged_in, history }) {
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    (async () => {
      if (!logged_in) return;
      const projects = (await api.get('projects')).sort((a, b) => {
        // sort by .activity_at desc
        a = Date.parse(a.activity_at);
        b = Date.parse(b.activity_at);
        if (b < a) return -1;
        if (b > a) return 1;
        return 0;
      });
      setProjects(projects);
    })();
  }, [logged_in]);

  useEffect(() => {
    // if there's nothing in cached select_lists, then fetch them.  This assists in creation of new instances offline before we've seen real objects with them
    // can be done async
    (async () => {
      if (await isStoreEmpty('select_lists')) api.get('select_lists');
    })();
  }, []);

  console.log('t1', 'not logged in here, gonna return login form', logged_in);
  if (!logged_in) return <LoginForm />;

  const handleReloadProject = async id => {
    const project = await api.get(`projects/${id}`);
    handleProjectSave(project);
  };

  const handleProjectClick = id => history.push(`/projects/${id}`);

  const handleProjectAdd = new_project => {
    // in early components they did the API work, so we don't need to here
    setProjects([new_project].concat(projects));
  };

  const handleProjectSave = new_project => {
    if (new_project && projects)
      setProjects(
        projects.map(project =>
          project.id == new_project.id ? new_project : project
        )
      );
  };

  const handleMarkHidden = project => {
    _markHidden(project, true);
  };
  const handleMarkUnhidden = project => {
    _markHidden(project, false);
  };
  const _markHidden = async (project, hidden) => {
    try {
      const is_ok_response = response => {
        return response && response.id;
      };
      const create_response_error_to_string = response => {
        return _.map(_.keys(response), key => `${key} ${response[key]}`).join(
          '\n'
        );
      };

      let endpoint = `projects/${project.id}.json`;

      let project_to_put = _.pick(project, ['id', 'hidden']);
      project_to_put.hidden = hidden;

      project_to_put = api.user_audit_at_helper(project_to_put, 'project');

      const resp = await api.put(endpoint, null, {
        project: project_to_put,
      });
      if (is_ok_response(resp)) {
        handleProjectSave(Object.assign({}, project, { hidden: hidden }));
      } else {
        alert(create_response_error_to_string(resp));
      }
    } catch (err) {
      alert('Sorry, there was an unexpected error.');
      console.log(err);
    }
  };

  return (
    <Switch>
      <Route
        path="/projects/:id/pack"
        render={({ match }) => (
          <ProjectPack
            id={match.params.id}
            projects={projects}
            history={history}
            onReloadProject={handleReloadProject}
          />
        )}
      />
      <Route
        path="/projects/:id"
        render={({ match }) => (
          <Project
            id={match.params.id}
            projects={projects}
            history={history}
            onMarkHidden={handleMarkHidden}
            onMarkUnhidden={handleMarkUnhidden}
          />
        )}
      />
      <Route
        render={() => (
          <ProjectsIndex
            projects={projects}
            onDetailClick={handleProjectClick}
            onProjectAdd={handleProjectAdd}
            onProjectSave={handleProjectSave}
            onMarkHidden={handleMarkHidden}
            onMarkUnhidden={handleMarkUnhidden}
          />
        )}
      />
    </Switch>
  );
}

export default connect(full_redux_state)(Projects);
