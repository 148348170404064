import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for, month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';

import {
  Loading,
  InlineNotification,
  Button,
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  TimePicker,
  Select,
  SelectItem,
  SelectItemGroup,
  Form,
} from 'carbon-components-react';
import { ArrowLeft16, Add16, Save16 } from '@carbon/icons-react';
import Breadcrumbs from '../shared/Breadcrumbs';
import RichTextArea from '../shared/RichTextArea';

function ObservationReport({
  id,
  user,
  offline,
  observation_reports,
  handleUpdateObservationReport,
  onCreateObservationReport,
  onUpdateObservationReport,
  history,
}) {
  let initial_observation_report = null;
  if (Array.isArray(observation_reports)) {
    initial_observation_report = observation_reports.find(
      pl => to_s(pl.id) == id
    );
  }

  const form_keys = [
    'contractor',
    'weather_am',
    'weather_pm',
    'date_made',
    'report_number',
    'report_body',
    'time_arrived',
    'time_departed',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for Observation

  const [observation_report, setObservationReport] = useState(
    initial_observation_report
  );
  const editable = true; // was !observation_report.approved

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    ...(initial_observation_report ? initial_observation_report : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = updatedValues =>
    _setFormState(prevState => {
      return { ...form_state, ...updatedValues };
    });

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    invalids = fh.validate(
      invalids,
      'contractor',
      form_state.contractor,
      'Must provide a contractor'
    );
    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const handle_submit = async e => {
    return await fh.handle_submit({
      e,
      endpoint: 'observation_reports',
      model: 'observation_report',
      state: form_state,
      fields: form_keys,
      valid,
      onCreate: onCreateObservationReport,
      onUpdate: onUpdateObservationReport,
      clear_form: () => false, // noop for this form
      onSuccess: () => {
        history.push(to);
      },
    });
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: setFormState,
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };
  // END form handling boilerplate and custom

  const reloadData = async () => {
    // TODO: lots of floating this up with handleUpdateObservationReport.... should put this in redux
    const observation_report = await api.get(`observation_reports/${id}`);
    if (handleUpdateObservationReport)
      handleUpdateObservationReport(observation_report);
    setObservationReport(observation_report);
    setFormState(observation_report);
  };
  useEffect(() => {
    if (!observation_report) reloadData();
  }, []);

  const project_name =
    observation_report && observation_report.project
      ? observation_report.project.name
      : '';
  const code =
    observation_report && observation_report.project
      ? observation_report.project.code
      : '';
  const when =
    observation_report && observation_report.logged_at
      ? full_month_day_year_for(observation_report.logged_at)
      : '';
  const to =
    observation_report && observation_report.project
      ? `/observation_reports/projects/${observation_report.project.id}`
      : '';

  function audit_markup() {
    if (
      !observation_report ||
      !observation_report.audits ||
      observation_report.audits.length === 0
    )
      return '';
    const li_for = function(audit) {
      let user_info = 'an unknown user';
      if (audit.user)
        user_info = <a href={`/users/${audit.user.id}`}>{audit.user.name}</a>;
      const friendly_message =
        audit.friendly_message || 'did something unknown';
      return (
        <li key={audit.id}>
          At {moment(audit.user_audit_at).format('YYYY/MM/DD h:mm A')}{' '}
          {user_info} {friendly_message}
        </li>
      );
    };
    return (
      <div className="mobile-hide">
        <h3>Audit Trail</h3>
        <ul>{observation_report.audits.map(audit => li_for(audit))}</ul>
      </div>
    );
  }

  function reports_markup() {
    if (
      !observation_report ||
      !observation_report.reports ||
      observation_report.reports.length === 0
    )
      return '';
    return (
      <div className="mobile-hide">
        <h3>Generated Reports</h3>
        <ul>
          {observation_report.reports.map(report => (
            <li key={report.id}>
              <a
                href={api.fixup_url(
                  `observation_reports/${observation_report.id}/report/${
                    report.id
                  }`
                )}
              >
                Report generated at{' '}
                {moment(report.created_at).format('YYYY/MM/DD h:mm A')}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  let content = <Loading small={false} active={true} />;
  if (!observation_report) {
    content = <Loading small={false} active={true} />;
  } else if (!observation_report.id && offline) {
    content = (
      <p>
        You are offline, and we do not have this Daily Observation Report
        cached, sorry.
      </p>
    );
  } else if (!observation_report.id && !offline) {
    content = (
      <p>
        You do not have access to that Daily Observation Test Report, or it does
        not exist.
      </p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title={`${!editable ? 'Viewing' : 'Editing'} Report`} />
        {user.role === 'admin' &&
          observation_report.has_any_report &&
          !observation_report.has_current_report && (
            <InlineNotification
              title="There have been changes to this Daily Observation Report since the last report was generated."
              subtitle="You can re-generate the report."
              kind="info"
              hideCloseButton={true}
              className="mobile-hide"
            />
          )}
        <Form onSubmit={handle_submit}>
          <TextInput {...vfb('contractor')} labelText="Contractor" />
          <TextInput {...vfb('weather_am')} labelText="Weather AM" />
          <TextInput {...vfb('weather_pm')} labelText="Weather PM" />
          <TextInput {...vfb('time_arrived')} labelText="Time Arrived" />
          <TextInput {...vfb('time_departed')} labelText="Time Departed" />
          <DatePicker
            id="date_made"
            datePickerType="single"
            onChange={v =>
              fh.handle_nonstandard_input({
                field: 'date_made',
                value: v[0],
                state: form_state,
                setState: setFormState,
              })
            }
            dateFormat="Y/m/d"
            value={form_state.date_made}
          >
            <DatePickerInput
              id="date_made-inner"
              labelText="Date"
              pattern="\d{4}\/\d{1,2}\/\d{1,2}"
            />
          </DatePicker>
          <TextInput {...vfb('report_number')} labelText="Report Number" />
          <RichTextArea {...vfb('report_body')} labelText="Report Body" />
          {editable && (
            <Button renderIcon={Save16} type="submit">
              Save
            </Button>
          )}
        </Form>
        {user.role === 'admin' && audit_markup(observation_report)}
        {user.role === 'admin' && reports_markup()}
      </>
    );
  }
  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="observation_reports"
          obj={observation_report}
          second="Daily Observation Reports"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(ObservationReport);
