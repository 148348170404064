const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const GO_ONLINE = 'GO_ONLINE';
const GO_OFFLINE = 'GO_OFFLINE';
const START_SYNC = 'START_SYNC';
const END_SYNC = 'END_SYNC';
const MARK_UNSAVED_CHANGE = 'MARK_UNSAVED_CHANGE';
const CLEAR_UNSAVED_CHANGE = 'CLEAR_UNSAVED_CHANGE';
const UPDATE_STORAGE = 'UPDATE_STORAGE';

export function login(user, csrf_token) {
  return {
    type: LOGIN,
    user,
    csrf_token,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function go_online() {
  return {
    type: GO_ONLINE,
  };
}

export function go_offline() {
  return {
    type: GO_OFFLINE,
  };
}

export function start_sync() {
  return {
    type: START_SYNC,
  };
}

export function end_sync() {
  return {
    type: END_SYNC,
  };
}

export function mark_unsaved_change() {
  return {
    type: MARK_UNSAVED_CHANGE,
  };
}

export function clear_unsaved_change() {
  return {
    type: CLEAR_UNSAVED_CHANGE,
  };
}

export async function update_storage() {
  const quota =
    navigator.storage &&
    navigator.storage.estimate &&
    (await navigator.storage.estimate());
  return {
    type: UPDATE_STORAGE,
    payload: quota,
  };
}
