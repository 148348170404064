import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import api from '../api';
import { full_redux_state } from '../redux/connectors';

import {
  Camera24,
  PressureFilled24,
  Table24,
  TaskView24,
  TrafficCone24,
  DrillDown24,
} from '@carbon/icons-react';
import { Dropdown, Tile } from 'carbon-components-react';

import { default as LoginForm } from './LoginForm';
import { icon_for } from './shared/tools';

class Home extends Component {
  render() {
    const { user, logged_in } = this.props;
    return (
      <>
        {!logged_in && <LoginForm />}
        {logged_in && (
          <>
            <header className="banner">
              <p className="welcome">Welcome, {user.name}.</p>
            </header>
            <h3 className="tools-list-header">Your Terradon Tools</h3>
            <ul>
              {user.tools.map(tool => (
                <li key={tool.id}>
                  {tool.implemented && (
                    <Link to={tool.path} className="tool-tile">
                      <Tile className="tool-tile">
                        <div className="icon">{icon_for(tool)}</div>
                        <div>
                          <p className="title">{tool.title}</p>
                          <p>{tool.description}</p>
                        </div>
                      </Tile>
                    </Link>
                  )}
                  {!tool.implemented && (
                    <Tile className="tool-tile">
                      <div className="icon">{icon_for(tool)}</div>
                      <div>
                        <p className="title">{tool.title}</p>
                        <p>{tool.description}</p>
                        <p>Coming Soon</p>
                      </div>
                    </Tile>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </>
    );
  }
}

export default connect(full_redux_state)(Home);
