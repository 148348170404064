import { cl } from './debug';

function to_s(key) {
  if (!key) return key;
  if (key && key.constructor && key.constructor.name === 'String') return key;
  if (key.toString) return key.toString();
  cl("don't know how to make this into a key", key);
  throw `don't know how to make this into a key ${key}`;
}

export { to_s };
