// TODO: see https://redux-docs.netlify.com/basics/reducers for combineReducers

const initialState = {
  user: null,
  online: 'unknown',
  syncing: false,
  unsaved: false,
  quota: null,
};

export default function terradonApp(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN':
      return Object.assign({}, state, {
        user: action.user,
      });
    case 'LOGOUT':
      return Object.assign({}, state, { user: null });
    case 'GO_ONLINE':
      return Object.assign({}, state, { online: 'online' });
    case 'GO_OFFLINE':
      return Object.assign({}, state, { online: 'offline' });
    case 'START_SYNC':
      return Object.assign({}, state, { syncing: true });
    case 'END_SYNC':
      return Object.assign({}, state, { syncing: false });
    case 'MARK_UNSAVED_CHANGE':
      return Object.assign({}, state, { unsaved: true });
    case 'CLEAR_UNSAVED_CHANGE':
      return Object.assign({}, state, { unsaved: false });
    case 'UPDATE_STORAGE':
      return Object.assign({}, state, { quota: action.payload });
    default:
      return state;
  }
}
