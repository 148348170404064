import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import { full_redux_state } from '../../redux/connectors';
import { to_s } from '../../common';

import ProjectIncidentReports from './ProjectIncidentReports';
import IncidentReport from '../IncidentReport';
import IncidentReportEquipment from '../IncidentReportEquipment';
import IncidentReportStatement from '../IncidentReportStatement';

import { Loading, Button } from 'carbon-components-react';

function IncidentReports({ user, offline, history }) {
  const [incident_reports, setIncidentReports] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    (async () => {
      const projects = await api.get('projects');
      setProjects(projects);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const incident_reports = (await api.get('incident_reports')).sort(
        (a, b) => {
          // sort by .logged_at desc
          a = Date.parse(a.logged_at);
          b = Date.parse(b.logged_at);
          if (b < a) return -1;
          if (b > a) return 1;
          return 0;
        }
      );
      setIncidentReports(incident_reports);
    })();
  }, []);

  const handleProjectClick = id =>
    history.push(`/incident_reports/projects/${id}`);
  const handleIncidentReportClick = id =>
    history.push(`/incident_reports/${id}`);

  const handleNewLogClick = async project_id => {
    const incident_report = api.user_audit_at_helper(
      { project_id },
      'incident_report'
    );
    const resp = await api.post('incident_reports', null, { incident_report });
    if (resp && resp.id) {
      setIncidentReports([resp].concat(incident_reports));
      history.push(`/incident_reports/${resp.id}`);
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  };

  const handleUpdateIncident = new_incident_report => {
    if (new_incident_report && incident_reports)
      setIncidentReports(
        incident_reports.map(incident_report =>
          incident_report.id == new_incident_report.id
            ? new_incident_report
            : incident_report
        )
      );
  };

  return (
    <Switch>
      <Route
        path="/incident_reports/:id/equipment/:equipment_id"
        render={({ match }) => (
          <IncidentReportEquipment
            id={match.params.id}
            equipment_id={match.params.equipment_id}
            incident_reports={incident_reports}
            onUpdateIncidentEquipment={handleUpdateIncident}
            history={history}
          />
        )}
      />
      <Route
        path="/incident_reports/:id/statement/:statement_id"
        render={({ match }) => (
          <IncidentReportStatement
            id={match.params.id}
            statement_id={match.params.statement_id}
            incident_reports={incident_reports}
            onUpdateIncidentStatement={handleUpdateIncident}
            history={history}
          />
        )}
      />
      <Route
        path="/incident_reports/projects/:id"
        render={({ match }) => {
          const project = (projects || []).find(
            p => to_s(p.id) === match.params.id
          );
          return (
            <ProjectIncidentReports
              id={match.params.id}
              project={project}
              incident_reports={incident_reports}
              onDetailClick={handleIncidentReportClick}
              onNewLogClick={handleNewLogClick}
              onUpdateIncidentReport={handleUpdateIncident}
            />
          );
        }}
      />
      <Route
        path="/incident_reports/:id"
        render={({ match }) => (
          <IncidentReport
            id={match.params.id}
            incident_reports={incident_reports}
            onUpdateIncidentReport={handleUpdateIncident}
            history={history}
          />
        )}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export default connect(full_redux_state)(IncidentReports);
