import React, { useState } from 'react';
import { connect } from 'react-redux';
import { full_redux_state } from '../../redux/connectors';
import { Link } from 'react-router-dom';
import { month_day_year_for } from '../../datetime';
import { to_s } from '../../common';
import api from '../../api';
import { cl } from '../../debug';
import ToolHeadline from '../shared/ToolHeadline';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
  Loading,
} from 'carbon-components-react';

import {
  ChevronRight16,
  Camera16,
  ArrowLeft16,
  Add16,
} from '@carbon/icons-react';
import Breadcrumbs from '../shared/Breadcrumbs';

// WIP: re-introduce the "View / Make Report" button on desktop

//  /ccst_reports/projects/:id -> ./ProjectCCSTReports
function ProjectCCSTReports({
  id /* :id in the matching url */,
  project,
  ccst_reports,
  onDetailClick,
  onNewLogClick,
  offline,
  user,
  onUpdateCCSTReport,
}) {
  if (!Array.isArray(ccst_reports))
    return <Loading small={false} active={true} />;

  project = project || {};
  ccst_reports = ccst_reports || [];
  ccst_reports = ccst_reports.filter(
    pl =>
      (pl.project && to_s(pl.project.id) === id) || to_s(pl.project_id) === id
  );

  const [ccst_report_reporting, setCCSTReportReporting] = useState({}); // keys are IDs of CCSTReport with report being generated, empty indicates none are being generated right now

  const [
    approval_modal_ccst_report_id,
    setApprovalModalCCSTReportId,
  ] = useState(null);

  const no_logs_message = offline
    ? `Sorry, you're offline and we don't have any Concrete Reports cached for this project`
    : `No Concrete Reports created for this project`;

  const reporting_button_for = ccst_report => {
    if (user.role !== 'admin') return '';

    if (ccst_report_reporting[ccst_report.id]) {
      return <Loading small={true} active={true} />;
    } else {
      return (
        <>
          {ccst_report.has_any_report && (
            <a
              href={api.fixup_url(`ccst_reports/${ccst_report.id}/report`)}
              onClick={evt => {
                evt.stopPropagation();
              }}
            >
              Download Approved Report
            </a>
          )}
          {!ccst_report.has_current_report && (
            <Button
              onClick={evt => {
                evt.stopPropagation();
                setApprovalModalCCSTReportId(ccst_report.id);
              }}
            >
              Preview Report
            </Button>
          )}
        </>
      );
    }
  };

  const handleApproveReport = async report_id => {
    setCCSTReportReporting({
      ...ccst_report_reporting,
      [report_id]: true,
    });
    setApprovalModalCCSTReportId(null);

    console.log('t8', 'about to POST to approve_report', report_id);

    const response = await api.post(`ccst_reports/${report_id}/approve_report`);

    console.log('t8', 'got POST response ', response);

    // TODO: use redux to update the global ccst_reports
    onUpdateCCSTReport(response);

    setCCSTReportReporting({
      ...ccst_report_reporting,
      [report_id]: false,
    });
  };

  const banner = (
    <div className="tool-banner">
      <Breadcrumbs project_id={project.id} />
      <p className="project-code">{project.code}</p>
      <p className="name">{project.name}</p>
    </div>
  );
  if (approval_modal_ccst_report_id) {
    return (
      <>
        {banner}
        <h2>Review and Approve Report</h2>
        <iframe
          className="terradon--preview-modal"
          src={api.fixup_url(
            `ccst_reports/${approval_modal_ccst_report_id}/preview_report`
          )}
        />
        <div className="terradon--align-right">
          <Button
            kind="ghost"
            onClick={() => setApprovalModalCCSTReportId(null)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleApproveReport(approval_modal_ccst_report_id)}
          >
            Approve
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      {banner}
      <ToolHeadline title="Reports">
        <Button renderIcon={Add16} onClick={() => onNewLogClick(id)}>
          New Report
        </Button>
      </ToolHeadline>
      <StructuredListWrapper selection>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Date</StructuredListCell>
            <StructuredListCell className="mobile-hide" head>
              Report
            </StructuredListCell>
            <StructuredListCell head />
          </StructuredListRow>
        </StructuredListHead>

        <StructuredListBody>
          {ccst_reports.length > 0 &&
            ccst_reports.map(ccst_report => (
              <StructuredListRow
                key={ccst_report.id}
                onClick={() => onDetailClick(ccst_report.id)}
              >
                <StructuredListCell>
                  {month_day_year_for(ccst_report.logged_at)}
                </StructuredListCell>
                <StructuredListCell className="mobile-hide">
                  {reporting_button_for(ccst_report)}
                </StructuredListCell>
                <StructuredListCell>
                  <ChevronRight16 />
                </StructuredListCell>
              </StructuredListRow>
            ))}
          {ccst_reports.length === 0 && (
            <StructuredListRow>
              <StructuredListCell>{no_logs_message}</StructuredListCell>
            </StructuredListRow>
          )}
        </StructuredListBody>
      </StructuredListWrapper>
    </>
  );
}

export default connect(full_redux_state)(ProjectCCSTReports);
