import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { full_redux_state } from '../../redux/connectors';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  Loading,
  Checkbox,
  TextInput,
  Form,
  Select,
  SelectItem,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
} from 'carbon-components-react';

import TdSelect from '../shared/Select.js';

import { ArrowLeft16 } from '@carbon/icons-react';
import { handle_submit } from '../../form_helpers';

function SelectList({
  user,
  offline,
  history,
  code,
  select_list,
  onSaveSelectList,
}) {
  if (!user.role === 'admin') return <p>Unauthorized</p>;
  if (!select_list) return <Loading small={false} active={true} />;

  const [sl, setSelectListLocal] = useState(select_list);

  const [edit_idx, setEditIdx] = useState(-1);
  const [editing_row, setEditingRow] = useState();
  const [new_row, setNewRow] = useState({ code: '', label: '' });

  // helper: is the API response body for the action an OK?
  const is_ok_response = response => {
    return response && response.id;
  };

  // helper: convert the create response body to an error string
  const create_response_error_to_string = response => {
    return _.map(_.keys(response), key => `${key} ${response[key]}`).join('\n');
  };

  const save = async e => {
    try {
      // DEBT: don't PUT if there's been no data change (?)

      // endpoint / method is PUT here because only programmers can add new select lists, not admins.  There is no "new list" version of this form
      let endpoint = `select_lists/${select_list.id}.json`;

      let select_list_to_post = _.pick(sl, [
        'id',
        'option_shown_title',
        'show_text_and_value',
        'show_title_option',
        'value_title',
      ]);
      select_list_to_post = api.user_audit_at_helper(
        select_list_to_post,
        'select_list'
      );
      select_list_to_post.options = JSON.stringify(sl.options);

      const resp = await api.put(endpoint, null, {
        select_list: select_list_to_post,
      });
      if (is_ok_response(resp)) {
        onSaveSelectList(sl);
        history.push(`/admin_settings`);
      } else {
        alert(create_response_error_to_string(resp));
      }
    } catch (err) {
      alert('Sorry, there was an unexpected error.');
      console.log(err);
    }
  };

  const value_title = sl =>
    sl.value_title
      ? `${sl.value_title} (Database / gINT Value)`
      : `Database / gINT Value`;

  const label_title = sl =>
    sl.option_shown_title ? `${sl.option_shown_title}` : `Shown Label`;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setSelectListLocal(
      Object.assign({}, sl, {
        options: reorder(
          sl.options,
          result.source.index,
          result.destination.index
        ),
      })
    );
  }

  function handleOptionChange(index, val) {
    const new_options = [...sl.options];
    new_options[index] = val;
    setSelectListLocal(Object.assign({}, sl, { options: new_options }));
  }

  function handleOptionRemove(index) {
    let new_options = [...sl.options];
    new_options.splice(index, 1);
    setSelectListLocal(Object.assign({}, sl, { options: new_options }));
  }

  function handleAddNewRow() {
    let new_options = [...sl.options];
    new_options.push({ [new_row.code]: new_row.label });
    setSelectListLocal(Object.assign({}, sl, { options: new_options }));
    setNewRow({ code: '', label: '' });
  }

  return (
    <>
      <div className="tool-banner">
        <Link to="/admin_settings">
          <ArrowLeft16 className="back-arrow" /> All Admin Settings
        </Link>
      </div>
      <h2>Edit Select List: {sl.name}</h2>
      <p>Code: {code}</p>
      <TdSelect
        id="unused-1"
        labelText={`Sample for ${sl.name}`}
        select_list={sl}
      />
      <Form>
        <Checkbox
          id={`sl-${sl.code}-show_text_and_value`}
          name={`show_text_and_value`}
          checked={!!sl.show_text_and_value}
          labelText={`Show Option Names AND Values in dropdown`}
          onChange={e =>
            setSelectListLocal(
              Object.assign({}, sl, { show_text_and_value: e })
            )
          }
        />
        <Checkbox
          id={`sl-${sl.code}-show_title_option`}
          name={`show_title_option`}
          checked={!!sl.show_title_option}
          labelText={`Show Title Row in Dropdown Options`}
          onChange={e =>
            setSelectListLocal(Object.assign({}, sl, { show_title_option: e }))
          }
        />
        <TextInput
          id={`sl-${sl.code}-value_title`}
          labelText="Title Row value title"
          onChange={e =>
            setSelectListLocal(
              Object.assign({}, sl, { value_title: e.target.value })
            )
          }
          value={sl.value_title}
        />
        <TextInput
          id={`sl-${sl.code}-option_shown_title`}
          labelText="Title Row option-shown title"
          onChange={e =>
            setSelectListLocal(
              Object.assign({}, sl, { option_shown_title: e.target.value })
            )
          }
          value={sl.option_shown_title}
        />
        <StructuredListWrapper>
          <StructuredListHead>
            <StructuredListRow head>
              <StructuredListCell head>{value_title(sl)}</StructuredListCell>
              <StructuredListCell head>{label_title(sl)}</StructuredListCell>
              <StructuredListCell head>Actions</StructuredListCell>
            </StructuredListRow>
          </StructuredListHead>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="bx--structured-list-tbody"
                  role="rowgroup"
                >
                  {/* this div should be a StructuredListBody element, but react-beautiful-dnd requires refs, which requires native HTML elements or support for refs, which SLB doesn't provide. */}
                  {sl.options.map((li, index) => (
                    <Draggable
                      draggableId={`option-${index}`}
                      index={index}
                      key={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bx--structured-list-row"
                        >
                          {/* this div should be a StructuredListRow element, but react-beautiful-dnd requires refs, which requires native HTML elements or support for refs, which SLR doesn't provide. */}
                          <StructuredListCell>
                            {edit_idx === index ? (
                              <TextInput
                                type="text"
                                value={editing_row.code}
                                onChange={e =>
                                  setEditingRow(
                                    Object.assign({}, editing_row, {
                                      code: e.target.value,
                                    })
                                  )
                                }
                              />
                            ) : (
                              Object.keys(li)[0]
                            )}
                          </StructuredListCell>
                          <StructuredListCell>
                            {edit_idx === index ? (
                              <TextInput
                                type="text"
                                value={editing_row.label}
                                onChange={e =>
                                  setEditingRow(
                                    Object.assign({}, editing_row, {
                                      label: e.target.value,
                                    })
                                  )
                                }
                              />
                            ) : (
                              Object.values(li)[0]
                            )}
                          </StructuredListCell>
                          <StructuredListCell>
                            {edit_idx === index ? (
                              <Button
                                onClick={() => {
                                  handleOptionChange(index, {
                                    [editing_row.code]: editing_row.label,
                                  });
                                  setEditIdx(-1);
                                }}
                              >
                                Save
                              </Button>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    setEditIdx(index);
                                    setEditingRow({
                                      code: Object.keys(li)[0],
                                      label: Object.values(li)[0],
                                    });
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => {
                                    handleOptionRemove(index);
                                  }}
                                >
                                  Remove
                                </Button>
                              </>
                            )}
                          </StructuredListCell>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </StructuredListWrapper>

        <Button onClick={save}>Save Select List</Button>
      </Form>

      <h3>Add New List Entry</h3>
      <TextInput
        labelText={value_title(sl)}
        value={new_row.code}
        onChange={e =>
          setNewRow({ code: e.target.value, label: new_row.label })
        }
      />
      <TextInput
        labelText={label_title(sl)}
        value={new_row.label}
        onChange={e => setNewRow({ code: new_row.code, label: e.target.value })}
      />
      <Button onClick={handleAddNewRow}>Add New Row</Button>
    </>
  );
}
export default connect(full_redux_state)(SelectList);
