import store from './redux/store';
import { go_offline } from './redux/actions';
import { handle_successful_fetch, handle_fetch_exception } from './offline';
import { cl, clrequest, clresponse } from './debug';
import { real_id_for } from './offline';
import { is_online_or_probe_for_online_with_heartbeat } from './offline/heartbeat';
import { update_url_temp_ids } from './offline/temp_ids';

const toQueryString = obj => {
  if (typeof obj === 'string') return obj;

  return Object.keys(obj)
    .map(key => {
      if (obj[key] == null) {
        return '';
      }

      const value = encodeURIComponent(obj[key]);

      return `${key}=${value}`;
    })
    .join('&');
};

const apiFetch = async (url, queryParams, options, td_options) => {
  const query = queryParams ? '?' + toQueryString(queryParams) : '';

  options = options || {};
  td_options = td_options || {};
  const headers = options.headers || {};

  if (false && options.method && options.method != 'GET') {
    // we were going to store a csrf token to pass to rails, but decided not to (session cookie should be good enough).  Leaving this as a "howto" add headers if necessary
    const redux_state = store.getState();
    if (redux_state.csrf_token) {
      headers['X-CSRF-Token'] = redux_state.csrf_token;
      headers['X-Requested-With'] = 'XMLHttpRequest';
    }
  }
  // decoded_response looks like:
  // { status: 204, body_decoded: <an object or something> }
  let decoded_response = null;
  let req_clone = null;
  try {
    const request = new Request(url + query, {
      credentials: 'include',
      headers,
      ...options,
    });
    req_clone = request.clone(); // if there's a body, we need to clone() this request before we fetch() to support the case where we're offline.  Some browsers (safari *cough*) won't let us read the body if we .clone() after it's been used.

    const we_think_we_are_online = await is_online_or_probe_for_online_with_heartbeat();

    if (!we_think_we_are_online) {
      // jump down to "the fetch failed" without even bothering to try
      throw 'we arent online, we dont think';
    }

    clrequest('apiFetch', request);
    const fetch_response = await fetch(request);
    clresponse('apiFetch online', fetch_response);

    if (fetch_response && fetch_response.status == 502) {
      throw 'we consider 502 response from api server to be offline';
    }

    decoded_response = await (td_options.response_decoder ||
      handle_successful_fetch)(request, fetch_response);
  } catch (ex) {
    cl('apiFetch fetch failed, exception follows.', url + query, ex);
    store.dispatch(go_offline());
    decoded_response = await (td_options.response_decoder ||
      handle_fetch_exception)(req_clone, options.body); // pass body manually due to iOS safari inability for us to read it later
    cl('apiFetch offline response', decoded_response);
  }
  if (decoded_response.status === 204) {
    return {};
  }

  return decoded_response.body_decoded;
};

const apiFetchWithBody = async (method, url, queryParams, body) => {
  return await apiFetch(url, queryParams, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method,
  });
};

const apiFetchWithFormData = async (method, url, queryParams, formData) => {
  return await apiFetch(url, queryParams, {
    body: formData,
    method,
  });
};

const fixup_url = function(url) {
  if (url.startsWith('http')) return url;
  if (url.startsWith('/api')) return url;
  if (url.startsWith('data')) return url;
  let prefix = '';
  if (window && window.location) {
    prefix = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port != '') {
      prefix = prefix + ':' + window.location.port;
    }
  } else {
    prefix = 'http://localhost:3000';
  }
  if (!url.startsWith('/')) {
    url = translate_temp_ids_in_url(url);
    return prefix + '/api/v1/' + url;
  } else {
    return prefix + url;
  }
};

function translate_temp_ids_in_url(url) {
  const translated = update_url_temp_ids(url);
  cl('temp-id-translator', url, translated);
  return translated;
}

function user_audit_at_helper(data, resource, time = null) {
  const isostring = (time || new Date()).toISOString();
  if (data && data.constructor && data.constructor.name === 'FormData') {
    data.append(`${resource}[user_audit_at]`, isostring);
    return data;
  } else {
    return {
      ...data,
      user_audit_at: isostring,
    };
  }
  throw "got something I couldn't deal with in user_audit_at_helper";
}

export const api = {
  async get(url, queryParams, td_options) {
    url = fixup_url(url);
    return await apiFetch(url, queryParams, {}, td_options);
  },
  async post(url, queryParams, body) {
    url = fixup_url(url);
    return await apiFetchWithBody('POST', url, queryParams, body);
  },
  async post_formdata(url, queryParams, formData) {
    url = fixup_url(url);
    return await apiFetchWithFormData('POST', url, queryParams, formData);
  },
  async put(url, queryParams, body) {
    url = fixup_url(url);
    return await apiFetchWithBody('PUT', url, queryParams, body);
  },
  async put_formdata(url, queryParams, formData) {
    url = fixup_url(url);
    return await apiFetchWithFormData('PUT', url, queryParams, formData);
  },
  async delete(url, queryParams) {
    url = fixup_url(url);
    return await apiFetch(url, queryParams, {
      method: 'DELETE',
    });
  },
  fixup_url,
  user_audit_at_helper,
};

export default api;
