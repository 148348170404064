import { cl, clcache } from '../debug';

function isOurs(request) {
  let rtn = new URL(request.url).hostname == self.location.hostname;
  //console.log('isOurs', rtn);
  return rtn;
}

function isAuth(request) {
  let rtn = new URL(request.url).pathname == '/api/v1/auth';
  //console.log('isAuth', rtn);
  return rtn;
}

function isAPI(request) {
  let rtn = new URL(request.url).pathname.startsWith('/api');
  //console.log('isAPI', rtn);
  return rtn;
}

function isAttachment(request) {
  let rtn = new URL(request.url).pathname.startsWith(
    '/rails/active_storage/blob'
  );
  console.log('t1', 'isAttachment', rtn, request.url);
  return rtn;
}

async function add_to_cache(cacheName, request, response) {
  clcache('add', cacheName, request, response);
  const cache = await caches.open(cacheName);
  let response_to_cache = response;
  console.log(
    't1',
    'actually caching (cache.put)',
    Date.now(),
    cacheName,
    request.url,
    response.redirected,
    response_to_cache
  );
  return cache.put(request, response_to_cache);
}

export { isOurs, isAuth, isAPI, isAttachment, add_to_cache };
