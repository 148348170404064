import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import api from '../../api';
import { full_redux_state } from '../../redux/connectors';

import { ArrowRight16, Box16 } from '@carbon/icons-react';
import {
  Button,
  Loading,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  Tile,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { icon_for, report_name_for_report_type } from '../shared/tools';
import { month_day_year_for } from '../../datetime';

function Project({
  id,
  user,
  offline,
  projects,
  history,
  onMarkHidden,
  onMarkUnhidden,
}) {
  const is_admin = user.role === 'admin';

  const no_projects_message = offline
    ? `Sorry, you're offline and we don't have any Projects cached`
    : `Unknown Project, or you don't have access to that Project`;
  if (!Array.isArray(projects)) return <Loading small={false} active={true} />;

  const project = projects.find(p => p.id == id);

  if (!project) return <p>{no_projects_message}</p>;

  // DEBT: maybe try to load this from a redux master store at some later point
  const [reports, setReports] = useState(undefined);
  useEffect(() => {
    (async () => {
      const recent_reports = (await api.get(
        `projects/${project.id}/recents`
      )).sort((a, b) => {
        // sort by .logged_at desc
        a = Date.parse(a.updated_at);
        b = Date.parse(b.updated_at);
        if (b < a) return -1;
        if (b > a) return 1;
        return 0;
      });
      setReports(recent_reports);
    })();
  }, []);

  const handleDetailClick = report => {
    history.push(`/${report.report_type}/${report.id}`);
  };
  const handleProjectPackClick = () => {
    history.push(`/projects/${project.id}/pack`);
  };
  const no_reports_message = offline
    ? `Sorry, you're offline and we don't have a record of Recent Reports cached.  Try visiting tools directly.`
    : `This Project has no reports or logs yet.`;
  if (!Array.isArray(reports)) return <Loading small={false} active={true} />;

  return (
    <div className="project-home">
      <header className="banner">
        <p className="welcome">{project.name}</p>
      </header>
      <div className="report-list-and-project-pack-button">
        <div className="report-list">
          <h3>
            Recent<span className="screen-hide-sm"> Reports &amp; Logs</span>
          </h3>
          <StructuredListWrapper selection>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Report</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>

            <StructuredListBody>
              {reports.length > 0 &&
                reports.map(report => (
                  <StructuredListRow
                    key={`${report.report_type}-${report.id}`}
                    onClick={() => handleDetailClick(report)}
                  >
                    <StructuredListCell>
                      {report_name_for_report_type(report.report_type)}:{' '}
                      {month_day_year_for(report.updated_at)}
                    </StructuredListCell>
                  </StructuredListRow>
                ))}
              {reports.length === 0 && (
                <StructuredListRow>
                  <StructuredListCell>{no_reports_message}</StructuredListCell>
                </StructuredListRow>
              )}
            </StructuredListBody>
          </StructuredListWrapper>
        </div>
        <Button
          className="project-pack"
          renderIcon={ArrowRight16}
          onClick={handleProjectPackClick}
        >
          Project Pack
        </Button>
      </div>
      <h3>Tools</h3>
      <ul className="tool-list">
        {user.tools.map(tool => (
          <li key={tool.id}>
            {tool.implemented && (
              <Link
                to={`${tool.path}/projects/${project.id}`}
                className="tool-tile"
              >
                <Tile className="tool-tile">
                  <div className="icon">{icon_for(tool)}</div>
                  <div>
                    <p className="title">{tool.title}</p>
                  </div>
                </Tile>
              </Link>
            )}
          </li>
        ))}
      </ul>
      {is_admin && project.hidden && (
        <Button
          kind="danger"
          renderIcon={Box16}
          onClick={e => {
            e.stopPropagation();
            onMarkUnhidden(project);
          }}
        >
          Un-Archive Project
        </Button>
      )}
      {is_admin && !project.hidden && (
        <Button
          kind="danger"
          renderIcon={Box16}
          onClick={e => {
            e.stopPropagation();
            onMarkHidden(project);
            history.push('/projects');
          }}
        >
          Archive Project
        </Button>
      )}
    </div>
  );
}

export default connect(full_redux_state)(Project);
