import { erase_datastores } from '../localforage';

import { handle_successful_fetch } from './online_handle';
import { handle_fetch_exception } from './offline_handle';

import { real_id_for, initialize_store } from './temp_ids';

async function initialize() {
  initialize_store();
}

function erase_local_storage() {
  erase_datastores();
}

export {
  initialize,
  handle_successful_fetch,
  handle_fetch_exception,
  erase_local_storage,
  real_id_for,
};
