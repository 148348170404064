import { cl } from '../debug';
import store from '../redux/store';
import { go_offline } from '../redux/actions';
import { replay_request_queue } from './request_queue';
import api from '../api';

// how often we run, periodically
const heartbeat_frequency_ms = 5000;
// if there's this much time passed since our last run, we'll go immediately on an API call.
const probe_heartbeat_frequency_ms = 1000;

let last_heartbeat_time = Date.now();

/* 
called when we get an API call. If we're offline, and we haven't had a heartbeat in the past probe_heartbeat_frequency_ms seconds, send a probe.
returns true if we're now (or already) online, runs queue if it has anything in it, if we weren't online.  Returns false if we're offline */

async function is_online_or_probe_for_online_with_heartbeat() {
  const { online } = store.getState();
  if (online !== 'offline') return true; // at initialization, online will be null, so assume we're online unless we know otherwise
  if (Date.now() - last_heartbeat_time > probe_heartbeat_frequency_ms) {
    return await heartbeat();
  }
  return false;
}

let heartbeat_thread = null;
function initialize() {
  cl('heartbeat initialize');
  heartbeat_thread = setTimeout(
    do_and_reenqueue_heartbeat,
    heartbeat_frequency_ms
  );
}

async function do_and_reenqueue_heartbeat() {
  await heartbeat();
  heartbeat_thread = setTimeout(
    do_and_reenqueue_heartbeat,
    heartbeat_frequency_ms
  );
}

async function heartbeat() {
  last_heartbeat_time = Date.now();

  const { online } = store.getState();
  if (online !== 'online') {
    cl('heartbeat not online');
    // not online.  Probe for online with request to api.get('heartbeat').  If successful, run the queue, then return.  The queue runner will mark us online again, if we are. DEBT: if successful, we could run the queue in the background, service any GETs in the mean time..
    const now_online = await get_heartbeat();
    if (now_online) {
      cl('heartbeat now online');
      return await replay_request_queue();
    }
    store.dispatch(go_offline());
    cl('heartbeat still offline');
    return false;
  } else {
    // we're online
    return true;
  }
}

async function get_heartbeat() {
  try {
    cl('get_heartbeat', api.fixup_url('heartbeat'));
    const request = new Request(api.fixup_url('heartbeat'), {
      credentials: 'include',
    });
    const fetch_response = await fetch(request);
    if (fetch_response && fetch_response.status == 502) {
      throw 'we consider 502 response from api server to be offline';
    }
    cl('get_heartbeat online');
    return true;
  } catch (ex) {
    cl('get_heartbeat offline');
    return false;
  }
}

export { is_online_or_probe_for_online_with_heartbeat, initialize };
