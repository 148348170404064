import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import _ from 'underscore';

function Breadcrumbs(props) {
  const first = 'Project Home';
  const second = props.second;
  const third = props.third;
  const obj_name = props.obj_name;
  const obj = props.obj;
  const project_id = props.project_id
    ? props.project_id
    : obj && obj.project
    ? obj.project.id
    : '';
  const object_id = obj ? obj.id : '';

  let rtn = (
    <>
      / <Link to={`/projects/${project_id}`}>Project Home</Link>
    </>
  );
  if (second) {
    rtn = (
      <>
        {rtn} / <Link to={`/${obj_name}/projects/${project_id}`}>{second}</Link>
      </>
    );
  }
  if (third) {
    rtn = (
      <>
        {rtn} / <Link to={`/${obj_name}/${object_id}`}>{third}</Link>
      </>
    );
  }
  return rtn;
}

export default Breadcrumbs;
