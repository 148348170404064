import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import { full_redux_state } from '../../redux/connectors';
import { to_s } from '../../common';

import AdminSettings from './AdminSettings';
import SelectList from './SelectList';

function AdminSettingsRouter({ user, offline, history }) {
  const [select_lists, setSelectLists] = useState(null);

  useEffect(() => {
    (async () => {
      const select_lists = await api.get('select_lists');
      setSelectLists(select_lists);
    })();
  }, []);

  const handleSaveSelectList = sl => {
    const new_select_lists = [...select_lists];
    const idx = new_select_lists.findIndex(i => i.code == sl.code);
    if (idx > -1) {
      new_select_lists[idx] = sl;
    } else {
      new_select_lists.push(sl);
    }
    setSelectLists(new_select_lists);
  };

  return (
    <Switch>
      <Route
        path="/admin_settings/select_lists/:code"
        render={({ match }) => {
          const select_list = (select_lists || []).find(
            p => to_s(p.code) === match.params.code
          );
          return (
            <SelectList
              code={match.params.code}
              select_list={select_list}
              history={history}
              onSaveSelectList={handleSaveSelectList}
            />
          );
        }}
      />
      <Route
        render={() => (
          <AdminSettings select_lists={select_lists} history={history} />
        )}
      />
    </Switch>
  );
}

export default connect(full_redux_state)(AdminSettingsRouter);
