import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from '../api';
import { login } from '../redux/actions';

import { Form, Button, TextInput } from 'carbon-components-react';

class LoginForm extends Component {
  state = { email: '', password: '', warningText: '' };

  async handle_submit(e) {
    e.preventDefault();
    const { email, password } = this.state;

    const response = await api.post('auth', null, { login: email, password });

    if (response.success) {
      this.props.onClose && this.props.onClose();
      this.props.dispatch(login(response.user, response.csrf_token));
    } else {
      let warningText = 'Sorry, there was a problem with your login';
      if (response.offline) {
        warningText = "Sorry, you're offline right now so we can't log you in";
      }
      this.setState({
        warningText,
      });
    }
  }

  _onChange(field, e) {
    this.setState({ [field]: e.target.value });
  }

  render() {
    const { dispatch: ignore, ...props } = this.props; // Modal internals not happy with redux dispatch being passed down
    const { warningText } = this.state;
    return (
      <div>
        <Form
          id="login-form"
          onSubmit={e => {
            this.handle_submit(e);
          }}
          {...props}
        >
          <TextInput
            id="email"
            placeholder="email address"
            labelText="Email Address:"
            type="email"
            onChange={e => {
              this._onChange('email', e);
            }}
          />
          <TextInput
            id="password"
            labelText="Password:"
            type="password"
            onChange={e => {
              this._onChange('password', e);
            }}
          />
          {warningText && <p>{warningText}</p>}
          <Button type="submit">Log In</Button>
        </Form>
      </div>
    );
  }
}

export default connect(/* for props.dispatch */)(LoginForm);
