import React, { Component } from 'react';
import _ from 'underscore';
import api from '../../api';

import { Form, TextInput, Button } from 'carbon-components-react';

class PhotoLogImageForm extends Component {
  form_keys = {
    description: '',
    photo_log_id: '',
    image: '',
  };

  form_labels = {
    description: 'Description',
  };

  constructor(props) {
    super(props);

    // the form fields
    this.state = _.clone(this.form_keys);

    this.state.id = null; // for now, we're always a create

    this.state.photo_log_id = this.props.photolog.id; // when we POST/PUT, the photo_log_id is the id of the photo log we're associated with

    // mark all fields as valid, for now
    this.state.invalids = _.mapObject(this.form_keys, (k, v) => {
      return false;
    });
  }

  handleStandardInput = e => {
    let invalids = this.state.invalids;
    invalids[e.target.name] = false;
    this.setState({ [e.target.name]: e.target.value, invalids });
  };

  handleStandardCheckbox = coercer => {
    return (checked, name, e) => {
      let invalids = this.state.invalids;
      invalids[name] = false;
      this.setState({ [name]: coercer(checked), invalids });
    };
  };

  checkbox_helpers = {};

  handleStandardFileInput = e => {
    let invalids = this.state.invalids;
    invalids[e.target.name] = false;
    this.setState({
      [e.target.name]: e.target.files[0],
      [`${e.target.name}_target`]: e.target,
      invalids,
    });
  };

  form_handlers = {
    image: this.handleStandardFileInput,
  };

  clearForm = () => {
    // TODO: cheap hack.  We remember the image target when it gets used.  If it exists, mark its value null on form clear
    if (this.state['image_target']) this.state['image_target'].value = null;
    this.setState({
      ...this.form_keys,
    });
  };

  // return true if our stored state is valid for submitting to the API, false if not (and update state.invalids if it isn't)
  valid = () => {
    const { state } = this;

    // when we validate, everything starts as assumed valid
    let invalids = _.mapObject(this.form_keys, (k, v) => {
      return false;
    });

    // TODO: encode validation rules into some sort of code so we can DRY this up, and put the validation message in the form_keys struct above

    // go through our validation rules, mark things invalid that don't match the rules
    if (!state.description)
      invalids['description'] = 'Must provide a photo description';

    if (!state.image) invalids['image'] = 'Must provide a photo';

    // if we didn't pass validation, update the state and return false
    let invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (invalid) {
      this.setState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    const photolog_id = this.props.photolog.id;

    if (this.valid()) {
      try {
        let endpoint = `photo_logs/${photolog_id}/images`;
        let api_method = api.post_formdata;
        const photolog_image_to_post = new FormData();
        for (var key in this.form_keys) {
          photolog_image_to_post.append(
            `photo_log_image[${key}]`,
            this.state[key]
          );
        }
        api.user_audit_at_helper(photolog_image_to_post, 'photo_log_image');

        if (false && this.is_update()) {
          // this doesn't need to work (yet).  I haven't investigated how this works PUTing to ...json w/ FormData(), which we need (?) due to the file upload
          api_method = api.put;
          endpoint = `photo_logs/${photolog_id}/images/${this.state.id}.json`;
        }
        const resp = await api_method(endpoint, null, photolog_image_to_post);
        if (this.is_ok_response(resp)) {
          this.clearForm();
          const {
            handleAddedPhotoLogImage,
            handleSavePhotoLogImage,
          } = this.props;
          if (this.is_create()) {
            handleAddedPhotoLogImage && handleAddedPhotoLogImage(resp);
          } else if (this.is_update()) {
            handleSavePhotoLogImage && handleSavePhotoLogImage(resp);
          } else {
            alert('internal error');
          }
        } else {
          alert(this.create_response_error_to_string(resp));
        }
      } catch (err) {
        alert('Sorry, there was an unexpected error.');
        console.log(err);
      }
    }
  };

  // helpers: are we creating or updating an object?
  is_create = () => {
    return !this.state.id;
  };
  is_update = () => {
    return !!this.state.id;
  };

  // helper: so we can namespace the IDs if necessary
  id_for = id => {
    return id;
  };

  // helper: unpack our validity data structure to map to what the carbon components are expecting
  invalid_for = id => {
    if (!!this.state.invalids[id]) {
      return {
        invalid: !!this.state.invalids[id],
        invalidText: this.state.invalids[id],
      };
    } else {
      return {};
    }
  };

  // helper: these are the standard props we pass to most input components
  field_boilerplate = id => {
    return {
      id: this.id_for(id),
      name: id,
      onChange: this.form_handlers[id]
        ? this.form_handlers[id]
        : this.handleStandardInput,
      ...this.invalid_for(id),
      ...(this.form_labels[id] ? { labelText: this.form_labels[id] } : {}),
    };
  };

  text_field_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
      value: this.state[id],
    };
  };

  checkbox_field_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
      checked: this.checkbox_helpers[id].decoder(this.state[id]),
    };
  };

  text_area_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
      value: this.state[id],
    };
  };

  file_field_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
    };
  };

  // helper: is the API response body for the action an OK?
  is_ok_response = response => {
    return response && response.id;
  };

  // helper: convert the create response body to an error string
  create_response_error_to_string = response => {
    return _.map(_.keys(response), key => `${key} ${response[key]}`).join('\n');
  };

  render() {
    const {
      handleCancelPhotoLogEdit,
      cancelButtonLabel,
      saveButtonLabel,
    } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <TextInput {...this.text_field_boilerplate('description')} />
        <input type="file" {...this.file_field_boilerplate('image')} />
        <Button type="submit">
          {saveButtonLabel ? saveButtonLabel : 'Add Image'}
        </Button>
        {handleCancelPhotoLogEdit && (
          <Button onClick={handleCancelPhotoLogEdit}>
            {cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
          </Button>
        )}
      </Form>
    );
  }
}

export default PhotoLogImageForm;
