import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import api from '../../api';
import { full_redux_state } from '../../redux/connectors';

import UserForm from './Form';
import ToolHeadline from '../shared/ToolHeadline';

import {
  Loading,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
} from 'carbon-components-react';

class Users extends Component {
  state = {
    users: null, // users to display (for now, all users from API), null indicates none loaded yet
    user_editing: null, // user being edited, null indicates none are being edited
  };

  async componentDidMount() {
    const users = await api.get('users');
    const tools = await api.get('tools');
    this.setState({ users, tools });
  }

  handleAddedUser = user => {
    const { users } = this.state;
    users.push(user);
    this.setState({ users });
  };

  handleCancelUserEdit = () => {
    // clear out edit form
    this.setState({ user_editing: null });
  };

  handleSaveUser = user => {
    const { users } = this.state;

    // replace existing user in users structure
    let existing_user_idx = _.findIndex(users, u => u.id == user.id);
    if (existing_user_idx > -1) {
      _.extend(users[existing_user_idx], user);
    }

    // clear out edit form
    this.setState({ users, user_editing: null });
  };

  render() {
    const { users, user_editing, tools } = this.state;
    const { user, offline } = this.props;
    if (!user.role === 'admin') return <p>Unauthorized</p>;
    if (users && users.length) {
      return (
        <>
          <ToolHeadline title="Users" />
          <StructuredListWrapper>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Name</StructuredListCell>
                <StructuredListCell head>Email</StructuredListCell>
                <StructuredListCell head>Tools</StructuredListCell>
                <StructuredListCell head>Role</StructuredListCell>
                <StructuredListCell head>Actions</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>

            <StructuredListBody>
              {users.map(user => (
                <StructuredListRow key={user.id}>
                  <StructuredListCell>{user.name}</StructuredListCell>
                  <StructuredListCell>{user.email}</StructuredListCell>
                  <StructuredListCell>
                    {
                      <ul>
                        {user.tools.map(t => (
                          <li key={t.id}>{t.title}</li>
                        ))}
                      </ul>
                    }
                  </StructuredListCell>
                  <StructuredListCell>{user.role}</StructuredListCell>
                  <StructuredListCell>
                    <Button
                      onClick={() => this.setState({ user_editing: user })}
                    >
                      Edit
                    </Button>
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>
          {user_editing && (
            <UserForm
              user={user_editing}
              tools={tools}
              saveButtonLabel="Save User"
              cancelButtonLabel="Cancel Edits"
              handleSaveUser={this.handleSaveUser}
              handleCancelUserEdit={this.handleCancelUserEdit}
            />
          )}
          {!user_editing && (
            <UserForm handleAddedUser={this.handleAddedUser} tools={tools} />
          )}
        </>
      );
    } else if (users) {
      if (offline) {
        return <p>Offline, so Users cannot be loaded</p>;
      } else {
        return <p>Zero users.</p>;
      }
    } else {
      return <Loading small={false} active={true} />;
    }
  }
}

export default connect(full_redux_state)(Users);
