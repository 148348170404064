import React, { Component } from 'react';
import _ from 'underscore';
import api from '../../api';

import { Form, TextInput, TextArea, Button } from 'carbon-components-react';

class ProjectForm extends Component {
  form_keys = {
    client_name: '',
    name: '',
    code: '',
    contact_info: '',
    county: '',
    location: '',
  };

  form_labels = {
    client_name: 'Client Name',
    name: 'Project Name',
    code: 'Code',
    contact_info: 'Contact',
    county: 'County',
    location: 'Location',
  };

  constructor(props) {
    super(props);

    // the form fields
    this.state = _.clone(this.form_keys);

    // non-visible Project properties and other internal-use state properites (if any)
    this.state.id = null; // if id is present, we're an Update form, not a Create

    // if we're passed defaults, load them
    if (this.props.project) {
      _.extend(this.state, this.props.project);
    }

    // mark all fields as valid, for now
    this.state.invalids = _.mapObject(this.form_keys, (k, v) => {
      return false;
    });
  }

  handleStandardInput = e => {
    let invalids = this.state.invalids;
    invalids[e.target.name] = false;
    this.setState({ [e.target.name]: e.target.value, invalids });
  };

  handleStandardCheckbox = coercer => {
    return (checked, name, e) => {
      let invalids = this.state.invalids;
      invalids[name] = false;
      this.setState({ [name]: coercer(checked), invalids });
    };
  };

  checkbox_helpers = {};

  form_handlers = {};

  clearForm = () => {
    this.setState({
      ...this.form_keys,
    });
  };

  // return true if our stored state is valid for submitting to the API, false if not (and update state.invalids if it isn't)
  valid = () => {
    const { state } = this;

    // when we validate, everything starts as assumed valid
    let invalids = _.mapObject(this.form_keys, (k, v) => {
      return false;
    });

    // TODO: encode validation rules into some sort of code so we can DRY this up, and put the validation message in the form_keys struct above

    // go through our validation rules, mark things invalid that don't match the rules
    if (!state.client_name)
      invalids['client_name'] = 'Must provide a client name';
    if (!state.name) invalids['name'] = 'Must provide a project name';
    if (!state.code) invalids['code'] = 'Must provide a code';
    if (!state.contact_info)
      invalids['contact_info'] = 'Must provide contact information';

    // if we didn't pass validation, update the state and return false
    let invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (invalid) {
      this.setState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (this.valid()) {
      try {
        let endpoint = 'projects';
        let api_method = api.post;
        let project_to_post = _.pick(this.state, _.keys(this.form_keys));
        project_to_post = api.user_audit_at_helper(project_to_post, 'project');

        if (this.is_update()) {
          api_method = api.put;
          endpoint = `projects/${this.state.id}.json`;
        }
        const resp = await api_method(endpoint, null, {
          project: project_to_post,
        });
        if (this.is_ok_response(resp)) {
          this.clearForm();
          const { handleAddedProject, handleSaveProject } = this.props;
          if (this.is_create()) {
            handleAddedProject && handleAddedProject(resp);
          } else if (this.is_update()) {
            handleSaveProject && handleSaveProject(resp);
          } else {
            alert('internal error');
          }
        } else {
          alert(this.create_response_error_to_string(resp));
        }
      } catch (err) {
        alert('Sorry, there was an unexpected error.');
        console.log(err);
      }
    }
  };

  // helpers: are we creating or updating an object?
  is_create = () => {
    return !this.state.id;
  };
  is_update = () => {
    return !!this.state.id;
  };

  // helper: so we can namespace the IDs if necessary
  id_for = id => {
    return id;
  };

  // helper: unpack our validity data structure to map to what the carbon components are expecting
  invalid_for = id => {
    if (!!this.state.invalids[id]) {
      return {
        invalid: !!this.state.invalids[id],
        invalidText: this.state.invalids[id],
      };
    } else {
      return {};
    }
  };

  // helper: these are the standard props we pass to most input components
  field_boilerplate = id => {
    return {
      id: this.id_for(id),
      name: id,
      onChange: this.form_handlers[id]
        ? this.form_handlers[id]
        : this.handleStandardInput,
      ...this.invalid_for(id),
      ...(this.form_labels[id] ? { labelText: this.form_labels[id] } : {}),
    };
  };

  text_field_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
      value: this.state[id],
    };
  };

  checkbox_field_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
      checked: this.checkbox_helpers[id].decoder(this.state[id]),
    };
  };

  text_area_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
      value: this.state[id],
    };
  };

  // helper: is the API response body for the action an OK?
  is_ok_response = response => {
    return response && response.id;
  };

  // helper: convert the create response body to an error string
  create_response_error_to_string = response => {
    return _.map(_.keys(response), key => `${key} ${response[key]}`).join('\n');
  };

  render() {
    const {
      handleCancelProjectEdit,
      cancelButtonLabel,
      saveButtonLabel,
    } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <TextInput {...this.text_field_boilerplate('client_name')} />
        <TextInput {...this.text_field_boilerplate('name')} />
        <TextInput {...this.text_field_boilerplate('location')} />
        <TextInput {...this.text_field_boilerplate('code')} />
        <TextInput {...this.text_field_boilerplate('county')} />
        <TextArea {...this.text_area_boilerplate('contact_info')} />
        <Button type="submit">
          {saveButtonLabel ? saveButtonLabel : 'Add Project'}
        </Button>
        {handleCancelProjectEdit && (
          <Button onClick={handleCancelProjectEdit}>
            {cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
          </Button>
        )}
      </Form>
    );
  }
}

export default ProjectForm;
