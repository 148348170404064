import { useEffect, useRef } from 'react';

export function useDidMount() {
  const didMountRef = useRef(true);

  useEffect(() => {
    didMountRef.current = false;
  }, []);
  return didMountRef.current;
}

export function scrollToFragmentOnMount(didMount) {
  if (didMount) {
    // mounted
    if (window.location.hash) {
      const e = document.getElementById(window.location.hash.substring(1));
      if (e) e.scrollIntoView();
    }
  } else {
    // a state update
  }
}

export function scrollToTopOnMount(didMount) {
  if (didMount) {
    // mounted
    window.scrollTo(0, 0);
  } else {
    // a state update
  }
}

export function scrollToFragmentOrTopOnMount(didMount) {
  if (didMount) {
    // mounted
    if (window.location.hash) {
      const e = document.getElementById(window.location.hash.substring(1));
      if (e) e.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  } else {
    // a state update
  }
}
