import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import { Checkbox } from 'carbon-components-react';

function TdCheckboxGroup(props) {
  const select_list = props.select_list;
  const upstreamOnChange = props.onChange;

  const state = props.value;

  const onChange = (select_list_key, checked) => {
    // values look like [ { value: "hot" }, { value: "cold" }], values match options entry keys in the SelectList
    let new_state;
    if (checked) {
      if (!_.find(state, v => v.value == select_list_key)) {
        new_state = state.concat([{ value: select_list_key }]);
        upstreamOnChange(new_state);
      }
    } else {
      new_state = _.reject(state, v => v.value == select_list_key);
      upstreamOnChange(new_state);
    }
  };

  return (
    <fieldset className="bx--fieldset td-checkbox-group">
      <legend className="bx--label">{props.label}</legend>
      {select_list.options.map((item, idx) => {
        const value = Object.keys(item)[0];
        let text = Object.values(item)[0];

        return (
          <Checkbox
            checked={!!_.find(state, v => v.value == value)}
            key={value}
            labelText={text}
            id={`${props.id}-${idx}`}
            onChange={checked => onChange(value, checked)}
          />
        );
      })}
    </fieldset>
  );
}

export default TdCheckboxGroup;
