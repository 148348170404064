import localforage from 'localforage';
import { to_s } from './common';
import { cl } from './debug';

const stores = {};

function store_name_for(name) {
  return `tt-${name}`;
}

function instance_params_for(name) {
  return {
    name: 'Terradon Tools',
    version: 1.0,
    size: 498073600, // just under 500MB.  Not used for IndexedDB, which is present pretty much everywhere these days...
    storeName: store_name_for(name),
  };
}

// create or get the datastore specified
function datastore(name) {
  name = name || 'root';
  let store = stores[name];
  if (!store) {
    record_store_name(name);
    store = stores[name] = localforage.createInstance(
      instance_params_for(name)
    );
  }

  return store;
}

async function record_store_name(name) {
  if (name === 'root') return;
  const stores = (await datastore('root').getItem('stores')) || {};
  stores[name] = true;
  datastore('root').setItem('stores', stores);
}

async function erase_datastores() {
  const stores = (await datastore('root').getItem('stores')) || {};
  for (let name of Object.keys(stores)) {
    await datastore(name).clear();
  }
  await datastore('root').setItem('stores', {});
  await datastore('root').setItem('request_queue', []);
}

const localforage_acceptable_types = [
  'Array',
  'ArrayBuffer',
  'Blob',
  'Float32Array',
  'Float64Array',
  'Int8Array',
  'Int16Array',
  'Int32Array',
  'Number',
  'Object',
  'Uint8Array',
  'Uint8ClampedArray',
  'Uint16Array',
  'Uint32Array',
  'String',
];

function getItem(model, key) {
  //cl('getItem', model, key, to_s(key));
  return datastore(model).getItem(to_s(key));
}

function setItem(model, key, val) {
  //cl('setItem', model, key, to_s(key), val);
  return datastore(model).setItem(to_s(key), val);
}

function removeItem(model, key) {
  //cl('removeitem', model, key, to_s(key));
  return datastore(model).removeItem(to_s(key));
}

function getStoreItemIDs(model) {
  //cl('getStoreItemIDs', model);
  return datastore(model).keys();
}

async function isStoreEmpty(model) {
  return (await getStoreItemIDs(model)).length == 0;
}

window.ttDebug = window.ttDebug || {};
window.ttDebug.getItem = getItem;
window.ttDebug.setItem = setItem;
window.ttDebug.removeItem = removeItem;
window.ttDebug.datastore = datastore;

export {
  datastore,
  localforage_acceptable_types,
  erase_datastores,
  getItem,
  setItem,
  removeItem,
  getStoreItemIDs,
  isStoreEmpty,
};
