import { cl, clrequest, clrequestfull, clresponse, clcache } from '../debug';
import { isAuth, isAPI } from './helpers';
import { datastore, getItem, setItem, removeItem } from '../localforage';
import { get_cached_auth_data, clear_cached_auth_data } from './auth';
import {
  decode_api_url,
  mock_object_for,
  superobject_specs_for,
  singularize,
  unwrap_array,
} from './data_model';
import { enqueue_request } from './request_queue';
import { unhandled, body_to_object } from './handler_common';
import {
  bookkeeping_add_superobject_subobjects,
  bookkeeping_update_superobject_subobjects,
  bookkeeping_delete_superobject_subobjects,
} from './online_handle';
import { generate_temp_id } from './temp_ids';

async function handle_fetch_exception(request, body) {
  try {
    const target = decode_api_url(request.url);
    const offline_response = {
      status: 200, // DEBT: is this OK?  not very RESTy...
    };
    if (isAuth(request)) {
      if (request.method === 'DELETE') {
        await clear_cached_auth_data();
      }
      // DEBT: we're always returning 200 here.  We could get more creative, but we're not using that

      let auth_data = null;

      // on POST, it's a login attempt.  If we're online and we got here, it has to fail
      if (request.method === 'POST') {
        auth_data = {
          success: false,
        };
      } else {
        auth_data = await get_cached_auth_data();
      }
      offline_response.body_decoded = { ...auth_data, offline: true };
      cl('offline auth response', offline_response);
    } else if (isAPI(request)) {
      if (request.method === 'GET') {
        if (target.collection) {
          // GET /photo_logs, /projects
          // TODO: how to handle "offline" flag when this is an array being returned? in each object for now...

          // TODO: how do we handle evictions from datastore(target.object)?  If it's gone the next time we get an online GET?
          const collection = [];
          await datastore(target.object).iterate((value, key, i) => {
            collection.push({ ...value, offline: true });
          });
          offline_response.body_decoded = collection;
          return offline_response;
        } else if (target.individual) {
          // GET /photo_logs/:id
          offline_response.body_decoded =
            (await getItem(target.object, target.id)) || {};
          offline_response.body_decoded.offline = true;
          return offline_response;
        } else if (
          target.subcollection &&
          target.object == 'projects' &&
          target.subobject == 'recents'
        ) {
          // these "recents" don't correspond to a rails model, but we're going to pretend they do:  project_recents  with the id of the project
          const cached = await getItem('project_recents', target.id);
          offline_response.body_decoded = cached ? unwrap_array(cached) : [];
          // offline_response.body_decoded.offline = true; // TODO: consider converting API method to return an object instead of an array, so we can toss offline in there (do we even use this flag anywhere?)
          return offline_response;
        } else {
          console.log('t5', target);
          unhandled(request, target, 'offline');
        }
      } else if (request.method === 'DELETE') {
        await enqueue_request(request, body);
        offline_response.body_decoded = { offline: true };
        if (target.collection || target.subcollection) {
          // rails doesn't do deletes on collections, at least by default, so fail
          unhandled(request, target, 'offline');
        } else if (target.individual) {
          // DELETE /photo_logs/:id
          await removeItem(target.object, target.id);
        } else if (target.subindividual) {
          // DELETE /photo_logs/:id/images/:id
          await removeItem(target.subobject, target.subid);
          await bookkeeping_delete_superobject_subobjects(target);
        } else {
          unhandled(request, target, 'offline');
        }
      } else if (request.method === 'POST') {
        if (target.individual || target.subindividual) {
          unhandled(request, target, 'offline');
        } else if (target.collection) {
          // POST /image_logs/:id

          const mock_id = await generate_temp_id();
          const mock_object = await mock_object_for(target, mock_id, body);
          await setItem(target.object, mock_id, mock_object);
          await enqueue_request(request, body, mock_id);

          offline_response.body_decoded = { ...mock_object, offline: true };
        } else if (target.subcollection) {
          // POST /image_logs/:id/images
          const mock_id = await generate_temp_id();
          const mock_object = await mock_object_for(target, mock_id, body);
          await setItem(target.subobject, mock_id, mock_object);

          let superobject_specs = superobject_specs_for(target.subobject);
          await bookkeeping_add_superobject_subobjects(
            superobject_specs,
            target.object,
            target.id,
            mock_object
          );

          await enqueue_request(request, body, mock_id);

          offline_response.body_decoded = { ...mock_object, offline: true };
        } else {
          unhandled(request, target, 'offline');
        }
      } else if (request.method === 'PUT') {
        const decoded_body = body_to_object(body);

        const remove_data_not_part_of_real_object = function(object) {
          const fields_to_remove = ['user_audit_at'];
          for (let field of fields_to_remove) {
            delete object[field];
          }
          return object;
        };

        if (target.collection || target.subcollection) {
          unhandled(request, target, 'offline');
        } else if (target.individual) {
          // PUT /photo_logs/:id
          // DEBT: PUT to /projects needs to update subobjects on others in the cache.  Doesn't matter right now because projects isn't available on mobile, and therefore isn't an offline thing

          const decoded_body_object = remove_data_not_part_of_real_object(
            decoded_body[singularize(target.object)]
          );

          // get from cache
          let existing_object = await getItem(target.object, target.id);
          // update locally
          existing_object = Object.assign(existing_object, decoded_body_object);
          // write back to cache
          await setItem(target.object, target.id, existing_object);

          // PUT on an individual currently has no subobject updates (example: a PUT on a photo_log does not update its contained photo_log_images)

          await enqueue_request(request, body);
          offline_response.body_decoded = { ...existing_object, offline: true };
        } else if (target.subindividual) {
          // PUT /photo_logs/:id/images/:id

          const decoded_body_object = remove_data_not_part_of_real_object(
            decoded_body[singularize(target.subobject)]
          );

          // get from cache
          let existing_object = await getItem(target.subobject, target.subid);
          // update locally
          existing_object = Object.assign(existing_object, decoded_body_object);
          // write back to cache
          await setItem(target.subobject, target.subid, existing_object);

          // update superobject subobjects (in photo_log_images, update the photo_log)
          let superobject_specs = superobject_specs_for(target.subobject);
          await bookkeeping_update_superobject_subobjects(
            superobject_specs,
            target.object,
            target.id,
            existing_object
          );

          await enqueue_request(request, body);
          offline_response.body_decoded = { ...existing_object, offline: true };
        }
        cl('api offline PUT response', offline_response);
      } else {
        unhandled(request, target, 'offline - unhandled api verb');
      }
    } else {
      unhandled(request, target, 'offline');
    }
    return offline_response;
  } catch (ex) {
    cl('ex in handle_fetch_exception', ex);
  }
}

export { handle_fetch_exception };
