import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for, month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';
import { useDidMount, scrollToFragmentOnMount } from '../shared/on_mount';

import {
  Loading,
  InlineNotification,
  Button,
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  TimePicker,
  TimePickerSelect,
  Select,
  SelectItem,
  SelectItemGroup,
  Form,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
} from 'carbon-components-react';
import {
  ArrowLeft16,
  Add16,
  Save16,
  ChevronRight16,
} from '@carbon/icons-react';

import TdSelect from '../shared/Select';
import Breadcrumbs from '../shared/Breadcrumbs';
import RichTextArea from '../shared/RichTextArea';

function CCSTReport({
  id,
  user,
  offline,
  ccst_reports,
  handleUpdateCCSTReport,
  onCreateCCSTReport,
  onUpdateCCSTReport,
  history,
}) {
  let initial_ccst_report = null;
  if (Array.isArray(ccst_reports)) {
    initial_ccst_report = ccst_reports.find(pl => to_s(pl.id) == id);
  }

  const form_keys = [
    'producer',
    'class_of_mix',
    'weather_am',
    'weather_pm',
    'method_of_placement',
    'remarks',
    'tickets',
    'test_reports',
    'date_made',
    'contractor',
    'location',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  form_keys['tickets'] = [];
  form_keys['test_reports'] = [];

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for CCST

  const [ccst_report, setCCSTReport] = useState(initial_ccst_report);
  const editable = true; // was: !ccst_report.approved;

  const didMount = useDidMount();
  useEffect(() => scrollToFragmentOnMount(didMount), [ccst_report, didMount]);

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    tickets: [],
    test_reports: [],
    ...(initial_ccst_report ? initial_ccst_report : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = updatedValues =>
    _setFormState(prevState => {
      return { ...form_state, ...updatedValues };
    });

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const handle_submit = async e => {
    return await fh.handle_submit({
      e,
      endpoint: 'ccst_reports',
      model: 'ccst_report',
      state: form_state,
      fields: form_keys,
      valid,
      onCreate: onCreateCCSTReport,
      onUpdate: onUpdateCCSTReport,
      clear_form: () => false, // noop for this form
      onSuccess: () => {
        history.push(to);
      },
    });
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: setFormState,
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };

  const save_wip = (newdata = {}) => {
    const wip = {
      ...ccst_report,
      ..._.pick(form_state, _.keys(form_keys)),
      ...newdata,
    };
    if (onUpdateCCSTReport) onUpdateCCSTReport(wip);
    return wip;
  };

  const handle_add_ticket = () => {
    const wip = save_wip({
      tickets: form_state.tickets.concat([
        {
          ...ccst_report.new_ticket,
          ticket_number:
            Math.max(
              ...[
                0,
                ...form_state.tickets.map(t => parseInt(t.ticket_number, 10)),
              ]
            ) + 1,
        },
      ]),
    });
    setFormState(wip);
    handleCCSTReportTicketClick(wip.tickets.length - 1);
  };

  // END form handling boilerplate and custom

  const reloadData = async () => {
    // TODO: lots of floating this up with handleUpdateCCSTReport.... should put this in redux
    const ccst_report = await api.get(`ccst_reports/${id}`);
    if (handleUpdateCCSTReport) handleUpdateCCSTReport(ccst_report);
    setCCSTReport(ccst_report);
    setFormState(ccst_report);
  };
  useEffect(() => {
    if (!ccst_report) reloadData();
  }, []);

  const project_name =
    ccst_report && ccst_report.project ? ccst_report.project.name : '';
  const code =
    ccst_report && ccst_report.project ? ccst_report.project.code : '';
  const when =
    ccst_report && ccst_report.logged_at
      ? full_month_day_year_for(ccst_report.date_made || ccst_report.logged_at)
      : '';
  const to =
    ccst_report && ccst_report.project
      ? `/ccst_reports/projects/${ccst_report.project.id}`
      : '';

  const handleCCSTReportTicketClick = ticket_id =>
    history.push(`/ccst_reports/${id}/tickets/${ticket_id}`);
  const handleCCSTReportTestReportClick = test_report_id =>
    history.push(`/ccst_reports/${id}/test_reports/${test_report_id}`);

  function audit_markup() {
    if (!ccst_report || !ccst_report.audits || ccst_report.audits.length === 0)
      return '';
    const li_for = function(audit) {
      let user_info = 'an unknown user';
      if (audit.user)
        user_info = <a href={`/users/${audit.user.id}`}>{audit.user.name}</a>;
      const friendly_message =
        audit.friendly_message || 'did something unknown';
      return (
        <li key={audit.id}>
          At {moment(audit.user_audit_at).format('YYYY/MM/DD h:mm A')}{' '}
          {user_info} {friendly_message}
        </li>
      );
    };
    return (
      <div className="mobile-hide">
        <h3>Audit Trail</h3>
        <ul>{ccst_report.audits.map(audit => li_for(audit))}</ul>
      </div>
    );
  }

  function reports_markup() {
    if (
      !ccst_report ||
      !ccst_report.reports ||
      ccst_report.reports.length === 0
    )
      return '';
    return (
      <div className="mobile-hide">
        <h3>Generated Reports</h3>
        <ul>
          {ccst_report.reports.map(report => (
            <li key={report.id}>
              <a
                href={api.fixup_url(
                  `ccst_reports/${ccst_report.id}/report/${report.id}`
                )}
              >
                Report generated at{' '}
                {moment(report.created_at).format('YYYY/MM/DD h:mm A')}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  let content = <Loading small={false} active={true} />;
  if (!ccst_report) {
    content = <Loading small={false} active={true} />;
  } else if (!ccst_report.id && offline) {
    content = (
      <p>
        You are offline, and we do not have this Concrete Report cached, sorry.
      </p>
    );
  } else if (!ccst_report.id && !offline) {
    content = (
      <p>
        You do not have access to that Concrete Report, or it does not exist.
      </p>
    );
  } else {
    let cumulative_pour = 0;
    const tickets_with_cumulative_pour = ccst_report.tickets.map(t => ({
      ...t,
      cumulative_pour: (cumulative_pour =
        cumulative_pour + parseFloat(t.truck_load)),
    }));
    content = (
      <>
        <ToolHeadline title={`${!editable ? 'Viewing' : 'Editing'} Report`} />
        {user.role === 'admin' &&
          ccst_report.has_any_report &&
          !ccst_report.has_current_report && (
            <InlineNotification
              title="There have been changes to this Concrete Report since the last report was generated."
              subtitle="You can re-generate the report."
              kind="info"
              hideCloseButton={true}
              className="mobile-hide"
            />
          )}
        <Form onSubmit={handle_submit}>
          <DatePicker
            id="date_made"
            datePickerType="single"
            onChange={v =>
              fh.handle_nonstandard_input({
                field: 'date_made',
                value: v[0],
                state: form_state,
                setState: setFormState,
              })
            }
            dateFormat="Y/m/d"
            value={form_state.date_made}
          >
            <DatePickerInput
              id="date-made-inner"
              labelText="Date"
              pattern="\d{4}\/\d{1,2}\/\d{1,2}"
            />
          </DatePicker>
          <TextInput {...vfb('contractor')} labelText="Contractor" />
          <TextInput {...vfb('location')} labelText="Placement Location" />
          <TextInput {...vfb('producer')} labelText="Concrete Producer" />
          <TextInput {...vfb('class_of_mix')} labelText="Class of Mix" />
          <TextInput
            {...vfb('method_of_placement')}
            labelText="Placement Method"
          />
          <TextInput {...vfb('weather_am')} labelText="Weather AM" />
          <TextInput {...vfb('weather_pm')} labelText="Weather PM" />
          <RichTextArea {...vfb('remarks')} labelText="Remarks" />

          <h2 id="tickets">Tickets</h2>
          <StructuredListWrapper selection>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Ticket Num</StructuredListCell>
                <StructuredListCell head>Truck Num</StructuredListCell>
                <StructuredListCell head>Truck Load</StructuredListCell>
                <StructuredListCell head>Cumulative Pour</StructuredListCell>
                <StructuredListCell head />
              </StructuredListRow>
            </StructuredListHead>

            <StructuredListBody>
              {tickets_with_cumulative_pour.map((ticket, ticket_id) => (
                <StructuredListRow
                  key={ticket_id}
                  onClick={() => {
                    save_wip();
                    handleCCSTReportTicketClick(ticket_id);
                  }}
                >
                  <StructuredListCell>
                    {ticket.ticket_number || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {ticket.truck_number || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {ticket.truck_load || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {ticket.cumulative_pour || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    <ChevronRight16 />
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>

          {editable && (
            <div className="terradon--align-right">
              <Button
                className="terradon--form-item"
                renderIcon={Add16}
                onClick={handle_add_ticket}
              >
                Add Ticket
              </Button>
            </div>
          )}

          <h2 id="test_reports">Test Reports</h2>
          <StructuredListWrapper selection>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Set number</StructuredListCell>
                <StructuredListCell head>Ticket Number</StructuredListCell>
                <StructuredListCell head>Result Count</StructuredListCell>
                <StructuredListCell head />
              </StructuredListRow>
            </StructuredListHead>

            <StructuredListBody>
              {ccst_report.test_reports.map((test_report, test_report_id) => (
                <StructuredListRow
                  key={test_report_id}
                  onClick={() => {
                    save_wip();
                    handleCCSTReportTestReportClick(test_report_id);
                  }}
                >
                  <StructuredListCell>
                    {test_report.set_number || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {test_report.ticket_number || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {test_report.results.length || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    <ChevronRight16 />
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>

          {editable && (
            <Button renderIcon={Save16} type="submit">
              Save
            </Button>
          )}
        </Form>
        {user.role === 'admin' && audit_markup(ccst_report)}
        {user.role === 'admin' && reports_markup()}
      </>
    );
  }

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="ccst_reports"
          obj={ccst_report}
          second="Concrete Reports"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(CCSTReport);
