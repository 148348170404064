import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s, date_to_s, date_formats } from '../../common';
import { cl } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';
import { useDidMount, scrollToTopOnMount } from '../shared/on_mount';

import {
  Loading,
  InlineNotification,
  Button,
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  TimePicker,
  Select,
  SelectItem,
  SelectItemGroup,
  Form,
} from 'carbon-components-react';
import { ArrowLeft16, Add16 } from '@carbon/icons-react';
import TdSelect from '../shared/Select';
import Breadcrumbs from '../shared/Breadcrumbs';

function CCSTReportTicket({
  id,
  ticket_id,
  user,
  offline,
  ccst_reports,
  onUpdateCCSTReport,
  history,
}) {
  let initial_ccst_report = null;
  if (Array.isArray(ccst_reports)) {
    initial_ccst_report = ccst_reports.find(pl => to_s(pl.id) == id);
  }
  let initial_ccst_report_ticket = null;
  if (initial_ccst_report && Array.isArray(initial_ccst_report.tickets)) {
    initial_ccst_report_ticket = initial_ccst_report.tickets[ticket_id];
  }

  const form_keys = [
    'ticket_number',
    'truck_number',
    'time_left_plant',
    'time_arrived_onsite',
    'time_of_unload',
    'truck_load',
    'slump_flow_1',
    'slump_flow_2',
    'air_con',
    'water_added',
    'add_mix_added',
    'concrete_temp',
    'no_cylinders_cast',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for CCST

  const [ccst_report, setCCSTReport] = useState(initial_ccst_report);
  const editable = true; // was !ccst_report.approved
  const [ccst_report_ticket, setCCSTReportTicket] = useState(
    initial_ccst_report_ticket
  );
  const didMount = useDidMount();
  useEffect(() => scrollToTopOnMount(didMount), [ccst_report_ticket, didMount]);

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    ...(initial_ccst_report_ticket ? initial_ccst_report_ticket : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = updatedValues =>
    _setFormState(prevState => {
      return { ...form_state, ...updatedValues };
    });

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    // TODO: validations?

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: setFormState,
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };
  const fvfb = id => {
    return { ...vfb(id), type: 'number', step: '0.01' };
  };

  // END form handling boilerplate and custom

  const new_test_report_from_this_ticket = () => {
    let slump = form_state.slump_flow_2;
    if (!slump || slump == '' || slump == '') {
      slump = form_state.slump_flow_1;
    }

    return {
      ...ccst_report.new_test_report,
      set_number: 1,
      ticket_number: form_state.ticket_number,
      truck_number: form_state.truck_number,
      time_left_plant: form_state.time_left_plant,
      truck_yardage: form_state.truck_load,
      material_temperature: form_state.concrete_temp,
      slump: slump,
      air_content: form_state.air_con,
      samples_cast_by: ccst_report.user.name,
      water_added: form_state.water_added,
      add_mix_added: form_state.add_mix_added,
      broke_by: form_state.broke_by,
    };
  };

  const reloadData = async () => {
    // TODO: lots of floating this up with handleUpdateCCSTReport.... should put this in redux
    const ccst_report = await api.get(`ccst_reports/${id}`);
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    setCCSTReport(ccst_report);
    setCCSTReportTicket(ccst_report.tickets[ticket_id]);
    setFormState(ccst_report.tickets[ticket_id]);
  };
  useEffect(() => {
    if (!ccst_report) reloadData();
  }, []);

  const project_name =
    ccst_report && ccst_report.project ? ccst_report.project.name : '';
  const code =
    ccst_report && ccst_report.project ? ccst_report.project.code : '';
  const when =
    ccst_report && ccst_report.date_made
      ? full_month_day_year_for(ccst_report.date_made || ccst_report.logged_at)
      : '';
  const to = ccst_report ? `/ccst_reports/${ccst_report.id}` : '';

  const handle_save = () => {
    // load our form_state into our ccst_report
    // then percolate that up (onUpdateCCSTReport(ccst_report))
    // then redirect to the main concrete report page, scrolled down to tickets
    ccst_report.tickets[ticket_id] = _.pick(form_state, _.keys(form_keys));
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    history.push(`/ccst_reports/${id}#tickets`);
  };

  const handle_create_test_report = () => {
    // load our form_state into our ccst_report (in case it got updated)
    // create a new test_report with our data pre-filled
    // then percolate that up (onUpdateCCSTReport(ccst_report))
    // then redirect to the test report entry page
    ccst_report.tickets[ticket_id] = _.pick(form_state, _.keys(form_keys));
    const test_report_id = ccst_report.test_reports.length;
    ccst_report.test_reports[
      test_report_id
    ] = new_test_report_from_this_ticket();
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    history.push(`/ccst_reports/${id}/test_reports/${test_report_id}`);
  };

  const handle_delete = () => {
    ccst_report.tickets.splice(ticket_id, 1);
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    history.push(`/ccst_reports/${id}#tickets`);
  };

  let content = '';
  if (!ccst_report) {
    content = <Loading small={false} active={true} />;
  } else if (!ccst_report.id && offline) {
    content = (
      <p>
        You are offline, and we do not have this Concrete Report cached, sorry.
      </p>
    );
  } else if (!ccst_report.id && !offline) {
    content = (
      <p>
        You do not have access to that Concrete Report, or it does not exist.
      </p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title={`${!editable ? 'Viewing' : 'Editing'} Ticket`} />
        <TextInput {...vfb('ticket_number')} labelText="Ticket Number" />
        <TextInput {...vfb('truck_number')} labelText="Truck Number" />
        <TextInput {...vfb('time_left_plant')} labelText="Time Left Plant" />
        <TextInput
          {...vfb('time_arrived_onsite')}
          labelText="Time Arrived Onsite"
        />
        <TextInput {...vfb('time_of_unload')} labelText="Time of Unload" />
        <TextInput {...fvfb('truck_load')} labelText="Truck Load (C.Y.)" />
        <TextInput
          {...vfb('slump_flow_1')}
          labelText="Slump/Flow No. 1 (In. or Sec.)"
        />
        <TextInput
          {...vfb('slump_flow_2')}
          labelText="Slump/Flow No. 2 (In. or Sec.)"
        />
        <TextInput {...vfb('air_con')} labelText="Air Con. (%)" />
        <TextInput {...vfb('water_added')} labelText="Water Added (Gal.)" />
        <TextInput {...vfb('add_mix_added')} labelText="Ad-Mix. Added" />
        <TextInput
          {...vfb('concrete_temp')}
          labelText="Concrete Temp (Degrees F)"
        />
        <TextInput
          {...vfb('no_cylinders_cast')}
          labelText="No. Cylinders Cast"
        />
        {editable && (
          <>
            <Button kind="ghost" onClick={handle_delete}>
              Delete Ticket
            </Button>
            <Button onClick={handle_save}>Update Ticket</Button>
            <Button
              className="create-test-report"
              onClick={handle_create_test_report}
            >
              Create Test Report
            </Button>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="ccst_reports"
          obj={ccst_report}
          second="Concrete Reports"
          third="Concrete Test Report"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(CCSTReportTicket);
