import React, { Component, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { full_redux_state } from '../../redux/connectors';
import {
  Button,
  Loading,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from 'carbon-components-react';
import {
  DownloadCloud16Classless,
  UploadBox16,
  WifiOff,
  WifiOff16,
} from '../shared/icons';
import { Checkmark16, TrashCan16 } from '@carbon/icons-react';
import { ASSET_CACHE } from '../../offline/cache';
import WebViewer from '@pdftron/pdfjs-express-viewer';

const file_url_cannonicalize = url => {
  if (url.startsWith('/')) {
    const u = new URL(window.location);
    u.pathname = url;
    return u.toString();
  }
  return url.toString();
};

function ProjectPack({ id, user, offline, projects, onReloadProject }) {
  const is_admin = user.role === 'admin';

  const no_projects_message = offline
    ? `Sorry, you're offline and we don't have any Projects cached`
    : `Unknown Project, or you don't have access to that Project`;

  const project = projects ? projects.find(p => p.id == id) : undefined;

  const files = project ? project.attachments : undefined;
  const [file_status_map, setFileStatusMap] = useState({});

  console.log('t1', 'ProjectPack Render', project, files);

  useEffect(() => {
    const handle_update_cache = event => {
      if (event.data && event.data.msg === 'CACHED') {
        check_for_current_file_states(event.data.msg.url);
      }
    };

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', handle_update_cache);
    }

    return () => {
      // componentWillUnmount equivalent
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener(
          'message',
          handle_update_cache
        );
      }
    };
  }); // DEBT: maybe there's a way we don't have to re-attach / set up handlers on each render if we do better w/ wrapping or keeping data in like redux

  const handleRemoveFromDevice = async file => {
    const file_url = file_url_cannonicalize(file.url);
    console.log('t1', 'removing from device', file.id, file_url);
    try {
      const cache = await caches.open(ASSET_CACHE);
      cache.delete(file_url);
      const fsm = {
        ...file_status_map,
        [file.id]: false,
      };
      console.log('t1', 'setting file status map', fsm);
      setFileStatusMap(fsm);
    } catch (e) {
      console.log('t1', 'exceptoin on removing from device', e);
    }
  };

  const check_for_current_file_states = specific_file => {
    console.log('t1', 'check_for_Current_file_states', specific_file);
    if (!files) {
      console.log('t1', 'check_for_Current_file_states no files?', files);
      return;
    }
    console.log(
      't1',
      'check_for_Current_file_states checking...',
      specific_file
    );
    // when files change, update the "is stuff cached" button state
    const checkFileStates = async () => {
      const my_file_status_map = { ...file_status_map };
      console.log('t1', 'opening cache', ASSET_CACHE);
      const cache = await caches.open(ASSET_CACHE);
      const is_cached_on_device = async file => {
        return !!(await cache.match(file_url_cannonicalize(file.url)));
      };

      if (specific_file) {
        const the_file =
          typeof specific_file === 'string'
            ? files.find(f => f.url == specific_file)
            : specific_file;
        console.log('t1', 'check_for_Current_file_states the_file', the_file);
        if (the_file)
          my_file_status_map[the_file.id] = await is_cached_on_device(the_file);
      } else {
        for (const file of files) {
          my_file_status_map[file.id] = await is_cached_on_device(file);
        }
      }
      console.log('t1', 'setFileStatusMap', my_file_status_map);
      setFileStatusMap(my_file_status_map);
    };

    const result = checkFileStates().catch(console.error);
  };
  let effect_guard = 'projects-undefined';
  if (!project) effect_guard = 'project-undefined';
  if (files) effect_guard = files.map(x => x.id).join('-');

  useEffect(check_for_current_file_states, [effect_guard]);

  if (!Array.isArray(projects)) return <Loading small={false} active={true} />;
  if (!project) return <p>{no_projects_message}</p>;

  const no_files_message = offline
    ? `Sorry, you're offline and we don't have any Projects cached`
    : `Project has no files.  If you are an admin, you can upload some.`;

  const is_cached_on_device = file => !!file_status_map[file.id];

  const file_status = file => {
    if (file.removed == true) {
      return 'Removed from Pack by Admins, Sync to remove from this device';
    } else if (is_cached_on_device(file)) {
      return 'Available Offline';
    } else {
      return 'Only Available Online';
    }
  };

  const shown_files = files.filter(f => !f.removed || is_cached_on_device(f));

  const handleDetailClick = file => {
    const url_to_open = file_url_cannonicalize(file.url);

    if (url_to_open) {
      console.log('t1', 'window.open', url_to_open);
      window.open(url_to_open);
    } else {
      alert('Unavailable offline');
    }
    return;
  };

  const updates_available = files.some(file => {
    // if any are "in project" and "not on device" that's an a-firm
    // if any are "not in project" and "on device" that's an a-firm
    const icod = is_cached_on_device(file);
    if ((!file.removed && !icod) || (file.removed && icod)) {
      return true;
    }
    return false;
  });

  const handleSyncUpdates = async () => {
    const cache = await caches.open(ASSET_CACHE);
    for (const file of files) {
      const file_url = file_url_cannonicalize(file.url);
      if (file.removed && is_cached_on_device(file)) {
        console.log('t1', 'purging from cache', file.id, file_url);
        console.log('t1', 'cache purge return', await cache.delete(file_url));
      } else if (!file.removed && !is_cached_on_device(file)) {
        console.log('t1', 'fetching so it adds to cache', file.id, file_url);
        const resp = await fetch(file_url, {});
        console.log(
          't1',
          'cache-filling request response (allowing SW to do the cache filling for us... which makes it not usable in safari because "response has redirects")',
          resp
        );
        // const b = resp.blob();
        // const new_resp = new Response(b, {
        //   status: resp.status,
        //   statusText: resp.statusText,
        //   headers: resp.headers,
        // });
        // console.log(
        //   't1',
        //   'now trying to put our own response without redirects in there',
        //   file_url,
        //   new_resp
        // );
        // await cache.put(file_url, new_resp);
        console.log(
          't1',
          'ok, I put it in the cache (but now the SW will unpack it for us if its not navigational)... wonder what I get out',
          Date.now(),
          file_url,
          await cache.match(file_url)
        );
      }
    }
    check_for_current_file_states();
  };

  const all_synced = !updates_available;

  let page_action_button = <></>;

  if (offline) {
    page_action_button = (
      <Button className="download-files" renderIcon={WifiOff16}>
        Disconnected
      </Button>
    );
  } else if (updates_available) {
    page_action_button = (
      <Button
        className="download-files"
        renderIcon={DownloadCloud16Classless}
        kind="danger--tertiary"
        onClick={handleSyncUpdates}
      >
        Sync Updates
      </Button>
    );
  } else if (all_synced) {
    page_action_button = (
      <Button className="download-files" renderIcon={Checkmark16}>
        Ready
      </Button>
    );
  }

  function handleNewFileClick() {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/png, image/jpeg, application/pdf, image/gif';
    inputElement.multiple = false;
    inputElement.addEventListener('change', async e => {
      const project_attachment = new FormData();
      const file = e.target.files[0];
      project_attachment.append('project_attachment[project_id]', project.id);
      project_attachment.append('project_attachment[file]', file);
      project_attachment.append('project_attachment[name]', file.name);
      project_attachment.append('project_attachment[mime_type]', file.type);
      api.user_audit_at_helper(project_attachment, 'project_attachment');
      const resp = await api.post_formdata(
        `projects/${project.id}/files`,
        null,
        project_attachment
      );

      if (resp && resp.id) {
        onReloadProject(project.id);
      } else {
        alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
      }
      document.body.removeChild(inputElement);
    });
    inputElement.style.visibility = 'hidden';
    document.body.appendChild(inputElement);
    inputElement.dispatchEvent(new MouseEvent('click'));
  }

  async function handleRemoveFromPack(file) {
    try {
      const is_ok_response = response => {
        return response && response.id;
      };
      const create_response_error_to_string = response => {
        return _.map(_.keys(response), key => `${key} ${response[key]}`).join(
          '\n'
        );
      };

      // const incident_report_image = new FormData();
      // incident_report_image.append(
      //   'incident_report_image[incident_report_id]',
      //   incident_report.id
      // );
      // incident_report_image.append(
      //   'incident_report_image[image]',
      //   e.target.files[0]
      // );
      // api.user_audit_at_helper(incident_report_image, 'incident_report_image');
      // const resp = await api.post_formdata(
      //   `incident_reports/${incident_report.id}/images`,
      //   null,
      //   incident_report_image
      // );

      let endpoint = `projects/${project.id}/files/${file.id}.json`;

      let file_to_put = _.pick(file, ['id', 'removed']);
      file_to_put.removed = true;

      file_to_put = api.user_audit_at_helper(file_to_put, 'project_attachment');

      const resp = await api.put(endpoint, null, {
        project_attachment: file_to_put,
      });
      if (is_ok_response(resp)) {
        onReloadProject(project.id);
        //handleProjectSave(Object.assign({}, project, { removed: true }));
      } else {
        alert(create_response_error_to_string(resp));
      }
    } catch (err) {
      alert('Sorry, there was an unexpected error.');
      console.log(err);
    }
  }

  return (
    <div className="project-pack">
      <div className="tool-banner">
        /{' '}
        <Link to={`/projects/${project.id}`}>
          {project.name} / {project.code}
        </Link>
        <p className="name">Project Pack</p>
      </div>

      <div className="control-buttons">
        {is_admin && (
          <Button
            className="add-files"
            renderIcon={UploadBox16}
            onClick={handleNewFileClick}
          >
            Add Files
          </Button>
        )}
        {page_action_button}
      </div>
      <StructuredListWrapper selection>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>File</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>

        <StructuredListBody>
          {shown_files.length > 0 &&
            shown_files.map(file => (
              <StructuredListRow
                key={file.id}
                onClick={() => handleDetailClick(file)}
              >
                <StructuredListCell>
                  <div className="file">
                    {file.name}
                    <br />({file_status(file)})
                    <div className="actions">
                      {file_status_map[file.id] && (
                        <Button
                          kind="danger--tertiary"
                          renderIcon={TrashCan16}
                          onClick={e => {
                            e.stopPropagation();
                            handleRemoveFromDevice(file);
                          }}
                        >
                          Device
                        </Button>
                      )}{' '}
                      {is_admin && !file.removed && (
                        <Button
                          kind="danger--tertiary"
                          renderIcon={TrashCan16}
                          onClick={e => {
                            e.stopPropagation();
                            handleRemoveFromPack(file);
                          }}
                        >
                          Pack
                        </Button>
                      )}
                    </div>
                  </div>
                </StructuredListCell>
              </StructuredListRow>
            ))}
          {files.length === 0 && (
            <StructuredListRow>
              <StructuredListCell>{no_files_message}</StructuredListCell>
            </StructuredListRow>
          )}
        </StructuredListBody>
      </StructuredListWrapper>
    </div>
  );
}

export default connect(full_redux_state)(ProjectPack);
