import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

function RichTextArea(props) {
  const our_props = { ...props };
  our_props.onEditorChange = value =>
    props.onChange({ target: { name: props.id, value } });
  return (
    <div className="bx--form-item">
      <label htmlFor={props.id} className="bx--label">
        {props.labelText}
      </label>
      <div className="bx--text-area__wrapper">
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          {...our_props}
          init={{
            browser_spellcheck: true,
            menubar: false,
            toolbar:
              'undo redo | bold italic underline strikethrough subscript superscript | removeformat',
            width: '100%',
          }}
        />
      </div>
    </div>
  );
}

export default RichTextArea;
