import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { unsaved } from '../../redux/connectors';
import { clear_unsaved_change } from '../../redux/actions';

// https://stackoverflow.com/questions/60600130/using-react-forwardref-with-redux-connect
// https://reactjs.org/docs/forwarding-refs.html
// we have to do the forwardref thing because otherwise we get errors in the console, looks like from HeaderMenuItem passing a ref?
const OurLink = React.forwardRef((props, ref) => {
  const { unsaved, dispatch, onClick, ...rest } = props;
  function maybe_block(e) {
    if (
      unsaved &&
      !confirm(
        'Your changes will be lost if you leave this page without saving.  Select OK to leave this page and lose changes.'
      )
    ) {
      e.preventDefault();
      return true;
    } else if (unsaved) {
      dispatch(clear_unsaved_change());
      return false;
    }
    return false;
  }
  return (
    <Link
      {...rest}
      ref={ref}
      onClick={e => {
        if (!maybe_block(e)) {
          onClick(e);
        }
      }}
    />
  );
});

export default connect(
  unsaved,
  null,
  null,
  { forwardRef: true }
)(OurLink);
