import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl, clresponse } from '../../debug';
import moment from 'moment';
import {
  full_month_day_year_for,
  month_day_year_for,
  year_month_day_for,
} from '../../datetime';
import { unsaved } from '../../redux/connectors';
import { mark_unsaved_change, clear_unsaved_change } from '../../redux/actions';
import * as fh from '../../form_helpers';
import PointNav from './Nav';
import TdSelect from '../shared/Select';
import ToolHeadline from '../shared/ToolHeadline';
import Banner from './Banner';
import { point_name_for } from './shared';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
  Loading,
  TextInput,
  Form,
  DatePicker,
  DatePickerInput,
  Checkbox,
} from 'carbon-components-react';

import {
  ChevronRight16,
  Camera16,
  ArrowLeft16,
  Add16,
  Save16,
} from '@carbon/icons-react';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// define this fn outside the component so it doesn't get redefined every render, which makes the debouncing useless (a newly debounced function gets created each time)
const handle_draft_submit = _.debounce(
  (e, boring_log, handle_submit, newstate, dispatch) => {
    if (boring_log && boring_log.state == 'draft') {
      return handle_submit(e, newstate);
    } else {
      dispatch(mark_unsaved_change());
      return false;
    }
  },
  1000
);
const dummy_event = { preventDefault: () => false };

function Point({
  id,
  point_id,
  boring_log,
  boring_log_point: initial_boring_log_point,
  history,
  offline,
  onCreateBoringLogPoint,
  onUpdateBoringLogPoint,
  onCopyPointClick,
  dispatch,
}) {
  const form_keys = [
    'hole_depth',
    'elevation',
    'elevation_reference',
    'boring_location_reference_type',
    'station',
    'offset',
    'offset_reference',
    'latitude',
    'longitude',
    'north',
    'east',
    'remarks',
    'date_started',
    'date_completed',
    'inspector',
    'driller',
    'rig_number',
    'rig_type',
    'hammer_type',
    'core_barrel_type',
    'core_size',
    'wl_depth_immediate',
    'wl_before_coring',
    'wl_depth_at_completion',
    'wl_depth_time_after',
    'wl_depth_after',
    'backfilled',
    'type_of_log',
    'point_name',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const json_array_form_keys = ['lithologies', 'samples', 'rmrs', 'hcsis'];
  json_array_form_keys.forEach(k => (form_keys[k] = []));

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for Points

  const [boring_log_point, setBoringLogPoint] = useState(
    initial_boring_log_point
      ? {
          ...initial_boring_log_point,
          point_name: point_name_for(initial_boring_log_point),
        }
      : null
  );

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    ...(initial_boring_log_point
      ? {
          ...initial_boring_log_point,
          point_name: point_name_for(initial_boring_log_point),
        }
      : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  // if an event is provided, enable save-to-server-on-every-change feature by calling handle_draft_submit (which is debounced)
  const setFormState = (updatedValues, event) =>
    _setFormState(prevState => {
      const newstate = { ...form_state, ...updatedValues };
      if (event)
        handle_draft_submit(
          event,
          boring_log,
          handle_submit,
          newstate,
          dispatch
        );
      return newstate;
    });

  const reset_state = () => {
    setBoringLogPoint({
      ...initial_boring_log_point,
      point_name: point_name_for(initial_boring_log_point),
    });
    setFormState({
      ..._.clone(form_keys),
      ...initial_boring_log_point,
      point_name: point_name_for(initial_boring_log_point),
    });
  };
  if (
    boring_log_point &&
    initial_boring_log_point &&
    boring_log_point.point_id != initial_boring_log_point.point_id
  ) {
    // refresh on a "copy to new borehole"
    reset_state();
  }

  if (!boring_log_point && initial_boring_log_point) {
    // lazy loading from parent has arrived, actually fill in stuff now
    reset_state();
  }

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const handle_navigate_away = to => {
    const wip = {
      ...boring_log_point,
      ..._.pick(form_state, _.keys(form_keys)),
    };
    if (onUpdateBoringLogPoint) onUpdateBoringLogPoint(wip);

    history.push(to);
  };

  // we accept a custom state here for when we're in draft mode and submitting on each change, and we're debouncing
  const handle_submit = async (e, state) => {
    return await fh.handle_submit({
      e,
      endpoint: `boring_logs/${id}/points`,
      model: 'boring_log_point',
      state: state ? state : form_state,
      fields: form_keys,
      valid,
      onCreate: onCreateBoringLogPoint,
      onUpdate: blp => {
        if (boring_log.state == 'published') dispatch(clear_unsaved_change());
        onUpdateBoringLogPoint(blp);
      },
      clear_form: () => false, // noop for this form
    });
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: s => setFormState(s, event),
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };
  const nvfbfs = id => {
    return { ...nvfb(id), form_state, setFormState };
  };
  // END form handling boilerplate and custom

  // BEGIN custom fields (will move to shared after refining these)

  // END custom fields

  const published = boring_log && boring_log.state == 'published';

  let content = '';
  if (!boring_log_point) {
    content = <Loading small={false} active={true} />;
  } else if (!boring_log_point.id && offline) {
    content = (
      <p>You are offline, and we do not have this Boring Log cached, sorry.</p>
    );
  } else if (!boring_log_point.id && !offline) {
    content = (
      <p>You do not have access to that Boring Log, or it does not exist.</p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title={`${point_name_for(boring_log_point)}: Editing`}>
          <Button
            renderIcon={Add16}
            onClick={async () => {
              if (published) await handle_submit();
              onCopyPointClick(boring_log_point);
              setBoringLogPoint(null);
            }}
          >
            Copy To New Borehole
          </Button>
        </ToolHeadline>
        <Form onSubmit={handle_submit}>
          <TextInput {...vfb('point_name')} labelText="Point Name" />
          <TextInput
            {...nvfb('hole_depth')}
            step="0.01"
            labelText="Total Depth (ft)"
          />
          <TextInput
            {...nvfb('elevation')}
            step="0.01"
            labelText="Surface Elevation (ft)"
          />
          <TdSelect
            {...vfb('elevation_reference')}
            labelText="Elev Ref"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-elevation-reference'
            )}
          />
          <TdSelect
            {...vfb('boring_location_reference_type')}
            labelText="Boring location Reference Type Ref"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-boring-location-reference-type'
            )}
          />
          <TextInput {...nvfb('station')} labelText="Station (ft)" />
          <TextInput {...nvfb('offset')} labelText="Offset (ft)" />
          <TdSelect
            {...vfb('offset_reference')}
            labelText="Offset Reference"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-offset-reference'
            )}
          />

          <TextInput {...nvfb('latitude')} labelText="Latitude" />
          <TextInput {...nvfb('longitude')} labelText="Longitude" />
          <TextInput {...nvfb('north')} labelText="Northing (ft)" />
          <TextInput {...nvfb('east')} labelText="Easting (ft)" />
          <TextInput {...vfb('remarks')} labelText="Remarks" />

          <DatePicker
            id="date_started"
            datePickerType="single"
            onChange={v => {
              fh.handle_nonstandard_input({
                field: 'date_started',
                value: v[0],
                state: form_state,
                setState: s => setFormState(s, dummy_event),
              });
            }}
            dateFormat="Y/m/d"
            value={form_state.date_started}
          >
            <DatePickerInput
              id="date_started-inner"
              labelText="Date Started"
              pattern="\d{4}\/\d{1,2}\/\d{1,2}"
            />
          </DatePicker>

          <DatePicker
            id="date_completed"
            datePickerType="single"
            onChange={v =>
              fh.handle_nonstandard_input({
                field: 'date_completed',
                value: v[0],
                state: form_state,
                setState: s => setFormState(s, dummy_event),
              })
            }
            dateFormat="Y/m/d"
            value={form_state.date_completed}
          >
            <DatePickerInput
              id="date_completed-inner"
              labelText="Date Completed"
              pattern="\d{4}\/\d{1,2}\/\d{1,2}"
            />
          </DatePicker>

          <TextInput {...vfb('inspector')} labelText="Inspector" />
          <TdSelectWithManual
            {...vfb('driller')}
            labelText="Driller"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-driller'
            )}
            form_state={form_state}
            setFormState={setFormState}
          />
          <TextInput {...nvfb('rig_number')} labelText="Rig Number" />
          <TdSelect
            {...vfb('rig_type')}
            labelText="Rig Type"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-rig-type'
            )}
          />
          <TdSelect
            {...vfb('hammer_type')}
            labelText="Hammer Type"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-hammer-type'
            )}
          />
          <TdSelect
            {...vfb('core_barrel_type')}
            labelText="Core Barrel Type"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-core-barrel-type'
            )}
          />
          <TdSelect
            {...vfb('core_size')}
            labelText="Core Size"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-core-size'
            )}
          />
          <TextInputDecimalNA
            {...nvfbfs('wl_depth_immediate')}
            step="0.01"
            labelText="Immediate WL Depth (ft)"
          />
          <TextInputDecimalNA
            {...nvfbfs('wl_before_coring')}
            step="0.01"
            labelText="WL Before Coring (ft)"
          />
          <TextInputDecimalNA
            {...nvfbfs('wl_depth_at_completion')}
            step="0.01"
            labelText="WL Depth At Completion (ft)"
          />
          <TextInputDecimalNA
            {...nvfbfs('wl_depth_time_after')}
            step="0.01"
            labelText="Elapsed Time (hr)"
          />
          <TextInputDecimalNA
            {...nvfbfs('wl_depth_after')}
            step="0.01"
            labelText="WL After Elapsed Time"
          />
          <TdSelect
            {...vfb('backfilled')}
            labelText="Backfilled"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-backfilled'
            )}
          />
          <TdSelect
            {...vfb('type_of_log')}
            labelText="Log Type"
            select_list={boring_log.select_lists.find(
              i => i.code === 'boring-log-log-type'
            )}
          />

          {published && (
            <Button type="submit" renderIcon={Save16}>
              Save Point
            </Button>
          )}
        </Form>
      </>
    );
  }

  const to = boring_log ? `/boring_logs/${boring_log.id}` : '';
  return (
    <>
      <Banner to={to} back_to="Boreholes" boring_log={boring_log} />
      <PointNav
        id={id}
        point_id={point_id}
        show="point"
        navigate_to={handle_navigate_away}
      />
      {content}
    </>
  );
}

function TextInputDecimalNA({ form_state, setFormState, ...props }) {
  return (
    <>
      <TextInput {...props} disabled={props.value == 'NA'} />
      <Checkbox
        checked={props.value == 'NA'}
        onChange={e => {
          fh.handle_nonstandard_input({
            field: props.name,
            value: e ? 'NA' : 0,
            state: form_state,
            setState: s => setFormState(s, dummy_event),
          });
        }}
        labelText={props.labelText + ' NA'}
        id={props.id + '-checkbox'}
      />
    </>
  );
}

function TdSelectWithManual({ form_state, setFormState, ...props }) {
  const [select_list, setSelectList] = useState();
  if (
    props.select_list &&
    props.select_list.options &&
    (!select_list || !select_list.options)
  ) {
    setSelectList({
      ...props.select_list,
      options: props.select_list.options.concat({ Other: 'Other' }),
    });
  }

  let select_list_value = props.value;
  let text_input_value = props.value;
  if (select_list && select_list.options) {
    select_list_value = select_list.options.find(o => o[props.value])
      ? props.value
      : 'Other';
    text_input_value =
      select_list_value == 'Other'
        ? props.value == 'Other'
          ? ''
          : props.value
        : '';
  }

  return (
    <>
      <TdSelect
        {...props}
        select_list={select_list}
        value={select_list_value}
      />
      <TextInput
        id={props.id + '-manual'}
        labelText={props.labelText + ' Manual Entry'}
        onChange={event => {
          return fh.handle_nonstandard_input({
            field: props.name,
            value: event.target.value,
            state: form_state,
            setState: s => setFormState(s, event),
          });
        }}
        value={text_input_value}
        disabled={select_list_value != 'Other'}
      />
    </>
  );
}

export default connect(unsaved)(Point);
