import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import { full_redux_state } from '../../redux/connectors';
import { to_s } from '../../common';

import ProjectBoringLogs from './ProjectBoringLogs';
import BoringLog from '../BoringLog';
import Point from '../BoringLog/Point';
import { Lithologies, Lithology } from '../BoringLog/Lithology';
import { Samples, Sample } from '../BoringLog/Sample';
import { RMRs, RMR } from '../BoringLog/RMR';
import { HCSIs, HCSI } from '../BoringLog/HCSI';

import { Loading, Button } from 'carbon-components-react';
import { UnknownFilled16 } from '@carbon/icons-react';

function BoringLogs({ user, offline, history }) {
  const [boring_logs, setBoringLogs] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    (async () => {
      const projects = await api.get('projects');
      setProjects(projects);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const boring_logs = (await api.get('boring_logs')).sort((a, b) => {
        // sort by .logged_at desc
        a = Date.parse(a.logged_at);
        b = Date.parse(b.logged_at);
        if (b < a) return -1;
        if (b > a) return 1;
        return 0;
      });
      setBoringLogs(boring_logs);
    })();
  }, []);

  const handleProjectClick = id => history.push(`/boring_logs/projects/${id}`);
  const handleBoringLogClick = id => history.push(`/boring_logs/${id}`);

  const handleNewLogClick = async project_id => {
    const boring_log = api.user_audit_at_helper({ project_id }, 'boring_log');
    const resp = await api.post('boring_logs', null, { boring_log });
    if (resp && resp.id) {
      setBoringLogs([resp].concat(boring_logs));
      history.push(`/boring_logs/${resp.id}`);
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  };

  const boring_log_by_id = id => {
    const rtn = boring_logs ? boring_logs.find(e => to_s(e.id) == id) : null;
    return rtn;
  };

  const max_point_id_by_boring_log_id = bl_id => {
    const bl = boring_log_by_id(bl_id);
    if (bl && bl.points) {
      return bl.points.reduce(
        (max, pt) => (max > pt.point_id ? max : pt.point_id),
        0
      );
    }
    return 0;
  };
  const boring_log_point_for = (bl_id, point_id) => {
    const bl = boring_log_by_id(bl_id);
    return bl ? bl.points.find(p => to_s(p.point_id) == point_id) : null;
  };

  const handleNewPointClick = async (boring_log_id, initial) => {
    const boring_log_point = api.user_audit_at_helper(
      initial || { boring_log_id },
      'boring_log_point'
    );
    boring_log_point.point_id =
      max_point_id_by_boring_log_id(boring_log_id) + 1;

    const resp = await api.post(`boring_logs/${boring_log_id}/points`, null, {
      boring_log_point,
    });
    if (resp && resp.id) {
      const new_bl = boring_log_by_id(boring_log_id);
      new_bl.points.push(resp);
      setBoringLogs(boring_logs.map(bl => (bl.id == new_bl.id ? new_bl : bl)));
      history.push(`/boring_logs/${boring_log_id}/points/${resp.point_id}`);
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  };

  const handleCopyPointClick = async boring_log_point => {
    handleNewPointClick(boring_log_point.boring_log_id, {
      boring_log_id: boring_log_point.boring_log_id,
      lithologies: boring_log_point.lithologies,
    });
  };

  // sub-pages (lithology/ies, sample/s, etc) will use this to update the in-app data
  // Point will also use this so as not to lose state when changing panes.  This is pretty heavyweight, but /shrug.
  // Point will have a handleSubmit with a PUT api call (then call this to update in-app data)
  const handleUpdateBoringLogPoint = new_boring_log_point => {
    if (new_boring_log_point && boring_logs) {
      setBoringLogs(
        boring_logs.map(boring_log => {
          if (boring_log.id == new_boring_log_point.boring_log_id) {
            boring_log.points = boring_log.points.map(p =>
              p.point_id == new_boring_log_point.point_id
                ? new_boring_log_point
                : p
            );
          }
          return boring_log;
        })
      );
    }
  };
  const handleUpdateBoringLog = new_boring_log => {
    if (new_boring_log && boring_logs) {
      setBoringLogs(
        boring_logs.map(boring_log => {
          if (boring_log.id == new_boring_log.id) {
            return new_boring_log;
          }
          return boring_log;
        })
      );
    }
  };

  return (
    <Switch>
      <Route
        path="/boring_logs/:id/points/:point_id/lithologies/:lithology_id"
        render={({ match }) => (
          <Lithology
            id={match.params.id}
            point_id={match.params.point_id}
            lithology_id={match.params.lithology_id}
            boring_log={boring_log_by_id(match.params.id)}
            boring_log_point={boring_log_point_for(
              match.params.id,
              match.params.point_id
            )}
            onUpdateBoringLogPoint={handleUpdateBoringLogPoint}
            history={history}
          />
        )}
      />
      <Route
        path="/boring_logs/:id/points/:point_id/lithologies"
        render={({ match }) => (
          <Lithologies
            id={match.params.id}
            point_id={match.params.point_id}
            boring_log={boring_log_by_id(match.params.id)}
            boring_log_point={boring_log_point_for(
              match.params.id,
              match.params.point_id
            )}
            onUpdateBoringLogPoint={handleUpdateBoringLogPoint}
            history={history}
          />
        )}
      />
      <Route
        path="/boring_logs/:id/points/:point_id/samples/:sample_id"
        render={({ match }) => (
          <Sample
            id={match.params.id}
            point_id={match.params.point_id}
            sample_id={match.params.sample_id}
            boring_log={boring_log_by_id(match.params.id)}
            boring_log_point={boring_log_point_for(
              match.params.id,
              match.params.point_id
            )}
            onUpdateBoringLogPoint={handleUpdateBoringLogPoint}
            history={history}
          />
        )}
      />
      <Route
        path="/boring_logs/:id/points/:point_id/samples"
        render={({ match }) => (
          <Samples
            id={match.params.id}
            point_id={match.params.point_id}
            boring_log={boring_log_by_id(match.params.id)}
            boring_log_point={boring_log_point_for(
              match.params.id,
              match.params.point_id
            )}
            onUpdateBoringLogPoint={handleUpdateBoringLogPoint}
            history={history}
          />
        )}
      />
      <Route
        path="/boring_logs/:id/points/:point_id/rmrs/:rmr_id"
        render={({ match }) => (
          <RMR
            id={match.params.id}
            point_id={match.params.point_id}
            rmr_id={match.params.rmr_id}
            boring_log={boring_log_by_id(match.params.id)}
            boring_log_point={boring_log_point_for(
              match.params.id,
              match.params.point_id
            )}
            onUpdateBoringLogPoint={handleUpdateBoringLogPoint}
            history={history}
          />
        )}
      />
      <Route
        path="/boring_logs/:id/points/:point_id/rmrs"
        render={({ match }) => (
          <RMRs
            id={match.params.id}
            point_id={match.params.point_id}
            boring_log={boring_log_by_id(match.params.id)}
            boring_log_point={boring_log_point_for(
              match.params.id,
              match.params.point_id
            )}
            onUpdateBoringLogPoint={handleUpdateBoringLogPoint}
            history={history}
          />
        )}
      />
      <Route
        path="/boring_logs/:id/points/:point_id/hcsis/:hcsi_id"
        render={({ match }) => (
          <HCSI
            id={match.params.id}
            point_id={match.params.point_id}
            hcsi_id={match.params.hcsi_id}
            boring_log={boring_log_by_id(match.params.id)}
            boring_log_point={boring_log_point_for(
              match.params.id,
              match.params.point_id
            )}
            onUpdateBoringLogPoint={handleUpdateBoringLogPoint}
            history={history}
          />
        )}
      />
      <Route
        path="/boring_logs/:id/points/:point_id/hcsis"
        render={({ match }) => (
          <HCSIs
            id={match.params.id}
            point_id={match.params.point_id}
            boring_log={boring_log_by_id(match.params.id)}
            boring_log_point={boring_log_point_for(
              match.params.id,
              match.params.point_id
            )}
            onUpdateBoringLogPoint={handleUpdateBoringLogPoint}
            history={history}
          />
        )}
      />{' '}
      <Route
        path="/boring_logs/:id/points/:point_id"
        render={({ match }) => (
          <Point
            id={match.params.id}
            point_id={match.params.point_id}
            boring_log={boring_log_by_id(match.params.id)}
            boring_log_point={boring_log_point_for(
              match.params.id,
              match.params.point_id
            )}
            onUpdateBoringLogPoint={handleUpdateBoringLogPoint}
            onCopyPointClick={handleCopyPointClick}
            history={history}
          />
        )}
      />
      <Route
        path="/boring_logs/projects/:id"
        render={({ match }) => {
          const project = (projects || []).find(
            p => to_s(p.id) === match.params.id
          );
          return (
            <ProjectBoringLogs
              id={match.params.id}
              project={project}
              boring_logs={boring_logs}
              onDetailClick={handleBoringLogClick}
              onNewLogClick={handleNewLogClick}
            />
          );
        }}
      />
      <Route
        path="/boring_logs/:id"
        render={({ match }) => (
          <BoringLog
            id={match.params.id}
            boring_log={boring_log_by_id(match.params.id)}
            onNewPointClick={() => handleNewPointClick(match.params.id)}
            onUpdateBoringLog={handleUpdateBoringLog}
            history={history}
          />
        )}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export default connect(full_redux_state)(BoringLogs);
