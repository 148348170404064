import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';

import PhotoLogImage from '../PhotoLogImage';
import ToolHeadline from '../shared/ToolHeadline';

import { Loading, InlineNotification, Button } from 'carbon-components-react';
import { ArrowLeft16, Add16 } from '@carbon/icons-react';

import PhotoLogImageForm from './Form';
import Breadcrumbs from '../shared/Breadcrumbs';

function PhotoLog({ id, user, offline, photo_logs, onUpdatePhotoLog }) {
  let initial_photo_log = null;
  if (Array.isArray(photo_logs)) {
    initial_photo_log = photo_logs.find(pl => to_s(pl.id) == id);
  }

  const [photo_log, setPhotoLog] = useState(initial_photo_log);

  const reloadData = async () => {
    // TODO: lots of floating this up with onUpdatePhotoLog.... should put this in redux
    const photo_log = await api.get(`photo_logs/${id}`);
    if (onUpdatePhotoLog) onUpdatePhotoLog(photo_log);
    setPhotoLog(photo_log);
  };
  useEffect(() => {
    if (!photo_log) reloadData();
  }, []);

  function handleNewPhotoClick() {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/png, image/jpeg';
    inputElement.multiple = false;
    inputElement.addEventListener('change', async e => {
      const photo_log_image = new FormData();
      photo_log_image.append('photo_log_image[photo_log_id]', photo_log.id);
      photo_log_image.append('photo_log_image[image]', e.target.files[0]);
      api.user_audit_at_helper(photo_log_image, 'photo_log_image');
      const resp = await api.post_formdata(
        `photo_logs/${photo_log.id}/images`,
        null,
        photo_log_image
      );

      if (resp && resp.id) {
        reloadData();
      } else {
        alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
      }
      document.body.removeChild(inputElement);
    });
    inputElement.style.visibility = 'hidden';
    document.body.appendChild(inputElement);
    inputElement.dispatchEvent(new MouseEvent('click'));
  }

  async function removeImage(removed_image) {
    if (
      !window.confirm(
        'Do you really want to remove this image?  Press "OK" to remove image.'
      )
    )
      return;

    const response = await api.delete(
      `photo_logs/${photo_log.id}/images/${removed_image.id}`,
      api.user_audit_at_helper({}, 'photo_log_image')
    );
    const new_photo_log = {
      ...photo_log,
      has_current_report: false,
      images: _.reject(photo_log.images, image => {
        return image.id == removed_image.id;
      }),
    };
    if (onUpdatePhotoLog) onUpdatePhotoLog(new_photo_log);
    setPhotoLog(new_photo_log);
  }

  function handleSavePhotoLogImage(new_photo_log_image) {
    const new_photo_log = {
      ...photo_log,
      has_current_report: false,
      images: photo_log.images.map(image =>
        new_photo_log_image.id == image.id ? new_photo_log_image : image
      ),
    };
    if (onUpdatePhotoLog) onUpdatePhotoLog(new_photo_log);
    setPhotoLog(new_photo_log);
  }

  const project_name =
    photo_log && photo_log.project ? photo_log.project.name : '';
  const code = photo_log && photo_log.project ? photo_log.project.code : '';
  const when =
    photo_log && photo_log.logged_at
      ? full_month_day_year_for(photo_log.logged_at)
      : '';
  const to =
    photo_log && photo_log.project
      ? `/photo_logs/projects/${photo_log.project.id}`
      : '';

  function audit_markup() {
    if (!photo_log || !photo_log.audits || photo_log.audits.length === 0)
      return '';
    const li_for = function(audit) {
      let user_info = 'an unknown user';
      if (audit.user)
        user_info = <a href={`/users/${audit.user.id}`}>{audit.user.name}</a>;
      const friendly_message =
        audit.friendly_message || 'did something unknown';
      return (
        <li key={audit.id}>
          At {moment(audit.user_audit_at).format('YYYY/MM/DD h:mm A')}{' '}
          {user_info} {friendly_message}
        </li>
      );
    };
    return (
      <div className="mobile-hide">
        <h3>Audit Trail</h3>
        <ul>{photo_log.audits.map(audit => li_for(audit))}</ul>
      </div>
    );
  }

  function reports_markup() {
    if (!photo_log || !photo_log.reports || photo_log.reports.length === 0)
      return '';
    return (
      <div className="mobile-hide">
        <h3>Generated Reports</h3>
        <ul>
          {photo_log.reports.map(report => (
            <li key={report.id}>
              <a
                href={api.fixup_url(
                  `photo_logs/${photo_log.id}/report/${report.id}`
                )}
              >
                Report generated at{' '}
                {moment(report.created_at).format('YYYY/MM/DD h:mm A')}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  let content = <Loading small={false} active={true} />;
  if (!photo_log) {
    content = <Loading small={false} active={true} />;
  } else if (!photo_log.id && offline) {
    content = (
      <p>You are offline, and we do not have this Photo Log cached, sorry.</p>
    );
  } else if (!photo_log.id && !offline) {
    content = (
      <p>You do not have access to that Photo Log, or it does not exist.</p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title="Manage Log">
          <Button renderIcon={Add16} onClick={() => handleNewPhotoClick()}>
            New Photo
          </Button>
        </ToolHeadline>
        {user.role === 'admin' &&
          photo_log.has_any_report &&
          !photo_log.has_current_report && (
            <InlineNotification
              title="There have been changes to this Photo Log since the last report was generated."
              subtitle="You can re-generate the report."
              kind="info"
              hideCloseButton={true}
              className="mobile-hide"
            />
          )}
        {photo_log.images.map(image => (
          <PhotoLogImage
            key={image.id}
            photo_log={photo_log}
            photo_log_image={image}
            removeImage={() => removeImage(image)}
            handleSavePhotoLogImage={handleSavePhotoLogImage}
          />
        ))}

        {user.role === 'admin' && audit_markup(photo_log)}
        {user.role === 'admin' && reports_markup()}
      </>
    );
  }

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="photo_logs"
          obj={photo_log}
          second="Photo Logs"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(PhotoLog);
