import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import { full_redux_state } from '../../redux/connectors';
import { to_s } from '../../common';

import ProjectPhotoLogs from './ProjectPhotoLogs';
import PhotoLog from '../PhotoLog';

import { Loading, Button } from 'carbon-components-react';

function PhotoLogs({ user, offline, history }) {
  const [photo_logs, setPhotoLogs] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    (async () => {
      const projects = await api.get('projects');
      setProjects(projects);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const photo_logs = (await api.get('photo_logs')).sort((a, b) => {
        // sort by .logged_at desc
        a = Date.parse(a.logged_at);
        b = Date.parse(b.logged_at);
        if (b < a) return -1;
        if (b > a) return 1;
        return 0;
      });
      setPhotoLogs(photo_logs);
    })();
  }, []);

  const handleProjectClick = id => history.push(`/photo_logs/projects/${id}`);
  const handlePhotoLogClick = id => history.push(`/photo_logs/${id}`);

  const handleNewLogClick = async project_id => {
    const photo_log = api.user_audit_at_helper({ project_id }, 'photo_log');
    const resp = await api.post('photo_logs', null, { photo_log });
    if (resp && resp.id) {
      setPhotoLogs([resp].concat(photo_logs));
      history.push(`/photo_logs/${resp.id}`);
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  };

  const handleUpdatePhotoLog = new_photo_log => {
    if (new_photo_log && photo_logs)
      setPhotoLogs(
        photo_logs.map(photo_log =>
          photo_log.id == new_photo_log.id ? new_photo_log : photo_log
        )
      );
  };

  return (
    <Switch>
      <Route
        path="/photo_logs/projects/:id"
        render={({ match }) => {
          const project = (projects || []).find(
            p => to_s(p.id) === match.params.id
          );
          return (
            <ProjectPhotoLogs
              id={match.params.id}
              project={project}
              photo_logs={photo_logs}
              onDetailClick={handlePhotoLogClick}
              onNewLogClick={handleNewLogClick}
              onUpdatePhotoLog={handleUpdatePhotoLog}
            />
          );
        }}
      />
      <Route
        path="/photo_logs/:id"
        render={({ match }) => (
          <PhotoLog
            id={match.params.id}
            photo_logs={photo_logs}
            onUpdatePhotoLog={handleUpdatePhotoLog}
          />
        )}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export default connect(full_redux_state)(PhotoLogs);
