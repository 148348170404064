import React from 'react';

export function WifiOff(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const width = props.width || '100%';
  const height = props.height || '100%';
  const title = props.title || 'wifi off';

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className="bx--btn__icon"
    >
      <title>{title}</title>
      <g fill={secondaryfill}>
        <path
          d="M16.22 22.022l-3.198 3.198A2.992 2.992 0 0 0 19 25a2.992 2.992 0 0 0-2.78-2.978z"
          fill={fill}
        />
        <path
          d="M15.988 18.001l3.563-3.502A13.027 13.027 0 0 0 16 14a12.91 12.91 0 0 0-9.192 3.808l2.828 2.828a8.939 8.939 0 0 1 6.352-2.635z"
          fill={fill}
        />
        <path
          d="M19.525 18.718a8.991 8.991 0 0 1 2.84 1.918l2.828-2.829a13.072 13.072 0 0 0-2.698-2.059l-2.97 2.97z"
          fill={fill}
        />
        <path
          d="M16 10c2.354 0 4.633.474 6.737 1.367l3.037-2.985A21.135 21.135 0 0 0 16 6 21.106 21.106 0 0 0 .975 12.224l2.828 2.828A17.137 17.137 0 0 1 16 10z"
          fill={fill}
        />
        <path
          d="M25.433 12.809a17.372 17.372 0 0 1 2.764 2.243l2.828-2.828a21.477 21.477 0 0 0-2.726-2.282l-2.866 2.867z"
          fill={fill}
        />
        <path d="M5.586 27L29 3.586 30.414 5 7 28.414z" />
      </g>
    </svg>
  );
}
export function WifiOff16(props) {
  return <WifiOff width={16} height={16} />;
}

export function Mountain(props) {
  const width = props.width || '100%';
  const height = props.height || '100%';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      className="bx--btn__icon"
    >
      <title>mountain</title>
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="none"
        stroke="currentColor"
      >
        <polyline points="12.56323,10.34485 9,5 2,17 8.95831,17 " />{' '}
        <polyline points="19.43677,10.34485 23,5 30,17 23.04169,17 " />{' '}
        <line x1="12.20833" y1="11" x2="19.79167" y2="11" />{' '}
        <polygon points="16,4 29,28 3,28 " />
      </g>
    </svg>
  );
}
export function Mountain24(props) {
  return <Mountain height={24} width={24} />;
}

export function Road(props) {
  const width = props.width || '100%';
  const height = props.height || '100%';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      className="bx--btn__icon"
    >
      <title>road</title>
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="none"
        stroke="currentColor"
      >
        <line x1="1" y1="30" x2="10" y2="2" />{' '}
        <line x1="22" y1="2" x2="31" y2="30" />{' '}
        <line x1="16" y1="5" x2="16" y2="8" />{' '}
        <line x1="16" y1="13" x2="16" y2="17" />{' '}
        <line x1="16" y1="22" x2="16" y2="27" />
      </g>
    </svg>
  );
}
export function Road24(props) {
  return <Road height={24} width={24} />;
}

export function DownloadCloud(props) {
  const width = props.width || '100%';
  const height = props.height || '100%';
  const cls = props.className || 'bx--btn__icon';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      width={width}
      className={cls}
    >
      <title>download-cloud</title>
      <g
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="2"
        fill="none"
        stroke="currentColor"
      >
        <path d="M8 17l4 4 4-4" />
        <path d="M12 12v9" />
        <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29" />
      </g>
    </svg>
  );
}
export function DownloadCloud16Classless(props) {
  return <DownloadCloud height={16} width={16} className=" " />;
}

export function UploadBox(props) {
  const width = props.width || '100%';
  const height = props.height || '100%';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      className="bx--btn__icon"
    >
      <title>box</title>
      <g fill="currentColor">
        <path d="M31.832,9.445l-4-6c-0.15-0.226-0.386-0.381-0.653-0.429l-11-2 c-0.011-0.002-0.022,0-0.032-0.002C16.098,1.008,16.049,1.008,16,1.008c-0.049,0-0.098,0-0.146,0.007 c-0.011,0.002-0.022-0.001-0.032,0.002l-11,2C4.554,3.064,4.318,3.22,4.168,3.445l-4,6c-0.204,0.307-0.224,0.701-0.05,1.026 S0.631,11,1,11h11c0.379,0,0.725-0.214,0.895-0.553L16,4.236l3.105,6.211C19.275,10.786,19.621,11,20,11h11 c0.369,0,0.708-0.203,0.882-0.528S32.036,9.752,31.832,9.445z" />{' '}
        <path d="M20,13c-1.143,0-2.17-0.635-2.683-1.656L17,10.709v19.935l10.414-4.734C27.771,25.748,28,25.393,28,25V13H20 z" />{' '}
        <path d="M15,10.709l-0.316,0.633C14.17,12.365,13.143,13,12,13H4v12c0,0.393,0.229,0.748,0.586,0.91L15,30.644 V10.709z" />
      </g>
    </svg>
  );
}
export function UploadBox16(props) {
  return <UploadBox width={16} height={16} />;
}

export function Disconnected(props) {
  const width = props.width || '100%';
  const height = props.height || '100%';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      height={height}
      width={width}
      className="bx--btn__icon"
    >
      <title>disconnected</title>
      <g fill="currentColor">
        <path d="M16.22 22.022l-3.198 3.198A2.992 2.992 0 0 0 19 25a2.992 2.992 0 0 0-2.78-2.978z" />
        <path d="M15.988 18.001l3.563-3.502A13.027 13.027 0 0 0 16 14a12.91 12.91 0 0 0-9.192 3.808l2.828 2.828a8.939 8.939 0 0 1 6.352-2.635z" />
        <path d="M19.525 18.718a8.991 8.991 0 0 1 2.84 1.918l2.828-2.829a13.072 13.072 0 0 0-2.698-2.059l-2.97 2.97z" />
        <path d="M16 10c2.354 0 4.633.474 6.737 1.367l3.037-2.985A21.135 21.135 0 0 0 16 6 21.106 21.106 0 0 0 .975 12.224l2.828 2.828A17.137 17.137 0 0 1 16 10z" />
        <path d="M25.433 12.809a17.372 17.372 0 0 1 2.764 2.243l2.828-2.828a21.477 21.477 0 0 0-2.726-2.282l-2.866 2.867z" />
        <path d="M5.586 27L29 3.586 30.414 5 7 28.414z" />
      </g>
    </svg>
  );
}
