import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl, clresponse } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for, month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import { mark_unsaved_change } from '../../redux/actions';
import * as fh from '../../form_helpers';
import PointNav from './Nav';
import TdSelect from '../shared/Select';
import ToolHeadline from '../shared/ToolHeadline';
import Banner from './Banner';
import { point_name_for } from './shared';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
  Loading,
  TextInput,
} from 'carbon-components-react';

import {
  ChevronRight16,
  Camera16,
  ArrowLeft16,
  Add16,
} from '@carbon/icons-react';

// define this fn outside the component so it doesn't get redefined every render, which makes the debouncing useless (a newly debounced function gets created each time)
const handle_draft_submit = _.debounce(
  (e, boring_log, handle_submit, newstate, dispatch) => {
    if (boring_log && boring_log.state == 'draft') {
      return handle_submit(e, newstate);
    } else {
      dispatch(mark_unsaved_change());
      return false;
    }
  },
  1000
);
const dummy_event = { preventDefault: () => false };

function RMRs({
  id,
  point_id,
  boring_log,
  boring_log_point,
  history,
  offline,
  onUpdateBoringLogPoint,
  dispatch,
}) {
  let boring_log_point_rmrs = null;
  if (boring_log_point && Array.isArray(boring_log_point.rmrs)) {
    boring_log_point_rmrs = boring_log_point.rmrs;
  }

  // we implement this for when we're in draft mode and submitting on each change, and we're debouncing
  const handle_submit = async (e, state) => {
    return await fh.handle_submit({
      e,
      endpoint: `boring_logs/${boring_log.id}/points`,
      model: 'boring_log_point',
      state: state,
      fields: ['rmrs'].reduce((a, v) => ({ ...a, [v]: '' }), {}),
      valid: () => true,
      onCreate: null,
      onUpdate: onUpdateBoringLogPoint,
      clear_form: () => false, // noop for this form
    });
  };

  const handleNewRMRClick = e => {
    boring_log_point.rmrs.push(boring_log_point.new_rmr);

    handle_draft_submit(
      e,
      boring_log,
      handle_submit,
      boring_log_point,
      dispatch
    );

    if (onUpdateBoringLogPoint) onUpdateBoringLogPoint(boring_log_point);
    history.push(
      `/boring_logs/${id}/points/${point_id}/rmrs/${boring_log_point.rmrs
        .length - 1}`
    );
  };

  const no_logs_message = offline
    ? `Sorry, you're offline and we don't have any Boring Log Points cached for this project.  You may add New RMRs.`
    : `No RMRs for this Boring Log Point`;

  let content = <Loading small={false} active={true} />;
  if (!boring_log_point) {
    content = <Loading small={false} active={true} />;
  } else if (!boring_log_point && !offline) {
    content = (
      <p>You do not have access to that Boring Log, or it does not exist.</p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title={point_name_for(boring_log_point)}>
          <Button renderIcon={Add16} onClick={e => handleNewRMRClick(e)}>
            New RMR
          </Button>
        </ToolHeadline>
        <StructuredListWrapper selection>
          <StructuredListHead>
            <StructuredListRow head>
              <StructuredListCell head>Depth</StructuredListCell>
              <StructuredListCell className="mobile-hide" head>
                Bottom
              </StructuredListCell>
              <StructuredListCell head />
            </StructuredListRow>
          </StructuredListHead>

          <StructuredListBody>
            {boring_log_point_rmrs.length > 0 &&
              boring_log_point_rmrs.map((rmr, rmr_id) => (
                <StructuredListRow
                  key={rmr_id}
                  onClick={() => {
                    history.push(
                      `/boring_logs/${id}/points/${point_id}/rmrs/${rmr_id}`
                    );
                  }}
                >
                  <StructuredListCell>{rmr.depth}</StructuredListCell>
                  <StructuredListCell className="mobile-hide">
                    {rmr.bottom}
                  </StructuredListCell>
                  <StructuredListCell>
                    <ChevronRight16 />
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            {boring_log_point_rmrs.length === 0 && (
              <StructuredListRow>
                <StructuredListCell>{no_logs_message}</StructuredListCell>
              </StructuredListRow>
            )}
          </StructuredListBody>
        </StructuredListWrapper>{' '}
      </>
    );
  }

  const to = boring_log && boring_log.project ? `/boring_logs/${id}` : '';
  return (
    <>
      <Banner to={to} back_to="Boreholes" boring_log={boring_log} />
      <PointNav
        id={id}
        point_id={point_id}
        show="rmr"
        navigate_to={history.push}
      />
      {content}
    </>
  );
}

function RMR({
  id,
  point_id,
  rmr_id,
  boring_log,
  boring_log_point: initial_boring_log_point,
  onUpdateBoringLogPoint,
  history,
  offline,
  dispatch,
}) {
  let initial_rmr = null;
  if (initial_boring_log_point) {
    initial_rmr = initial_boring_log_point.rmrs[rmr_id];
  }

  const form_keys = [
    'depth',
    'bottom',
    'rock_strength',
    'stratum_rqd',
    'joint_spacing',
    'ground_water_condition',
    'bs',
    'discontinuity_length',
    'seperation',
    'roughness',
    'infilling',
    'weathering',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for RMRs

  const [boring_log_point, setBoringLogPoint] = useState(
    initial_boring_log_point
  );

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    ...(initial_rmr ? initial_rmr : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = (updatedValues, event) =>
    _setFormState(prevState => {
      const newstate = { ...form_state, ...updatedValues };
      if (event) {
        // handle_draft_submit needs newstate include the full point, not this form's state
        boring_log_point.rmrs[rmr_id] = _.pick(newstate, _.keys(form_keys));
        handle_draft_submit(
          event,
          boring_log,
          handle_submit,
          boring_log_point,
          dispatch
        );
      }
      return newstate;
    });

  if (!boring_log_point && initial_boring_log_point) {
    // lazy loading from parent has arrived, load up form state
    setBoringLogPoint(initial_boring_log_point);
    setFormState(initial_boring_log_point.rmrs[rmr_id]);
  }

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  // we implement this for when we're in draft mode and submitting on each change, and we're debouncing
  const handle_submit = async (e, state) => {
    return await fh.handle_submit({
      e,
      endpoint: `boring_logs/${boring_log.id}/points`,
      model: 'boring_log_point',
      state: state,
      fields: ['rmrs'].reduce((a, v) => ({ ...a, [v]: '' }), {}),
      valid: () => true,
      onCreate: null,
      onUpdate: onUpdateBoringLogPoint,
      clear_form: () => false, // noop for this form
    });
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: s => setFormState(s, event),
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };

  // END form handling boilerplate and custom

  const handle_navigate_away = to => {
    // load our form_state into our boring_log_point
    // then percolate that up (onUpdateBoringLogPoint(boring_log_point))
    boring_log_point.rmrs[rmr_id] = _.pick(form_state, _.keys(form_keys));
    if (onUpdateBoringLogPoint) onUpdateBoringLogPoint(boring_log_point);
    history.push(to);
  };

  const handle_delete = () => {
    boring_log_point.rmrs.splice(rmr_id, 1);
    handle_draft_submit(
      dummy_event,
      boring_log,
      handle_submit,
      boring_log_point,
      dispatch
    );
    if (onUpdateBoringLogPoint) onUpdateBoringLogPoint(boring_log_point);
    history.push(`/boring_logs/${id}/points/${point_id}/rmrs`);
  };

  let content = '';
  if (!boring_log_point) {
    content = <Loading small={false} active={true} />;
  } else if (!boring_log_point.id && offline) {
    content = (
      <p>You are offline, and we do not have this Boring Log cached, sorry.</p>
    );
  } else if (!boring_log_point.id && !offline) {
    content = (
      <p>You do not have access to that Boring Log, or it does not exist.</p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title="Editing RMR" />
        <TextInput {...nvfb('depth')} step="0.01" labelText="Depth (ft)" />
        <TextInput {...nvfb('bottom')} step="0.01" labelText="Bottom (ft)" />
        <TdSelect
          {...vfb('rock_strength')}
          labelText="Rock Strength"
          select_list={boring_log.select_lists.find(
            i => i.code === 'boring-log-rock-strength'
          )}
        />
        <TdSelect
          {...vfb('stratum_rqd')}
          labelText="RQD"
          select_list={boring_log.select_lists.find(
            i => i.code === 'boring-log-rqd'
          )}
        />
        <TdSelect
          {...vfb('joint_spacing')}
          labelText="Joint Spacing"
          select_list={boring_log.select_lists.find(
            i => i.code === 'boring-log-joint-spacing'
          )}
        />
        <TdSelect
          {...vfb('ground_water_condition')}
          labelText="Ground Water Condition"
          select_list={boring_log.select_lists.find(
            i => i.code === 'boring-log-ground-water-condition'
          )}
        />

        <h3>Condition of Joints</h3>
        <TextInput {...vfb('bs')} labelText="BS" />
        <TextInput
          {...nvfb('discontinuity_length')}
          step="0.01"
          labelText="Length of Discontinuity"
        />
        <TextInput {...nvfb('seperation')} labelText="Seperation" />
        <TextInput {...nvfb('roughness')} labelText="Roughness" />
        <TextInput {...nvfb('infilling')} labelText="Infilling" />
        <TextInput {...nvfb('weathering')} labelText="Weathering" />

        <Button kind="ghost" onClick={handle_delete}>
          Delete RMR
        </Button>
        <Button
          onClick={() =>
            handle_navigate_away(`/boring_logs/${id}/points/${point_id}/rmrs`)
          }
        >
          Update RMR
        </Button>
      </>
    );
  }

  const to = boring_log ? `/boring_logs/${id}` : '';
  return (
    <>
      <Banner to={to} back_to="Boreholes" boring_log={boring_log} />
      <PointNav
        id={id}
        point_id={point_id}
        show="rmr"
        navigate_to={handle_navigate_away}
      />
      {content}
    </>
  );
}

const rmrs = connect(full_redux_state)(RMRs);
const rmr = connect(full_redux_state)(RMR);

export { rmrs as RMRs, rmr as RMR };
