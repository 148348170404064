import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl, clresponse } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for, month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';
import Banner from './Banner';
import { point_name_for } from './shared';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
  Loading,
} from 'carbon-components-react';

import {
  ChevronRight16,
  Camera16,
  ArrowLeft16,
  Add16,
  Save16,
} from '@carbon/icons-react';

function BoringLog({
  id,
  boring_log,
  history,
  offline,
  onNewPointClick,
  onUpdateBoringLog,
  user,
}) {
  let boring_log_points = null;
  if (boring_log && Array.isArray(boring_log.points)) {
    boring_log_points = boring_log.points;
  }

  const form_keys = ['state'].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const handle_publish = async e => {
    return await fh.handle_submit({
      e,
      endpoint: `boring_logs`,
      model: 'boring_log',
      state: { id: boring_log.id, state: 'published' },
      fields: form_keys,
      valid: () => true,
      onUpdate: onUpdateBoringLog,
      clear_form: () => false, // noop for this form
    });
  };

  const no_logs_message = offline
    ? `Sorry, you're offline and we don't have this Boring Log cached for this project.  If you just created this Boring Log offline, you can add a New Borehole.`
    : `Unable to find points for this Boring Log`;

  const draft = boring_log && boring_log.state !== 'published';

  function audit_markup() {
    if (!boring_log || !boring_log.audits || boring_log.audits.length === 0)
      return '';
    const li_for = function(audit) {
      let user_info = 'an unknown user';
      if (audit.user)
        user_info = <a href={`/users/${audit.user.id}`}>{audit.user.name}</a>;
      const friendly_message =
        audit.friendly_message || 'did something unknown';
      return (
        <li key={audit.id}>
          At {moment(audit.user_audit_at).format('YYYY/MM/DD h:mm A')}{' '}
          {user_info} {friendly_message}
        </li>
      );
    };
    return (
      <div className="mobile-hide">
        <h3>Audit Trail</h3>
        <ul>{boring_log.audits.map(audit => li_for(audit))}</ul>
      </div>
    );
  }

  let content = <></>;
  if (!boring_log) {
    content = <Loading small={false} active={true} />;
  } else if (!boring_log_points && !offline) {
    content = (
      <p>You do not have access to that Boring Log, or it does not exist.</p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title="Boreholes">
          <Button renderIcon={Add16} onClick={() => onNewPointClick()}>
            New Borehole
          </Button>
        </ToolHeadline>
        <StructuredListWrapper selection>
          <StructuredListHead>
            <StructuredListRow head>
              <StructuredListCell head>Boring Number</StructuredListCell>
              <StructuredListCell className="mobile-hide" head>
                Depth
              </StructuredListCell>
              <StructuredListCell head />
            </StructuredListRow>
          </StructuredListHead>

          <StructuredListBody>
            {boring_log_points.length > 0 &&
              boring_log_points.map(point => (
                <StructuredListRow
                  key={point.point_id}
                  onClick={() => {
                    history.push(`/boring_logs/${id}/points/${point.point_id}`);
                  }}
                >
                  <StructuredListCell>
                    {point_name_for(point)}
                  </StructuredListCell>
                  <StructuredListCell className="mobile-hide">
                    {point.hole_depth}
                  </StructuredListCell>
                  <StructuredListCell>
                    <ChevronRight16 />
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            {boring_log_points.length === 0 && (
              <StructuredListRow>
                <StructuredListCell>{no_logs_message}</StructuredListCell>
              </StructuredListRow>
            )}
          </StructuredListBody>
        </StructuredListWrapper>
        {draft && (
          <Button onClick={handle_publish} renderIcon={Save16}>
            Publish Boring Log
          </Button>
        )}

        {user.role === 'admin' && audit_markup()}
      </>
    );
  }
  return (
    <>
      <Banner back_to="Boring Logs" boring_log={boring_log} />
      {content}
    </>
  );
}

export default connect(full_redux_state)(BoringLog);
