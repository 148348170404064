import { cl } from '../debug';

const full_redux_state = state => {
  return {
    logged_in: state.user ? true : false,
    user: state.user,
    offline: state.online === 'offline',
    syncing: state.syncing === true,
    unsaved: state.unsaved === true,
    quota: state.quota,
  };
};

function offline(state) {
  return {
    offline: state.online === 'offline',
    syncing: state.syncing === true,
  };
}

function unsaved(state) {
  return {
    unsaved: state.unsaved === true,
  };
}

export { full_redux_state, offline, unsaved };
