import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';
import api from '../../api';
import { full_redux_state } from '../../redux/connectors';
import { Loading, Button, Modal, Tile } from 'carbon-components-react';
import SignaturePad from 'react-signature-pad-wrapper';

const MySettings = function({ user: reduxUser, offline, logged_in }) {
  const [user, setUser] = useState();
  const reloadData = async () => {
    const user = await api.get(`users/${reduxUser.id}`);
    setUser(user);
  };
  useEffect(() => {
    if (!user && reduxUser) reloadData();
  });

  const [signaturePreloading, setSignaturePreloading] = useState(false);
  const [signaturePreloaded, setSignaturePreloaded] = useState(false);
  function preloadSignature() {
    if (signaturePreloaded) {
      return;
    }
    if (user && user.signature_url) {
      if (!offline) {
        // async load the signature from signature_url
        if (signaturePreloading) return;
        setSignaturePreloading(true);
        api
          .get(user.signature_url, null, {
            response_decoder: async (request, response) => {
              const decoded_response = {
                status: 500,
                body_decoded:
                  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
              };
              if (!response) {
                return decoded_response;
              }
              if (response.status === 200) {
                decoded_response.status = response.status;
                decoded_response.body_decoded = URL.createObjectURL(
                  await response.blob()
                );
              }

              return decoded_response;
            },
          })
          .then(objectURL => {
            signatureRef.current.fromDataURL(objectURL);
            URL.revokeObjectURL(objectURL);
            setSignaturePreloaded(true);
          });
      } else {
        // warn that we got a URL from the server but we're offline and can't load it: adding a signature will overwrite whatever's on the server
        // warning is computed below: if (offline && !signaturePreloaded && user.signature_url)
      }
    } else {
      // no signature_url stored on server, don't need to preload or warn about offline.  do nothing.
    }
  }
  useEffect(preloadSignature);

  const signatureModalHeading =
    user && offline && !signaturePreloaded && user.signature_url
      ? 'Edit Signature (note: offline, will overwrite previous signature on save)'
      : 'Edit Signature';

  const preventCloseOnClickOutside = false;
  let signatureRef = useRef();
  let signatureModalRef = useRef();
  let signatureTriggerButtonRef = useRef();
  const [signatureOpen, setSignatureOpen] = useState(false);

  function handleOpenSignature() {
    setSignatureOpen(true);
  }

  function handleCloseSignature(evt) {
    if (
      evt &&
      !signatureModalRef.current.innerModal.current.contains(evt.target) &&
      preventCloseOnClickOutside
    ) {
      return;
    } else {
      setSignatureOpen(false);
      signatureTriggerButtonRef.current.focus();
    }
  }

  async function handleUpdatedSignature() {
    const blob = await (await fetch(signatureRef.current.toDataURL())).blob();

    const user_formdata = new FormData();
    user_formdata.append('user[signatures][]', blob, 'signature.png');
    api.user_audit_at_helper(user_formdata, 'user');
    const resp = await api.put_formdata(
      `users/${user.id}`,
      null,
      user_formdata
    );

    if (resp && resp.id) {
      reloadData();
      handleCloseSignature();
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  }

  if (!logged_in) return <Loading small={false} active={true} />;
  const u = user || reduxUser;
  return (
    <div className="td-my-settings bx--grid">
      <div className="bx--row">
        <div className="bx--col">
          <h1>{u.name}</h1>
          <p>Name: {u.name}</p>
          <p>Email: {u.email}</p>
          <p>Title: {u.title}</p>
          <p>Role: {u.role}</p>
          <ul className="tools">
            <p>Tools:</p>
            {u.tools.map(
              tool =>
                tool.implemented && (
                  <li key={tool.id}>
                    <Link to={tool.path}>{tool.title}</Link>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      <div className="bx--row">
        <div class="bx--col-md-4">
          <Tile light className="signature-tile">
            {u.signature_url && (
              <>
                <p>Signature</p>
                <img src={u.signature_url} />
              </>
            )}
            {!u.signature_url && <p>No signature uploaded yet.</p>}
            <div
              role="presentation"
              onKeyDown={evt => {
                if (evt.which === 27) {
                  handleCloseSignature();
                  onKeyDown(evt);
                }
              }}
            >
              <Button
                className="edit-signature"
                onClick={handleOpenSignature}
                ref={signatureTriggerButtonRef}
              >
                Edit Signature
              </Button>
              <Modal
                modalHeading={signatureModalHeading}
                modalLabel="Signature"
                open={signatureOpen}
                ref={signatureModalRef}
                onRequestClose={handleCloseSignature}
                onRequestSubmit={handleUpdatedSignature}
                primaryButtonText="Save"
                secondaryButtonText="Cancel"
                className="td-drawing-modal"
              >
                {/* alternate https://github.com/tbolis/react-sketch/blob/master/examples/main.jsx https://tbolis.github.io/showcase/react-sketch/ */}
                <SignaturePad redrawOnResize={true} ref={signatureRef} />
              </Modal>
            </div>
          </Tile>
        </div>
      </div>
    </div>
  );
};

export default connect(full_redux_state)(MySettings);
