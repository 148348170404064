import React, { useState } from 'react';
import { connect } from 'react-redux';
import { full_redux_state } from '../../redux/connectors';
import { Link } from 'react-router-dom';
import { month_day_year_for } from '../../datetime';
import { to_s } from '../../common';
import api from '../../api';
import { cl } from '../../debug';
import ToolHeadline from '../shared/ToolHeadline';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
  Loading,
} from 'carbon-components-react';

import {
  ChevronRight16,
  Camera16,
  ArrowLeft16,
  Add16,
} from '@carbon/icons-react';
import Breadcrumbs from '../shared/Breadcrumbs';

// WIP: re-introduce the "View / Make Report" button on desktop

//  /photo_logs/projects/:id -> ./ProjectPhotoLogs
function ProjectPhotoLogs({
  id /* :id in the matching url */,
  project,
  photo_logs,
  onDetailClick,
  onNewLogClick,
  offline,
  user,
  onUpdatePhotoLog,
}) {
  if (!Array.isArray(photo_logs))
    return <Loading small={false} active={true} />;

  project = project || {};
  photo_logs = photo_logs || [];
  photo_logs = photo_logs.filter(
    pl =>
      (pl.project && to_s(pl.project.id) === id) || to_s(pl.project_id) === id
  );

  const [photo_log_reporting, setPhotoLogReporting] = useState({}); // keys are IDs of photolog with report being generated, empty indicates none are being generated right now
  const [show_archived, setShowArchived] = useState(false);

  const any_logs = photo_logs.length > 0;
  const any_archived_logs = !!photo_logs.filter(pl => pl.archived).length;

  if (!show_archived) {
    photo_logs = photo_logs.filter(pl => !pl.archived);
  }

  const no_logs_message = offline
    ? `Sorry, you're offline and we don't have any Photo Logs cached for this project`
    : show_archived
    ? `No Photo Logs created for this project`
    : any_logs
    ? 'No unarchived Photo Logs created for this project'
    : 'No Photo Logs created for this project';

  const reporting_button_for = photo_log => {
    if (user.role !== 'admin') return '';

    if (photo_log_reporting[photo_log.id]) {
      return <Loading small={true} active={true} />;
    } else if (photo_log.has_current_report) {
      return (
        <>
          <a
            href={api.fixup_url(`photo_logs/${photo_log.id}/report`)}
            onClick={evt => {
              evt.stopPropagation();
            }}
          >
            Download Report
          </a>{' '}
          {!photo_log.archived && (
            <Button
              onClick={evt => {
                evt.stopPropagation();
                handleArchive(photo_log);
              }}
            >
              Archive Report
            </Button>
          )}
        </>
      );
    } else {
      return (
        <Button
          onClick={evt => {
            evt.stopPropagation();
            handleGenerateReport(photo_log);
          }}
        >
          Generate Report
        </Button>
      );
    }
  };

  const handleGenerateReport = async photo_log => {
    setPhotoLogReporting({
      ...photo_log_reporting,
      [photo_log.id]: true,
    });

    const response = await api.post(
      `photo_logs/${photo_log.id}/generate_report`
    );

    // TODO: use redux to update the global photo_logs
    onUpdatePhotoLog(response);

    setPhotoLogReporting({
      ...photo_log_reporting,
      [photo_log.id]: false,
    });
  };

  const handleArchive = async photo_log => {
    const response = await api.post(`photo_logs/${photo_log.id}/archive`);

    // TODO: use redux to update the global photo_logs
    onUpdatePhotoLog(response);
  };

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs project_id={project.id} />
        <p className="project-code">{project.code}</p>
        <p className="name">{project.name}</p>
      </div>
      <ToolHeadline title="Logs">
        <Button renderIcon={Add16} onClick={() => onNewLogClick(id)}>
          New Log
        </Button>
      </ToolHeadline>
      <StructuredListWrapper selection>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head />
            <StructuredListCell head>Date</StructuredListCell>
            <StructuredListCell className="mobile-hide" head>
              Report
            </StructuredListCell>
            <StructuredListCell head />
          </StructuredListRow>
        </StructuredListHead>

        <StructuredListBody>
          {photo_logs.length > 0 &&
            photo_logs.map(photo_log => (
              <StructuredListRow
                key={photo_log.id}
                onClick={() => onDetailClick(photo_log.id)}
              >
                <StructuredListCell>
                  <Camera16 />
                </StructuredListCell>
                <StructuredListCell>
                  {month_day_year_for(photo_log.logged_at)}
                </StructuredListCell>
                <StructuredListCell className="mobile-hide">
                  {reporting_button_for(photo_log)}
                </StructuredListCell>
                <StructuredListCell>
                  <ChevronRight16 />
                </StructuredListCell>
              </StructuredListRow>
            ))}
          {photo_logs.length === 0 && (
            <StructuredListRow>
              <StructuredListCell>{no_logs_message}</StructuredListCell>
            </StructuredListRow>
          )}
        </StructuredListBody>
      </StructuredListWrapper>
      {any_archived_logs && (
        <Button onClick={() => setShowArchived(!show_archived)}>
          {show_archived ? 'Hide' : 'Show'} Archived Logs
        </Button>
      )}
    </>
  );
}

export default connect(full_redux_state)(ProjectPhotoLogs);
