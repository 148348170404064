import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for, month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';
import { useDidMount, scrollToFragmentOnMount } from '../shared/on_mount';

import {
  Loading,
  InlineNotification,
  Button,
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  TimePicker,
  TimePickerSelect,
  Select,
  SelectItem,
  SelectItemGroup,
  Form,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
} from 'carbon-components-react';
import {
  ArrowLeft16,
  Add16,
  Save16,
  ChevronRight16,
} from '@carbon/icons-react';

import TdSelect from '../shared/Select';
import Breadcrumbs from '../shared/Breadcrumbs';
import RichTextArea from '../shared/RichTextArea';

function AsphaltReport({
  id,
  user,
  offline,
  asphalt_reports,
  handleUpdateAsphaltReport,
  onCreateAsphaltReport,
  onUpdateAsphaltReport,
  history,
}) {
  let initial_asphalt_report = null;
  if (Array.isArray(asphalt_reports)) {
    initial_asphalt_report = asphalt_reports.find(pl => to_s(pl.id) == id);
  }

  const form_keys = [
    'contractor',
    'general_location',
    'weather_am',
    'weather_pm',
    'gauge_make_model',
    'serial_number',
    'date_made',
    'remarks',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  form_keys['materials'] = [];
  form_keys['tests'] = [];

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for Asphalt

  const [asphalt_report, setAsphaltReport] = useState(initial_asphalt_report);
  const editable = true; // was !asphalt_report.approved

  const didMount = useDidMount();
  useEffect(() => scrollToFragmentOnMount(didMount), [
    asphalt_report,
    didMount,
  ]);

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    materials: [],
    tests: [],
    ...(initial_asphalt_report ? initial_asphalt_report : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = updatedValues =>
    _setFormState(prevState => {
      return { ...form_state, ...updatedValues };
    });

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const handle_submit = async e => {
    return await fh.handle_submit({
      e,
      endpoint: 'asphalt_reports',
      model: 'asphalt_report',
      state: form_state,
      fields: form_keys,
      valid,
      onCreate: onCreateAsphaltReport,
      onUpdate: onUpdateAsphaltReport,
      clear_form: () => false, // noop for this form
      onSuccess: () => {
        history.push(to);
      },
    });
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: setFormState,
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };
  const fvfb = id => {
    return { ...vfb(id), type: 'number', step: '0.01' };
  };

  const save_wip = (newdata = {}) => {
    const wip = {
      ...asphalt_report,
      ..._.pick(form_state, _.keys(form_keys)),
      ...newdata,
    };
    if (onUpdateAsphaltReport) onUpdateAsphaltReport(wip);
    return wip;
  };

  const handle_add_material = () => {
    const wip = save_wip({
      materials: form_state.materials.concat([
        {
          ...asphalt_report.new_material,
          material_number:
            Math.max(
              ...[
                0,
                ...form_state.materials.map(t =>
                  parseInt(t.material_number, 10)
                ),
              ]
            ) + 1,
        },
      ]),
    });
    setFormState(wip);
    handleAsphaltReportMaterialClick(wip.materials.length - 1);
  };

  const handle_add_test = () => {
    let material_number = '-';
    if (asphalt_report.materials && asphalt_report.materials.length > 0) {
      material_number = asphalt_report.materials[0].material_number;
    }
    const wip = save_wip({
      tests: form_state.tests.concat([
        {
          ...asphalt_report.new_test,
          test_number:
            Math.max(
              ...[0, ...form_state.tests.map(t => parseInt(t.test_number, 10))]
            ) + 1,
          material_number,
        },
      ]),
    });
    setFormState(wip);
    handleAsphaltReportTestClick(wip.tests.length - 1);
  };

  // END form handling boilerplate and custom

  const reloadData = async () => {
    // TODO: lots of floating this up with handleUpdateAsphaltReport.... should put this in redux
    const asphalt_report = await api.get(`asphalt_reports/${id}`);
    if (handleUpdateAsphaltReport) handleUpdateAsphaltReport(asphalt_report);
    setAsphaltReport(asphalt_report);
    setFormState(asphalt_report);
  };
  useEffect(() => {
    if (!asphalt_report) reloadData();
  }, []);

  const project_name =
    asphalt_report && asphalt_report.project ? asphalt_report.project.name : '';
  const code =
    asphalt_report && asphalt_report.project ? asphalt_report.project.code : '';
  const when =
    asphalt_report && asphalt_report.logged_at
      ? full_month_day_year_for(
          asphalt_report.date_made || asphalt_report.logged_at
        )
      : '';
  const to =
    asphalt_report && asphalt_report.project
      ? `/asphalt_reports/projects/${asphalt_report.project.id}`
      : '';

  const handleAsphaltReportMaterialClick = material_id =>
    history.push(`/asphalt_reports/${id}/materials/${material_id}`);
  const handleAsphaltReportTestClick = test_id =>
    history.push(`/asphalt_reports/${id}/tests/${test_id}`);

  function audit_markup() {
    if (
      !asphalt_report ||
      !asphalt_report.audits ||
      asphalt_report.audits.length === 0
    )
      return '';
    const li_for = function(audit) {
      let user_info = 'an unknown user';
      if (audit.user)
        user_info = <a href={`/users/${audit.user.id}`}>{audit.user.name}</a>;
      const friendly_message =
        audit.friendly_message || 'did something unknown';
      return (
        <li key={audit.id}>
          At {moment(audit.user_audit_at).format('YYYY/MM/DD h:mm A')}{' '}
          {user_info} {friendly_message}
        </li>
      );
    };
    return (
      <div className="mobile-hide">
        <h3>Audit Trail</h3>
        <ul>{asphalt_report.audits.map(audit => li_for(audit))}</ul>
      </div>
    );
  }

  function reports_markup() {
    if (
      !asphalt_report ||
      !asphalt_report.reports ||
      asphalt_report.reports.length === 0
    )
      return '';
    return (
      <div className="mobile-hide">
        <h3>Generated Reports</h3>
        <ul>
          {asphalt_report.reports.map(report => (
            <li key={report.id}>
              <a
                href={api.fixup_url(
                  `asphalt_reports/${asphalt_report.id}/report/${report.id}`
                )}
              >
                Report generated at{' '}
                {moment(report.created_at).format('YYYY/MM/DD h:mm A')}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  let content = <Loading small={false} active={true} />;
  if (!asphalt_report) {
    content = <Loading small={false} active={true} />;
  } else if (!asphalt_report.id && offline) {
    content = (
      <p>
        You are offline, and we do not have this Asphalt Report cached, sorry.
      </p>
    );
  } else if (!asphalt_report.id && !offline) {
    content = (
      <p>
        You do not have access to that Asphalt Report, or it does not exist.
      </p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title={`${!editable ? 'Viewing' : 'Editing'} Report`} />
        {user.role === 'admin' &&
          asphalt_report.has_any_report &&
          !asphalt_report.has_current_report && (
            <InlineNotification
              title="There have been changes to this Asphalt Report since the last report was generated."
              subtitle="You can re-generate the report."
              kind="info"
              hideCloseButton={true}
              className="mobile-hide"
            />
          )}
        <Form onSubmit={handle_submit}>
          <DatePicker
            id="date_made"
            datePickerType="single"
            onChange={v =>
              fh.handle_nonstandard_input({
                field: 'date_made',
                value: v[0],
                state: form_state,
                setState: setFormState,
              })
            }
            dateFormat="Y/m/d"
            value={form_state.date_made}
          >
            <DatePickerInput
              id="date-made-inner"
              labelText="Date"
              pattern="\d{4}\/\d{1,2}\/\d{1,2}"
            />
          </DatePicker>
          <TextInput {...vfb('contractor')} labelText="Contractor" />
          <TextInput
            {...vfb('general_location')}
            labelText="General Location"
          />
          <TextInput {...vfb('weather_am')} labelText="Weather AM" />
          <TextInput {...vfb('weather_pm')} labelText="Weather PM" />
          <TextInput
            {...vfb('gauge_make_model')}
            labelText="Gauge Make/Model"
          />
          <TextInput {...vfb('serial_number')} labelText="Serial Number" />
          <RichTextArea {...vfb('remarks')} labelText="Remarks" />

          <h2 id="materials">Materials</h2>
          <StructuredListWrapper selection>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Material Num</StructuredListCell>
                <StructuredListCell head>
                  Material Description
                </StructuredListCell>
                <StructuredListCell head>Test Method</StructuredListCell>
                <StructuredListCell head>Max Wet Density</StructuredListCell>
                <StructuredListCell head />
              </StructuredListRow>
            </StructuredListHead>

            <StructuredListBody>
              {asphalt_report.materials.map((material, material_id) => (
                <StructuredListRow
                  key={material_id}
                  onClick={() => {
                    save_wip();
                    handleAsphaltReportMaterialClick(material_id);
                  }}
                >
                  <StructuredListCell>
                    {material.material_number || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {material.material_description || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {material.test_method || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {material.max_wet_density || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    <ChevronRight16 />
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>

          {editable && asphalt_report.materials.length < 4 && (
            <div className="terradon--align-right">
              <Button
                className="terradon--form-item"
                renderIcon={Add16}
                onClick={handle_add_material}
              >
                Add Material
              </Button>
            </div>
          )}

          <h2 id="tests">Tests</h2>
          <StructuredListWrapper selection>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Test Number</StructuredListCell>
                <StructuredListCell head>Test Location</StructuredListCell>
                <StructuredListCell head>Material Number</StructuredListCell>
                <StructuredListCell head>Wet Density</StructuredListCell>
                <StructuredListCell head />
              </StructuredListRow>
            </StructuredListHead>

            <StructuredListBody>
              {asphalt_report.tests.map((test, test_id) => (
                <StructuredListRow
                  key={test_id}
                  onClick={() => {
                    save_wip();
                    handleAsphaltReportTestClick(test_id);
                  }}
                >
                  <StructuredListCell>
                    {test.test_number || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {test.test_location || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {test.material_number || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {test.wet_density || '-'}
                  </StructuredListCell>
                  <StructuredListCell>
                    <ChevronRight16 />
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>

          {editable && (
            <div className="terradon--align-right">
              <Button
                className="terradon--form-item"
                renderIcon={Add16}
                onClick={handle_add_test}
              >
                Add Test
              </Button>
            </div>
          )}

          {editable && (
            <Button renderIcon={Save16} type="submit">
              Save
            </Button>
          )}
        </Form>
        {user.role === 'admin' && audit_markup(asphalt_report)}
        {user.role === 'admin' && reports_markup()}
      </>
    );
  }

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="asphalt_reports"
          obj={asphalt_report}
          second="Asphalt Reports"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(AsphaltReport);
