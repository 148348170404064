import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import moment from 'moment';
import { full_redux_state } from '../../redux/connectors';

import {
  Button,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent,
} from 'carbon-components-react';

import { Checkmark16 } from '@carbon/icons-react';
import { ExpandableTile } from '../carbon/ExpandableTile';

function IncidentReportImage({ incident_report_image: image, removeImage }) {
  const [expanded, setExpanded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <ExpandableTile
      key={image.id}
      terradonSimplifyClose={true}
      expanded={expanded}
      handleClick={() => setExpanded(true)}
      terradonContentStillLoading={!imageLoaded}
      className="incident_report_image"
      tileExpandedLabel=""
      tileCollapsedLabel="Delete Image"
    >
      <TileAboveTheFoldContent>
        <img
          src={api.fixup_url(image.url)}
          onLoad={() => setImageLoaded(true)}
        />
      </TileAboveTheFoldContent>
      <TileBelowTheFoldContent>
        <div className="buttons">
          <Button onClick={removeImage} kind="ghost">
            Delete Image
          </Button>
        </div>
      </TileBelowTheFoldContent>
    </ExpandableTile>
  );
}

export default IncidentReportImage;
