import React, { Component } from 'react';
import _ from 'underscore';
import api from '../../api';

import { Form, Checkbox, TextInput, Button } from 'carbon-components-react';

class UserForm extends Component {
  form_keys = {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    role: 'user',
    title: '',
  };

  form_labels = {
    name: 'Full Name',
    email: 'Email Address',
    password: 'Password (4 digit PIN)',
    password_confirmation: 'Password, again (4 digit PIN)',
    role: 'Administrator?',
    title: 'Title',
  };

  constructor(props) {
    super(props);

    // the form fields
    this.state = _.clone(this.form_keys);

    // non-visible User properties and other internal-use state properites (if any)
    this.state.id = null; // if id is present, we're an Update form, not a Create

    this.state.selected_tools = {};

    // if we're passed defaults, load them
    if (props.user) {
      _.extend(this.state, props.user);

      // load in this.state.selected_tools
      this.state.selected_tools = _.reduce(
        props.user.user_tools || [],
        (memo, ut) => {
          return { ...memo, [`tool-${ut.tool_id}`]: true };
        },
        {}
      );
    }

    // mark all fields as valid, for now
    this.state.invalids = _.mapObject(this.form_keys, (k, v) => {
      return false;
    });
  }

  handleStandardInput = e => {
    let invalids = this.state.invalids;
    invalids[e.target.name] = false;
    this.setState({ [e.target.name]: e.target.value, invalids });
  };

  handleStandardCheckbox = coercer => {
    return (checked, name, e) => {
      let invalids = this.state.invalids;
      invalids[name] = false;
      this.setState({ [name]: coercer(checked), invalids });
    };
  };

  handleToolCheckbox = (value, id) => {
    this.setState({
      selected_tools: {
        ...this.state.selected_tools,
        [id]: value,
      },
    });
  };

  checkbox_helpers = {
    role: {
      coercer: checked => (checked ? 'admin' : 'user'),
      decoder: value => (value === 'admin' ? true : false),
    },
  };

  form_handlers = {
    role: this.handleStandardCheckbox(this.checkbox_helpers.role.coercer),
  };

  clearForm = () => {
    let x = {
      ...this.form_keys,
    };
    this.setState(x);
  };

  // return true if our stored state is valid for submitting to the API, false if not (and update state.invalids if it isn't)
  valid = () => {
    const { state } = this;

    // when we validate, everything starts as assumed valid
    let invalids = _.mapObject(this.form_keys, (k, v) => {
      return false;
    });

    // TODO: encode validation rules into some sort of code so we can DRY this up, and put the validation message in the form_keys struct above

    // go through our validation rules, mark things invalid that don't match the rules
    if (!state.email) invalids['email'] = 'Must provide an email address';

    if (
      this.is_create() ||
      (this.is_update() && (state.password || state.password_confirmation))
    ) {
      if (!state.password)
        invalids['password'] = 'Must use your 4 digit PIN as your password';
      if (!state.password_confirmation)
        invalids['password_confirmation'] =
          'Passwords must match and not be blank';
      if (!(state.password === state.password_confirmation))
        invalids['password_confirmation'] =
          'Passwords must match and not be blank';
      if (
        state.password &&
        (state.password.length < 4 || state.password.length > 4)
      )
        invalids['password'] = 'Must use your 4 digit PIN as your password';
    }
    if (!state.name) invalids['name'] = 'Must provide a full name';

    // validate email is an email: done by the carbon component

    // if we didn't pass validation, update the state and return false
    let invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (invalid) {
      this.setState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  user_tools_attributes = () => {
    const { user } = this.props;
    const { selected_tools } = this.state;

    const id = i => parseInt(i.replace('tool-', ''), 10);

    // rails wants:
    //  // * identical contents for the stuff we already had
    //  * nothing for the stuff we already had
    //  * user_tool.id, user_tool.tool_id and _destroy: '1' for the stuff we're deleting
    //  * tool_id for the stuff we're adding

    const tool_ids_we_already_had = ((user && user.user_tools) || []).map(
      t => t.tool_id
    );

    const tool_ids_selected = Object.keys(selected_tools)
      .filter(t => selected_tools[t])
      .map(t => id(t));

    const tool_ids_we_are_deleting = tool_ids_we_already_had.filter(
      id => !tool_ids_selected.includes(id)
    );

    const tool_ids_we_are_adding = tool_ids_selected.filter(
      id => !tool_ids_we_already_had.includes(id)
    );

    const from_existing = ((user && user.user_tools) || [])
      .map(user_tool => {
        if (tool_ids_we_are_deleting.includes(user_tool.tool_id)) {
          return {
            id: user_tool.id,
            tool_id: user_tool.tool_id,
            _destroy: '1',
          };
        } else {
          return null;
        }
      })
      .filter(t => !!t);

    const new_user_tools = tool_ids_we_are_adding.map(id => {
      return { tool_id: id };
    });

    return from_existing.concat(new_user_tools);
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (this.valid()) {
      try {
        let endpoint = 'users';
        let api_method = api.post;
        let user_to_post = _.pick(this.state, _.keys(this.form_keys));
        if (this.is_update()) {
          api_method = api.put;
          endpoint = `users/${this.state.id}.json`;
          if (!user_to_post.password) {
            delete user_to_post.password;
            delete user_to_post.password_confirmation;
          }
        }
        user_to_post = api.user_audit_at_helper(user_to_post, 'user');

        user_to_post.user_tools_attributes = this.user_tools_attributes();

        const resp = await api_method(endpoint, null, {
          user: user_to_post,
        });
        if (this.is_ok_response(resp)) {
          this.clearForm();
          const { handleAddedUser, handleSaveUser } = this.props;
          if (this.is_create()) {
            handleAddedUser && handleAddedUser(resp);
          } else if (this.is_update()) {
            handleSaveUser && handleSaveUser(resp);
          } else {
            alert('internal error');
          }
        } else {
          alert(this.create_response_error_to_string(resp));
        }
      } catch (err) {
        alert('Sorry, there was an unexpected error.');
        console.log(err);
      }
    }
  };

  // helpers: are we creating or updating an object?
  is_create = () => {
    return !this.state.id;
  };
  is_update = () => {
    return !!this.state.id;
  };

  // helper: so we can namespace the IDs if necessary
  id_for = id => {
    return id;
  };

  // helper: unpack our validity data structure to map to what the carbon components are expecting
  invalid_for = id => {
    if (!!this.state.invalids[id]) {
      return {
        invalid: !!this.state.invalids[id],
        invalidText: this.state.invalids[id],
      };
    } else {
      return {};
    }
  };

  // helper: these are the standard props we pass to most input components
  field_boilerplate = id => {
    return {
      id: this.id_for(id),
      name: id,
      onChange: this.form_handlers[id]
        ? this.form_handlers[id]
        : this.handleStandardInput,
      ...this.invalid_for(id),
      ...(this.form_labels[id] ? { labelText: this.form_labels[id] } : {}),
    };
  };

  text_field_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
      value: this.state[id],
    };
  };

  checkbox_field_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
      checked: this.checkbox_helpers[id].decoder(this.state[id]),
    };
  };

  // helper: is the API response body for the action an OK?
  is_ok_response = response => {
    return response && response.id;
  };

  // helper: convert the create response body to an error string
  create_response_error_to_string = response => {
    return _.map(_.keys(response), key => `${key} ${response[key]}`).join('\n');
  };

  render() {
    const { selected_tools } = this.state;
    const {
      handleCancelUserEdit,
      cancelButtonLabel,
      saveButtonLabel,
      tools,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <TextInput {...this.text_field_boilerplate('name')} />
        <TextInput {...this.text_field_boilerplate('email')} type="email" />
        <TextInput {...this.text_field_boilerplate('title')} />
        <TextInput
          {...this.text_field_boilerplate('password')}
          type="password"
        />
        <TextInput
          {...this.text_field_boilerplate('password_confirmation')}
          type="password"
        />
        <Checkbox {...this.checkbox_field_boilerplate('role')} />
        <Tools
          all_tools={tools}
          selected_tools={selected_tools}
          handleToolCheckbox={this.handleToolCheckbox}
        />
        <Button className="add-user" type="submit">
          {saveButtonLabel ? saveButtonLabel : 'Add User'}
        </Button>
        {handleCancelUserEdit && (
          <Button className="add-user-cancel" onClick={handleCancelUserEdit}>
            {cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
          </Button>
        )}
      </Form>
    );
  }
}

const Tools = function(props) {
  const { all_tools, selected_tools, handleToolCheckbox } = props;

  const tool_selected = tool => {
    return !!selected_tools && !!selected_tools[`tool-${tool.id}`];
  };
  return (
    <>
      <p>Tools</p>
      <ul>
        {all_tools.map(tool => (
          <li key={tool.id}>
            <Checkbox
              id={`tool-${tool.id}`}
              name={tool.title}
              disabled={tool.standard}
              checked={tool.standard || tool_selected(tool)}
              labelText={tool.title}
              onChange={handleToolCheckbox}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default UserForm;
