import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl, clresponse } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for, month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';
import { useDidMount, scrollToFragmentOnMount } from '../shared/on_mount';

import {
  Loading,
  InlineNotification,
  Button,
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  TimePicker,
  TimePickerSelect,
  Select,
  SelectItem,
  SelectItemGroup,
  Form,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Modal,
} from 'carbon-components-react';
import {
  ArrowLeft16,
  Add16,
  Save16,
  ChevronRight16,
} from '@carbon/icons-react';

import TdSelect from '../shared/Select';
import TdCheckboxGroup from '../shared/CheckboxGroup';
import IncidentReportImage from '../IncidentReportImage';
import SignaturePad from 'react-signature-pad-wrapper';
import { tupleTypeAnnotation } from 'babel-types';
import Breadcrumbs from '../shared/Breadcrumbs';
import RichTextArea from '../shared/RichTextArea';

function IncidentReport({
  id,
  user,
  offline,
  incident_reports,
  handleUpdateIncidentReport,
  onCreateIncidentReport,
  onUpdateIncidentReport,
  history,
}) {
  let initial_incident_report = null;
  if (Array.isArray(incident_reports)) {
    initial_incident_report = incident_reports.find(pl => to_s(pl.id) == id);
  }

  const form_keys = [
    'report_date',
    'report_time',
    'incident_date',
    'incident_time',
    'jobsite',
    'number',
    'superintendent',
    'project_manager',
    'weather',
    'temperature',
    'shift_start_time',
    'shift_stop_time',
    'employee_name',
    'employee_gender',
    'employee_job_title',
    'just_before_prose',
    'what_happened_prose',
    'witnessed',
    'anyone_injured',
    'person_taking_pictures',
    'injuries_who_medical',
    'injuries_blood_involved',
    'injuries_blood_who_cleaned',
    'injuries_what_was_injury',
    'injuries_what_object',
    'equipment',
    'police_report',
    'police_report_who',
    'statements',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  form_keys['weather'] = [];
  form_keys['equipment'] = [];
  form_keys['statements'] = [];

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for Incident

  const [incident_report, setIncidentReport] = useState(
    initial_incident_report
  );
  const editable = true; // was !incident_report.approved

  const didMount = useDidMount();
  useEffect(() => scrollToFragmentOnMount(didMount), [
    incident_report,
    didMount,
  ]);

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    weather: [],
    equipment: [],
    statements: [],
    ...(initial_incident_report ? initial_incident_report : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = updatedValues =>
    _setFormState(prevState => {
      return { ...form_state, ...updatedValues };
    });

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    invalids = fh.validate(
      invalids,
      'jobsite',
      form_state.jobsite,
      'Must provide a jobsite'
    );
    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const handle_submit = async e => {
    return await fh.handle_submit({
      e,
      endpoint: 'incident_reports',
      model: 'incident_report',
      state: form_state,
      fields: form_keys,
      valid,
      onCreate: onCreateIncidentReport,
      onUpdate: onUpdateIncidentReport,
      clear_form: () => false, // noop for this form
      onSuccess: () => {
        history.push(to);
      },
    });
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: setFormState,
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };

  const save_wip = (newdata = {}) => {
    const wip = {
      ...incident_report,
      ..._.pick(form_state, _.keys(form_keys)),
      ...newdata,
    };
    if (onUpdateIncidentReport) onUpdateIncidentReport(wip);
    return wip;
  };
  const handle_add_equipment = () => {
    const wip = save_wip({
      equipment: form_state.equipment.concat([incident_report.new_equipment]),
    });
    setFormState(wip);
    handleIncidentReportEquipmentClick(wip.equipment.length - 1);
  };

  const handle_add_statement = () => {
    const wip = save_wip({
      statements: form_state.statements.concat([incident_report.new_statement]),
    });
    setFormState(wip);
    handleIncidentReportStatementClick(wip.statements.length - 1);
  };

  // END form handling boilerplate and custom

  const reloadData = async () => {
    // TODO: lots of floating this up with handleUpdateIncidentReport.... should put this in redux
    const incident_report = await api.get(`incident_reports/${id}`);
    if (handleUpdateIncidentReport) handleUpdateIncidentReport(incident_report);
    setIncidentReport(incident_report);
    setFormState(incident_report);
  };
  useEffect(() => {
    if (!incident_report) reloadData();
  }, []);

  const [drawingPreloading, setDrawingPreloading] = useState(false);
  const [drawingPreloaded, setDrawingPreloaded] = useState(false);
  function preloadDrawing() {
    if (drawingPreloaded) {
      return;
    }
    if (incident_report && incident_report.drawing_url) {
      if (!offline) {
        // async load the drawing from drawing_url
        if (drawingPreloading) return;
        setDrawingPreloading(true);
        api
          .get(incident_report.drawing_url, null, {
            response_decoder: async (request, response) => {
              const decoded_response = {
                status: 500,
                body_decoded:
                  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
              };
              if (!response) {
                return decoded_response;
              }
              if (response.status === 200) {
                decoded_response.status = response.status;
                decoded_response.body_decoded = URL.createObjectURL(
                  await response.blob()
                );
              }

              return decoded_response;
            },
          })
          .then(objectURL => {
            drawingRef.current.fromDataURL(objectURL);
            URL.revokeObjectURL(objectURL);
            setDrawingPreloaded(true);
          });
      } else {
        // warn that we got a URL from the server but we're offline and can't load it: adding a drawing will overwrite whatever's on the server
        // warning is computed below: if (offline && !drawingPreloaded && incident_report.drawing_url)
      }
    } else {
      // no drawing_url stored on server, don't need to preload or warn about offline.  do nothing.
    }
  }
  useEffect(preloadDrawing);

  const drawingModalHeading =
    incident_report &&
    offline &&
    !drawingPreloaded &&
    incident_report.drawing_url
      ? 'Edit Drawing (note: offline, will overwrite previous drawing on save)'
      : 'Edit Drawing';

  const preventCloseOnClickOutside = false;
  let drawingRef = useRef();
  let drawingModalRef = useRef();
  let drawingTriggerButtonRef = useRef();
  const [drawingOpen, setDrawingOpen] = useState(false);

  function handleOpenDrawing() {
    setDrawingOpen(true);
  }

  function handleCloseDrawing(evt) {
    if (
      evt &&
      !drawingModalRef.current.innerModal.current.contains(evt.target) &&
      preventCloseOnClickOutside
    ) {
      return;
    } else {
      setDrawingOpen(false);
      drawingTriggerButtonRef.current.focus();
    }
  }

  async function handleUpdatedDrawing() {
    const blob = await (await fetch(drawingRef.current.toDataURL())).blob();

    const incident_report_formdata = new FormData();
    incident_report_formdata.append(
      'incident_report[drawings][]',
      blob,
      'drawing.png'
    );
    api.user_audit_at_helper(incident_report_formdata, 'incident_report');
    const resp = await api.put_formdata(
      `incident_reports/${incident_report.id}`,
      null,
      incident_report_formdata
    );

    if (resp && resp.id) {
      reloadData();
      handleCloseDrawing();
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  }

  function handleNewPhotoClick() {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/png, image/jpeg';
    inputElement.multiple = false;
    inputElement.addEventListener('change', async e => {
      const incident_report_image = new FormData();
      incident_report_image.append(
        'incident_report_image[incident_report_id]',
        incident_report.id
      );
      incident_report_image.append(
        'incident_report_image[image]',
        e.target.files[0]
      );
      api.user_audit_at_helper(incident_report_image, 'incident_report_image');
      const resp = await api.post_formdata(
        `incident_reports/${incident_report.id}/images`,
        null,
        incident_report_image
      );

      if (resp && resp.id) {
        reloadData();
      } else {
        alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
      }
      document.body.removeChild(inputElement);
    });
    inputElement.style.visibility = 'hidden';
    document.body.appendChild(inputElement);
    inputElement.dispatchEvent(new MouseEvent('click'));
  }

  async function removeImage(removed_image) {
    if (
      !window.confirm(
        'Do you really want to remove this image?  Press "OK" to remove image.'
      )
    )
      return;

    const response = await api.delete(
      `incident_reports/${incident_report.id}/images/${removed_image.id}`,
      api.user_audit_at_helper({}, 'incident_report_image')
    );
    const new_incident_report = {
      ...incident_report,
      images: _.reject(incident_report.images, image => {
        return image.id == removed_image.id;
      }),
    };
    if (handleUpdateIncidentReport)
      handleUpdateIncidentReport(new_incident_report);
    setIncidentReport(new_incident_report);
  }

  function handleSaveIncidentReportImage(new_incident_report_image) {
    const new_incident_report = {
      ...incident_report,
      images: incident_report.images.map(image =>
        new_incident_report_image.id == image.id
          ? new_incident_report_image
          : image
      ),
    };
    if (handleUpdateIncidentReport)
      handleUpdateIncidentReport(new_incident_report);
    setIncidentReport(new_incident_report);
  }

  const project_name =
    incident_report && incident_report.project
      ? incident_report.project.name
      : '';
  const code =
    incident_report && incident_report.project
      ? incident_report.project.code
      : '';
  const when =
    incident_report && incident_report.logged_at
      ? full_month_day_year_for(incident_report.logged_at)
      : '';
  const to =
    incident_report && incident_report.project
      ? `/incident_reports/projects/${incident_report.project.id}`
      : '';

  const handleIncidentReportEquipmentClick = equipment_id =>
    history.push(`/incident_reports/${id}/equipment/${equipment_id}`);

  const handleIncidentReportStatementClick = statement_id =>
    history.push(`/incident_reports/${id}/statement/${statement_id}`);

  function audit_markup() {
    if (
      !incident_report ||
      !incident_report.audits ||
      incident_report.audits.length === 0
    )
      return '';
    const li_for = function(audit) {
      let user_info = 'an unknown user';
      if (audit.user)
        user_info = <a href={`/users/${audit.user.id}`}>{audit.user.name}</a>;
      const friendly_message =
        audit.friendly_message || 'did something unknown';
      return (
        <li key={audit.id}>
          At {moment(audit.user_audit_at).format('YYYY/MM/DD h:mm A')}{' '}
          {user_info} {friendly_message}
        </li>
      );
    };
    return (
      <div className="mobile-hide">
        <h3>Audit Trail</h3>
        <ul>{incident_report.audits.map(audit => li_for(audit))}</ul>
      </div>
    );
  }

  function reports_markup() {
    if (
      !incident_report ||
      !incident_report.reports ||
      incident_report.reports.length === 0
    )
      return '';
    return (
      <div className="mobile-hide">
        <h3>Generated Reports</h3>
        <ul>
          {incident_report.reports.map(report => (
            <li key={report.id}>
              <a
                href={api.fixup_url(
                  `incident_reports/${incident_report.id}/report/${report.id}`
                )}
              >
                Report generated at{' '}
                {moment(report.created_at).format('YYYY/MM/DD h:mm A')}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  let content = <Loading small={false} active={true} />;
  if (!incident_report) {
    content = <Loading small={false} active={true} />;
  } else if (!incident_report.id && offline) {
    content = (
      <p>
        You are offline, and we do not have this Incident Report cached, sorry.
      </p>
    );
  } else if (!incident_report.id && !offline) {
    content = (
      <p>
        You do not have access to that Incident Report, or it does not exist.
      </p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title={`${!editable ? 'Viewing' : 'Editing'} Report`} />
        {user.role === 'admin' &&
          incident_report.has_any_report &&
          !incident_report.has_current_report && (
            <InlineNotification
              title="There have been changes to this Incident Report since the last report was generated."
              subtitle="You can re-generate the report."
              kind="info"
              hideCloseButton={true}
              className="mobile-hide"
            />
          )}
        <Form onSubmit={handle_submit}>
          <DatePicker
            id="report_date"
            datePickerType="single"
            onChange={v =>
              fh.handle_nonstandard_input({
                field: 'report_date',
                value: v[0],
                state: form_state,
                setState: setFormState,
              })
            }
            dateFormat="Y/m/d"
            value={form_state.report_date}
          >
            <DatePickerInput
              id="date-made-inner"
              labelText="Report Date"
              pattern="\d{4}\/\d{1,2}\/\d{1,2}"
            />
          </DatePicker>
          <TextInput {...vfb('report_time')} labelText="Report Time" />
          <DatePicker
            id="incident_date"
            datePickerType="single"
            onChange={v =>
              fh.handle_nonstandard_input({
                field: 'incident_date',
                value: v[0],
                state: form_state,
                setState: setFormState,
              })
            }
            dateFormat="Y/m/d"
            value={form_state.incident_date}
          >
            <DatePickerInput
              id="date-made-inner"
              labelText="Incident Date"
              pattern="\d{4}\/\d{1,2}\/\d{1,2}"
            />
          </DatePicker>
          <TextInput {...vfb('incident_time')} labelText="Incident Time" />
          <TextInput {...vfb('jobsite')} labelText="Jobsite" />
          <TextInput {...vfb('number')} labelText="Number" />
          <TextInput {...vfb('superintendent')} labelText="Superintendent" />
          <TextInput {...vfb('project_manager')} labelText="Project Manager" />
          <TdCheckboxGroup
            id="weather"
            onChange={v =>
              fh.handle_nonstandard_input({
                field: 'weather',
                value: v,
                state: form_state,
                setState: setFormState,
              })
            }
            label="Weather"
            value={form_state['weather']}
            select_list={incident_report.select_lists.find(
              i => i.code === 'incident-weather'
            )}
          />

          <TextInput {...nvfb('temperature')} labelText="Temperature (F)" />
          <TextInput
            {...vfb('shift_start_time')}
            labelText="Shift Start Time"
          />
          <TextInput {...vfb('shift_stop_time')} labelText="Shift Stop Time" />
          <TextInput {...vfb('employee_name')} labelText="Employee Name" />
          <TextInput {...vfb('employee_gender')} labelText="Employee Gender" />
          <TextInput
            {...vfb('employee_job_title')}
            labelText="Employee Job Title"
          />
          <RichTextArea
            {...vfb('just_before_prose')}
            labelText="What was the employee doing just before the incident occurred?"
          />
          <RichTextArea
            {...vfb('what_happened_prose')}
            labelText="What happened? (Be specific)"
          />
          <TdSelect
            {...vfb('witnessed')}
            labelText="Did anyone witness the incident?"
            select_list={incident_report.select_lists.find(
              i => i.code === 'incident-yesno'
            )}
          />
          {fh.is_true(form_state['witnessed']) && (
            <p>If YES, Fill out a Witness Statement for EACH witness.</p>
          )}
          <TdSelect
            {...vfb('anyone_injured')}
            labelText="Was anyone injured?"
            select_list={incident_report.select_lists.find(
              i => i.code === 'incident-yesno'
            )}
          />
          {fh.is_true(form_state['anyone_injured']) && (
            <>
              <p>
                Take pictures of all equipment or materials involved in the
                incident BEFORE anything is moved.
              </p>
              <Button renderIcon={Add16} onClick={handleNewPhotoClick}>
                New Photo
              </Button>
              <div className="td-photo-group">
                {incident_report.images.map(image => (
                  <IncidentReportImage
                    key={image.id}
                    incident_report={incident_report}
                    incident_report_image={image}
                    removeImage={() => removeImage(image)}
                    handleSaveIncidentReportImage={
                      handleSaveIncidentReportImage
                    }
                  />
                ))}
              </div>
              <TextInput
                {...vfb('person_taking_pictures')}
                labelText="Person taking pictures"
              />
              <p>Injuries</p>
              <TextInput
                {...vfb('injuries_who_medical')}
                labelText="Who provided medical attention?"
              />
              <TdSelect
                {...vfb('injuries_blood_involved')}
                labelText="Was blood involved?"
                select_list={incident_report.select_lists.find(
                  i => i.code === 'incident-yesno'
                )}
              />
              {fh.is_true(form_state['injuries_blood_involved']) && (
                <TextInput
                  {...vfb('injuries_blood_who_cleaned')}
                  labelText="Who cleaned up the blood?"
                />
              )}
              <RichTextArea
                {...vfb('injuries_what_was_injury')}
                labelText="What was the injury or illness? (Be specific - fractured left ring finger rather than hurt hand)"
              />
              <RichTextArea
                {...vfb('injuries_what_object')}
                labelText="What object or substance directly harmed the employee? (Example: concrete floor)"
              />
            </>
          )}
          <hr className="bx--form-item" />
          <h2 id="equipment">Equipment</h2>
          <StructuredListWrapper selection>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Equipment involved</StructuredListCell>
                <StructuredListCell head>Number</StructuredListCell>
                <StructuredListCell head />
              </StructuredListRow>
            </StructuredListHead>

            <StructuredListBody>
              {incident_report.equipment.map((equipment, equipment_id) => (
                <StructuredListRow
                  key={equipment_id}
                  onClick={() => {
                    save_wip();
                    handleIncidentReportEquipmentClick(equipment_id);
                  }}
                >
                  <StructuredListCell>
                    {equipment.equipment_involved || 'No Equipment Set'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {equipment.number || 'No Number Set'}
                  </StructuredListCell>
                  <StructuredListCell>
                    <ChevronRight16 />
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>
          {editable && (
            <div className="terradon--align-right">
              <Button
                className="terradon--form-item"
                renderIcon={Add16}
                onClick={handle_add_equipment}
              >
                Add Equipment
              </Button>
            </div>
          )}
          <TdSelect
            {...vfb('police_report')}
            labelText="Was a police report taken?"
            select_list={incident_report.select_lists.find(
              i => i.code === 'incident-yesno'
            )}
          />
          {fh.is_true(form_state['police_report']) && (
            <TextInput
              {...vfb('police_report_who')}
              labelText="If Yes, by who?"
            />
          )}
          <p>
            Provide a detailed drawing including street names, directional
            arrows, equipment, witnesses, etc.
          </p>
          <div
            role="presentation"
            onKeyDown={evt => {
              if (evt.which === 27) {
                handleCloseDrawing();
                onKeyDown(evt);
              }
            }}
          >
            <Button onClick={handleOpenDrawing} ref={drawingTriggerButtonRef}>
              Edit Drawing
            </Button>
            <Modal
              modalHeading={drawingModalHeading}
              modalLabel="Drawing"
              open={drawingOpen}
              ref={drawingModalRef}
              onRequestClose={handleCloseDrawing}
              onRequestSubmit={handleUpdatedDrawing}
              primaryButtonText="Save"
              secondaryButtonText="Cancel"
              className="td-drawing-modal"
            >
              {/* using https://github.com/michaeldzjap/react-signature-pad-wrapper */}
              {/* alternate https://github.com/tbolis/react-sketch/blob/master/examples/main.jsx https://tbolis.github.io/showcase/react-sketch/ */}
              <p>
                Use your finger or a stylus to draw your diagram. This works
                best on a tablet.
              </p>
              <SignaturePad redrawOnResize={true} ref={drawingRef} />
            </Modal>
          </div>
          <hr className="bx--form-item" />
          <h2 id="statements">Witness Statements</h2>
          <StructuredListWrapper selection>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>Name of Employee</StructuredListCell>
                <StructuredListCell head>Job Title</StructuredListCell>
                <StructuredListCell head />
              </StructuredListRow>
            </StructuredListHead>

            <StructuredListBody>
              {incident_report.statements.map((statement, statement_id) => (
                <StructuredListRow
                  key={statement_id}
                  onClick={() => {
                    save_wip();
                    handleIncidentReportStatementClick(statement_id);
                  }}
                >
                  <StructuredListCell>
                    {statement.employee_name || 'No Employee Name Set'}
                  </StructuredListCell>
                  <StructuredListCell>
                    {statement.job_title || 'No Job Title Set'}
                  </StructuredListCell>
                  <StructuredListCell>
                    <ChevronRight16 />
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>
          {editable && (
            <div className="terradon--align-right">
              <Button
                className="terradon--form-item"
                renderIcon={Add16}
                onClick={handle_add_statement}
              >
                Add Statement
              </Button>
            </div>
          )}
          {editable && (
            <Button renderIcon={Save16} type="submit">
              Save
            </Button>
          )}
        </Form>
        {user.role === 'admin' && audit_markup(incident_report)}
        {user.role === 'admin' && reports_markup()}
      </>
    );
  }

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="incident_reports"
          obj={incident_report}
          second="Incident Reports"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(IncidentReport);
