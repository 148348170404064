import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import { full_redux_state } from '../../redux/connectors';
import { to_s } from '../../common';

import ProjectSoilReports from './ProjectSoilReports';
import SoilReport from '../SoilReport';
import SoilReportMaterial from '../SoilReportMaterial';
import SoilReportTest from '../SoilReportTest';

import { Loading, Button } from 'carbon-components-react';

function SoilReports({ user, offline, history }) {
  const [soil_reports, setSoilReports] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    (async () => {
      const projects = await api.get('projects');
      setProjects(projects);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const soil_reports = (await api.get('soil_reports')).sort((a, b) => {
        // sort by .logged_at desc
        a = Date.parse(a.logged_at);
        b = Date.parse(b.logged_at);
        if (b < a) return -1;
        if (b > a) return 1;
        return 0;
      });
      setSoilReports(soil_reports);
    })();
  }, []);

  const handleProjectClick = id => history.push(`/soil_reports/projects/${id}`);
  const handleSoilReportClick = id => history.push(`/soil_reports/${id}`);

  const handleNewLogClick = async project_id => {
    const soil_report = api.user_audit_at_helper({ project_id }, 'soil_report');
    const resp = await api.post('soil_reports', null, { soil_report });
    if (resp && resp.id) {
      setSoilReports([resp].concat(soil_reports));
      history.push(`/soil_reports/${resp.id}`);
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  };

  const handleUpdateSoilReport = new_soil_report => {
    if (new_soil_report && soil_reports)
      setSoilReports(
        soil_reports.map(soil_report =>
          soil_report.id == new_soil_report.id ? new_soil_report : soil_report
        )
      );
  };

  return (
    <Switch>
      <Route
        path="/soil_reports/:id/tests/:test_id"
        render={({ match }) => (
          <SoilReportTest
            id={match.params.id}
            test_id={match.params.test_id}
            soil_reports={soil_reports}
            onUpdateSoilReport={handleUpdateSoilReport}
            history={history}
          />
        )}
      />
      <Route
        path="/soil_reports/:id/materials/:material_id"
        render={({ match }) => (
          <SoilReportMaterial
            id={match.params.id}
            material_id={match.params.material_id}
            soil_reports={soil_reports}
            onUpdateSoilReport={handleUpdateSoilReport}
            history={history}
          />
        )}
      />
      <Route
        path="/soil_reports/projects/:id"
        render={({ match }) => {
          const project = (projects || []).find(
            p => to_s(p.id) === match.params.id
          );
          return (
            <ProjectSoilReports
              id={match.params.id}
              project={project}
              soil_reports={soil_reports}
              onDetailClick={handleSoilReportClick}
              onNewLogClick={handleNewLogClick}
              onUpdateSoilReport={handleUpdateSoilReport}
            />
          );
        }}
      />
      <Route
        path="/soil_reports/:id"
        render={({ match }) => (
          <SoilReport
            id={match.params.id}
            soil_reports={soil_reports}
            onUpdateSoilReport={handleUpdateSoilReport}
            history={history}
          />
        )}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export default connect(full_redux_state)(SoilReports);
