import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s, date_to_s, date_formats } from '../../common';
import { cl } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';
import { useDidMount, scrollToTopOnMount } from '../shared/on_mount';

import {
  Loading,
  InlineNotification,
  Button,
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  TimePicker,
  Select,
  SelectItem,
  SelectItemGroup,
  Form,
} from 'carbon-components-react';
import { ArrowLeft16, Add16 } from '@carbon/icons-react';
import TdSelect from '../shared/Select';
import Breadcrumbs from '../shared/Breadcrumbs';

function AsphaltReportMaterial({
  id,
  material_id,
  user,
  offline,
  asphalt_reports,
  onUpdateAsphaltReport,
  history,
}) {
  let initial_asphalt_report = null;
  if (Array.isArray(asphalt_reports)) {
    initial_asphalt_report = asphalt_reports.find(pl => to_s(pl.id) == id);
  }
  let initial_asphalt_report_material = null;
  if (
    initial_asphalt_report &&
    Array.isArray(initial_asphalt_report.materials)
  ) {
    initial_asphalt_report_material =
      initial_asphalt_report.materials[material_id];
  }

  const form_keys = [
    'material_number',
    'material_description',
    'test_method',
    'max_wet_density',
    'range_min',
    'range_max',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for Asphalt

  const [asphalt_report, setAsphaltReport] = useState(initial_asphalt_report);
  const editable = true; // was !asphalt_report.approved
  const [asphalt_report_material, setAsphaltReportMaterial] = useState(
    initial_asphalt_report_material
  );
  const didMount = useDidMount();
  useEffect(() => scrollToTopOnMount(didMount), [
    asphalt_report_material,
    didMount,
  ]);

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    ...(initial_asphalt_report_material ? initial_asphalt_report_material : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = updatedValues =>
    _setFormState(prevState => {
      return { ...form_state, ...updatedValues };
    });

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    // TODO: validations?

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: setFormState,
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };
  const fvfb = id => {
    return { ...vfb(id), type: 'number', step: '0.01' };
  };

  // END form handling boilerplate and custom

  const reloadData = async () => {
    // TODO: lots of floating this up with handleUpdateAsphaltReport.... should put this in redux
    const asphalt_report = await api.get(`asphalt_reports/${id}`);
    if (onUpdateAsphaltReport) onUpdateAsphaltReport(asphalt_report);
    setAsphaltReport(asphalt_report);
    setAsphaltReportMaterial(asphalt_report.materials[material_id]);
    setFormState(asphalt_report.materials[material_id]);
  };
  useEffect(() => {
    if (!asphalt_report) reloadData();
  }, []);

  const project_name =
    asphalt_report && asphalt_report.project ? asphalt_report.project.name : '';
  const code =
    asphalt_report && asphalt_report.project ? asphalt_report.project.code : '';
  const when =
    asphalt_report && asphalt_report.date_made
      ? full_month_day_year_for(
          asphalt_report.date_made || asphalt_report.logged_at
        )
      : '';

  const handle_save = () => {
    // load our form_state into our asphalt_report
    // then percolate that up (onUpdateAsphaltReport(asphalt_report))
    // then redirect to the main asphalt report page, scrolled down to materials
    asphalt_report.materials[material_id] = _.pick(
      form_state,
      _.keys(form_keys)
    );
    if (onUpdateAsphaltReport) onUpdateAsphaltReport(asphalt_report);
    history.push(`/asphalt_reports/${id}#materials`);
  };

  const handle_delete = () => {
    asphalt_report.materials.splice(material_id, 1);
    if (onUpdateAsphaltReport) onUpdateAsphaltReport(asphalt_report);
    history.push(`/asphalt_reports/${id}#materials`);
  };

  let content = '';
  if (!asphalt_report) {
    content = <Loading small={false} active={true} />;
  } else if (!asphalt_report.id && offline) {
    content = (
      <p>
        You are offline, and we do not have this Asphalt Report cached, sorry.
      </p>
    );
  } else if (!asphalt_report.id && !offline) {
    content = (
      <p>
        You do not have access to that Asphalt Report, or it does not exist.
      </p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title={`${!editable ? 'Viewing' : 'Editing'} Material`} />
        <TextInput {...vfb('material_number')} labelText="Material Number" />
        <TextInput
          {...vfb('material_description')}
          labelText="Material Description"
        />
        <TextInput {...vfb('test_method')} labelText="Test Method" />
        <TextInput {...fvfb('max_wet_density')} labelText="Max Wet Density" />
        <TextInput
          {...fvfb('range_min')}
          labelText="Density Percentage Range (min)"
        />
        <TextInput
          {...fvfb('range_max')}
          labelText="Density Percentage Range (max)"
        />

        {editable && (
          <>
            <Button kind="ghost" onClick={handle_delete}>
              Delete Material
            </Button>
            <Button onClick={handle_save}>Update Material</Button>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="asphalt_reports"
          obj={asphalt_report}
          second="Asphalt Reports"
          third="Asphalt Report"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(AsphaltReportMaterial);
