import { cl, clrequest, clrequestfull, clresponse, clcache } from '../debug';
import { isAuth, isAPI } from './helpers';
import { write_cached_auth_data, clear_cached_auth_data } from './auth';
import {
  decode_api_url,
  subobject_specs_for,
  superobject_specs_for,
  wrap_array,
} from './data_model';
import { is_uncommitted_object } from './temp_ids';
import { unhandled } from './handler_common';
import {
  datastore,
  getItem,
  setItem,
  removeItem,
  getStoreItemIDs,
} from '../localforage';
import { to_s } from '../common';
import { includes_temp_id } from './temp_ids';
import api from '../api';

async function handle_successful_fetch(request, response) {
  const decoded_response = { status: response.status, body_decoded: {} };

  try {
    const target = decode_api_url(request.url);

    if (target.has_uncommitted_object_reference) {
      throw `uncommitted object_reference found in successful to-live URL ${
        request.url
      }`;
    }

    // extract and decode actual response.  We need it in here, and we return it up to js/api.js for use there, too
    let txt = null;
    try {
      txt = await response.text();
      if (txt && txt.length) {
        decoded_response.body_decoded = JSON.parse(txt);
      } else {
        decoded_response.body_decoded = {};
      }
      cl('apiFetch response', request.url, decoded_response.body_decoded);
    } catch (ex) {
      cl('error parsing response', request.url, ex, txt || '<no txt returned>');
      return decoded_response;
    }

    if (isAuth(request)) {
      if (request.method === 'DELETE') {
        await clear_cached_auth_data();
      } else {
        write_cached_auth_data(decoded_response.body_decoded);
      }
    } else if (isAPI(request)) {
      async function store_individual(subobject_specs, model, individual) {
        // if individual has subobjects, break those subobjects out into objects stored in the correct datastore(subobject)
        // if individual has subobjects, and the existing stored individual has TMP IDs in the subobject, make sure to add those back in

        const existing_individual = await getItem(model, individual.id);

        for (let subobject_spec of subobject_specs) {
          const existing_has_subobjects =
            existing_individual && existing_individual[subobject_spec.local];

          // write new subobject individuals into cache
          if (individual[subobject_spec.local]) {
            for (let subobject_individual of individual[subobject_spec.local]) {
              // DEBT: could Promise.all this
              await setItem(
                subobject_spec.model,
                subobject_individual.id,
                subobject_individual
              );
            }
            // get list of IDs from existing_individual[subobject_spec.local].  Any of those that don't appear in `individual` we need to evict from the subobject_spec.model store.
            if (existing_has_subobjects) {
              const existing_cache_ids = new Set(
                existing_individual[subobject_spec.local].map(o => to_s(o.id))
              );
              const ids_in_this_load = new Set(
                individual[subobject_spec.local].map(o => to_s(o.id))
              );
              const to_evict = new Set(
                [...existing_cache_ids].filter(id => !ids_in_this_load.has(id))
              );
              for (let id_to_evict of to_evict) {
                if (!is_uncommitted_object(id_to_evict)) {
                  cl(
                    'GET evicting unseen subobject',
                    subobject_spec.model,
                    id_to_evict
                  );
                  await removeItem(subobject_spec.model, id_to_evict);
                }
              }
            }
          }
          // load existing_individual subobject temp items into individual
          if (existing_has_subobjects) {
            const uncommitted_objects = existing_individual[
              subobject_spec.local
            ].filter(e => is_uncommitted_object(e));
            individual[subobject_spec.local] = (
              individual[subobject_spec.local] || []
            ).concat(uncommitted_objects);
          }
        }

        let id = individual.id;
        // special case for select_lists, we store by code not ID.  TODO: paramaterize this so if we find another model that behaves this way we don't have to add another special case
        if (model == 'select_lists') id = individual.code;
        await setItem(model, id, individual);
      }

      if (request.method === 'GET') {
        if (target.collection) {
          // GET /photo_logs, /projects
          // break down body object as array, store in datastore(target.object) with keys being the IDs in the objects in the array
          if (Array.isArray(decoded_response.body_decoded)) {
            let subobject_specs = subobject_specs_for(target.object);
            for (let individual of decoded_response.body_decoded) {
              await store_individual(
                subobject_specs,
                target.object,
                individual
              );
            }

            // if there are any embedded select_lists, then cache them
            if (
              decoded_response.body_decoded.length &&
              decoded_response.body_decoded[0].select_lists
            ) {
              for (const select_list of decoded_response.body_decoded[0]
                .select_lists) {
                await setItem('select_lists', select_list.code, select_list);
              }
            }

            if (
              decoded_response.body_decoded.length == 0 &&
              target.object_has_select_lists
            ) {
              // async kick off a request for the select_lists for this model type so we have them in cache
              api.get(`select_lists`, {
                model: target.object_select_list_model,
              });
            }

            if (target.object != 'select_lists') {
              // evict members of datastore(target.object) that aren't temps and which didn't appear in this response
              // TODO DEBT: when we have to start pagination, this is not going to work
              // TODO DEBT: ... sorta like we don't run this on select_lists, which sometimes is everything (off of front page) and sometimes is partial (like when we don't get any results for a collection, and target.object_has_select_lists)
              const existing_cache_ids = new Set(
                await getStoreItemIDs(target.object)
              );
              const ids_in_this_load = new Set(
                decoded_response.body_decoded.map(i => to_s(i.id))
              );
              const to_evict = new Set(
                [...existing_cache_ids].filter(id => !ids_in_this_load.has(id))
              );
              for (let id_to_evict of to_evict) {
                if (!is_uncommitted_object(id_to_evict)) {
                  cl('GET evicting unseen', target.object, id_to_evict);
                  await removeItem(target.object, id_to_evict);
                }
              }
            }
          } else {
            unhandled(request, target, 'decoded body not an array');
          }
        } else if (target.individual) {
          // GET /photo_logs/:id
          // store in datastore(target.object) with the key being target.id (maybe throw exception if target.id != the body object's .id

          if (
            target.id &&
            decoded_response.body_decoded &&
            decoded_response.body_decoded.id == target.id
          ) {
            let subobject_specs = subobject_specs_for(target.object);
            await store_individual(
              subobject_specs,
              target.object,
              decoded_response.body_decoded
            );
          } else {
            unhandled(request, target, 'object body id does not match url id');
          }
        } else {
          // DEBT: we don't do subobject or subcollection yet (mostly; we do a special case for projects/:id/recents)
          if (
            target.subcollection &&
            target.object == 'projects' &&
            target.subobject == 'recents'
          ) {
            // these "recents" don't correspond to a rails model, but we're going to pretend they do:  project_recents  with the id of the project
            const object = 'project_recents';
            let subobject_specs = subobject_specs_for(object);
            await store_individual(
              subobject_specs,
              object,
              wrap_array(target.id, decoded_response.body_decoded)
            );
          } else {
            unhandled(request, target);
          }
        }
      } else if (request.method === 'DELETE') {
        if (target.collection || target.subcollection) {
          // rails doesn't do deletes on collections, at least by default, so fail
          unhandled(request, target);
        } else if (target.individual) {
          // DELETE /photo_logs/:id
          await removeItem(target.object, target.id);
        } else if (target.subindividual) {
          // DELETE /photo_logs/:id/images/:id
          await removeItem(target.subobject, target.subid);

          await bookkeeping_delete_superobject_subobjects(target);
        } else {
          unhandled(request, target);
        }
      } else if (request.method === 'POST') {
        if (target.individual || target.subindividual) {
          // we don't POST to individuals (... sort of, we do in the case of like /ccst_reports/1/approve_report)
          let subobject_specs = subobject_specs_for(target.object);
          await store_individual(
            subobject_specs,
            target.object,
            decoded_response.body_decoded
          );
        } else if (target.collection) {
          // POST /photo_logs
          // note: we don't need to do ALL the logic in `store_individual` because by the nature of a post to a collection this is a new object.  But if there are subobjects in here we need to break out (which for /photo_logs there aren't), store_individual handles that
          let subobject_specs = subobject_specs_for(target.object);
          await store_individual(
            subobject_specs,
            target.object,
            decoded_response.body_decoded
          );
        } else if (target.subcollection) {
          // POST /photo_logs/:id/images
          let subobject_specs = subobject_specs_for(target.subobject);
          await store_individual(
            subobject_specs,
            target.subobject,
            decoded_response.body_decoded
          );
          // "superobject" here is "when we save this photo_log_image, we also need to copy it up into the photo_log.images array"
          let superobject_specs = superobject_specs_for(target.subobject);
          await bookkeeping_add_superobject_subobjects(
            superobject_specs,
            target.object,
            target.id,
            decoded_response.body_decoded
          );
        } else {
          unhandled(request, target);
        }
      } else if (request.method === 'PUT') {
        if (target.collection || target.subcollection) {
          unhandled(request, target, 'PUT does not go to collections');
        } else if (target.individual) {
          // PUT /photo_logs/:id
          // server side returns a new copy of the object, store it just like a POST

          // DEBT: PUT to /projects needs to update subobjects on others in the cache.  Doesn't matter right now because projects isn't available on mobile, and therefore isn't an offline thing
          let subobject_specs = subobject_specs_for(target.object);
          await store_individual(
            subobject_specs,
            target.object,
            decoded_response.body_decoded
          );
        } else if (target.subindividual) {
          // PUT /photo_logs/:id/images/:id
          // server side returns a new copy of the object, store it just like a POST
          let subobject_specs = subobject_specs_for(target.subobject);
          await store_individual(
            subobject_specs,
            target.subobject,
            decoded_response.body_decoded
          );
          // "superobject" here is "when we save this photo_log_image, we also need to copy it up into the photo_log.images array"
          let superobject_specs = superobject_specs_for(target.subobject);
          await bookkeeping_update_superobject_subobjects(
            superobject_specs,
            target.object,
            target.id,
            decoded_response.body_decoded
          );
        } else {
          unhandled(request, target);
        }
      }
    } else {
      // shouldn't get here, because we aren't fetch()ing anything that isn't isAPI.  Blow up if we start doing that.
      unhandled(request, target);
    }
  } catch (ex) {
    cl('ex in handle_successful_fetch', ex);
  }
  return decoded_response;
}

async function bookkeeping_delete_superobject_subobjects(target) {
  // if the removed subitem exists as a subobject of the target.object (in one case, images stored within photo_logs), remove them from the cached target.object
  for (let subobject_spec of subobject_specs_for(target.object)) {
    if (subobject_spec.model === target.subobject) {
      const existing_super_individual = await getItem(target.object, target.id);
      if (
        existing_super_individual &&
        existing_super_individual[subobject_spec.local] &&
        Array.isArray(existing_super_individual[subobject_spec.local])
      ) {
        existing_super_individual[
          subobject_spec.local
        ] = existing_super_individual[subobject_spec.local].filter(
          e => e.id != target.subid
        );

        await setItem(target.object, target.id, existing_super_individual);
      }
    }
  }
}

async function bookkeeping_add_superobject_subobjects(
  superobject_spec,
  model,
  id,
  individual
) {
  // if there's a superobject spec for model, store individual in model's

  let key = superobject_spec[model];
  if (key) {
    const existing_superobject = await getItem(model, id);
    if (existing_superobject[key] && Array.isArray(existing_superobject[key])) {
      existing_superobject[key].push(individual);

      await setItem(model, id, existing_superobject);
    }
  }
}

async function bookkeeping_update_superobject_subobjects(
  superobject_spec,
  model,
  id,
  individual
) {
  // if there's a superobject spec for model, store individual in model's

  let key = superobject_spec[model];
  if (key) {
    const existing_superobject = await getItem(model, id);
    if (existing_superobject[key] && Array.isArray(existing_superobject[key])) {
      const idx = existing_superobject[key].findIndex(
        subobject => subobject.id == individual.id
      );
      if (idx >= 0) {
        existing_superobject[key][idx] = individual;
      } else {
        cl(
          'bookkeeping_update_uperobject_subobjects',
          'warning - individual we expected to be in place does not exist, so adding',
          existing_superobject,
          individual
        );
        existing_superobject[key].push(individual);
      }

      await setItem(model, id, existing_superobject);
    }
  }
}

export {
  handle_successful_fetch,
  bookkeeping_add_superobject_subobjects,
  bookkeeping_update_superobject_subobjects,
  bookkeeping_delete_superobject_subobjects,
};
