import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl, clresponse } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for, month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';

import { Tabs, Tab } from 'carbon-components-react';

function PointNav({ id, point_id, show, navigate_to }) {
  return (
    <Tabs
      type="container"
      selected={['point', 'lithology', 'sample', 'rmr', 'hcsi'].findIndex(
        t => t == show
      )}
      onSelectionChange={idx =>
        navigate_to(
          [
            `/boring_logs/${id}/points/${point_id}`,
            `/boring_logs/${id}/points/${point_id}/lithologies`,
            `/boring_logs/${id}/points/${point_id}/samples`,
            `/boring_logs/${id}/points/${point_id}/rmrs`,
            `/boring_logs/${id}/points/${point_id}/hcsis`,
          ][idx]
        )
      }
    >
      <Tab label="Details" />
      <Tab label="Lithology" />
      <Tab label="Sample" />
      <Tab label="RMR" />
      <Tab label="HCSI" />
    </Tabs>
  );
}

export default PointNav;
