import 'regenerator-runtime/runtime';
import { cl } from './debug';

if (navigator.serviceWorker) {
  let r = navigator.serviceWorker.register('/sw.js').catch(ex => {
    cl('service worker registration problem', ex);
  });
  r.then(function(rtn) {
    console.log('service worker registered, returned', rtn);
  });
}

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import '@babel/polyfill';
import api from './api';
import store from './redux/store';
import { login, go_offline, update_storage } from './redux/actions';
import RootRouter from './router';
import { initialize as offline_initialize } from './offline';
import { initialize as heartbeat_initialize } from './offline/heartbeat';

offline_initialize();
heartbeat_initialize();

if (navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener('message', event => {
    console.log('t1', 'receiving message from SW', event.data);
    if (event.data && event.data.msg === 'OFFLINE') {
      store.dispatch(go_offline());
    }
  });
}

let mode = 'admin';

if (window.screen.width < 1058) {
  // we previously restricted some admin stuff to desktop only, this was asked to be removed
  // mode = 'user';
}

ReactDOM.render(
  <Provider store={store}>
    <RootRouter mode={mode} />
  </Provider>,
  document.getElementById('root')
);

// After we attach the application to the dom, check to see if we're authed and if so, log in.
api.get('auth').then(response => {
  if (response.success) {
    store.dispatch(login(response.user));
  }
});

// Initialize our displayed storage quota
update_storage().then(msg => store.dispatch(msg));
