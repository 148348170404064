import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import { full_redux_state } from '../../redux/connectors';
import { to_s } from '../../common';

import ProjectObservationReports from './ProjectObservationReports';
import ObservationReport from '../ObservationReport';

import { Loading, Button } from 'carbon-components-react';

function ObservationReports({ user, offline, history }) {
  const [observation_reports, setObservationReports] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    (async () => {
      const projects = await api.get('projects');
      setProjects(projects);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const observation_reports = (await api.get('observation_reports')).sort(
        (a, b) => {
          // sort by .logged_at desc
          a = Date.parse(a.logged_at);
          b = Date.parse(b.logged_at);
          if (b < a) return -1;
          if (b > a) return 1;
          return 0;
        }
      );
      setObservationReports(observation_reports);
    })();
  }, []);

  const handleProjectClick = id =>
    history.push(`/observation_reports/projects/${id}`);
  const handleObservationReportClick = id =>
    history.push(`/observation_reports/${id}`);

  const handleNewLogClick = async project_id => {
    const observation_report = api.user_audit_at_helper(
      { project_id },
      'observation_report'
    );
    const resp = await api.post('observation_reports', null, {
      observation_report,
    });
    if (resp && resp.id) {
      setObservationReports([resp].concat(observation_reports));
      history.push(`/observation_reports/${resp.id}`);
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  };

  const handleUpdateObservationReport = new_observation_report => {
    if (new_observation_report && observation_reports)
      setObservationReports(
        observation_reports.map(observation_report =>
          observation_report.id == new_observation_report.id
            ? new_observation_report
            : observation_report
        )
      );
  };

  return (
    <Switch>
      <Route
        path="/observation_reports/projects/:id"
        render={({ match }) => {
          const project = (projects || []).find(
            p => to_s(p.id) === match.params.id
          );
          return (
            <ProjectObservationReports
              id={match.params.id}
              project={project}
              observation_reports={observation_reports}
              onDetailClick={handleObservationReportClick}
              onNewLogClick={handleNewLogClick}
              onUpdateObservationReport={handleUpdateObservationReport}
            />
          );
        }}
      />
      <Route
        path="/observation_reports/:id"
        render={({ match }) => (
          <ObservationReport
            id={match.params.id}
            observation_reports={observation_reports}
            onUpdateObservationReport={handleUpdateObservationReport}
            history={history}
          />
        )}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export default connect(full_redux_state)(ObservationReports);
