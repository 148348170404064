import moment from 'moment';

function month_day_year_for(dt) {
  dt = moment(dt);
  return dt.format('M/D/YYYY');
}

function year_month_day_for(dt) {
  dt = moment(dt);
  return dt.format('YYYY/M/D');
}

function from_month_day_year(s) {
  return moment(s, 'M/D/YYYY').format();
}

function full_month_day_year_for(dt) {
  dt = moment(dt);
  return dt.format('MMMM D, YYYY');
}

export {
  month_day_year_for,
  from_month_day_year,
  full_month_day_year_for,
  year_month_day_for,
};
