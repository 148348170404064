import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s, date_to_s, date_formats } from '../../common';
import { cl } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';
import { useDidMount, scrollToFragmentOrTopOnMount } from '../shared/on_mount';

import {
  Loading,
  InlineNotification,
  Button,
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  TimePicker,
  Select,
  SelectItem,
  SelectItemGroup,
  Form,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
} from 'carbon-components-react';
import { ArrowLeft16, Add16, ChevronRight16 } from '@carbon/icons-react';
import TdSelect from '../shared/Select';
import Breadcrumbs from '../shared/Breadcrumbs';
import RichTextArea from '../shared/RichTextArea';

function CCSTReportTestReport({
  id,
  test_report_id,
  user,
  offline,
  ccst_reports,
  onUpdateCCSTReport,
  history,
}) {
  let initial_ccst_report = null;
  if (Array.isArray(ccst_reports)) {
    initial_ccst_report = ccst_reports.find(pl => to_s(pl.id) == id);
  }
  let initial_ccst_report_test_report = null;
  if (initial_ccst_report && Array.isArray(initial_ccst_report.test_reports)) {
    initial_ccst_report_test_report =
      initial_ccst_report.test_reports[test_report_id];
  }

  const form_keys = [
    'specified_strength',
    'set_number',
    'ticket_number',
    'truck_number',
    'time_left_plant',
    'truck_yardage',
    'material_temperature',
    'slump',
    'air_content',
    'water_added',
    'add_mix_added',
    'samples_cast_by',
    'sample_type',
    'sample_size',
    'remarks',
    'broke_by',
    'results',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for CCST

  const [ccst_report, setCCSTReport] = useState(initial_ccst_report);
  const editable = true; // was !ccst_report.approved
  const [ccst_report_test_report, setCCSTReportTestReport] = useState(
    initial_ccst_report_test_report
  );
  const didMount = useDidMount();
  useEffect(() => scrollToFragmentOrTopOnMount(didMount), [
    ccst_report_test_report,
    didMount,
  ]);

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    ...(initial_ccst_report_test_report ? initial_ccst_report_test_report : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = updatedValues =>
    _setFormState(prevState => {
      return { ...form_state, ...updatedValues };
    });

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: setFormState,
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };
  const fvfb = id => {
    return { ...vfb(id), type: 'number', step: '0.01' };
  };

  const save_wip = (newdata = {}) => {
    // takes new local data, returns full local state
    // but needs to call upstream onUpdate with full CCST

    const wip = {
      ...ccst_report_test_report,
      ..._.pick(form_state, _.keys(form_keys)),
      ...newdata,
    };

    const wip_test_reports = [...ccst_report.test_reports];
    wip_test_reports[test_report_id] = wip;
    const wip_ccst_report = {
      ...ccst_report,
      test_reports: wip_test_reports,
    };
    if (onUpdateCCSTReport) onUpdateCCSTReport(wip_ccst_report);
    return wip;
  };

  const new_result_with_prefill = () => {
    const new_result = { ...ccst_report.new_result };
    if (form_state.results.length) {
      new_result.laboratory_number = form_state.results[0].laboratory_number;
    }
    return new_result;
  };

  // END form handling boilerplate and custom

  const reloadData = async () => {
    // TODO: lots of floating this up with handleUpdateCCSTReport.... should put this in redux
    const ccst_report = await api.get(`ccst_reports/${id}`);
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    setCCSTReport(ccst_report);
    setCCSTReportTestReport(ccst_report.test_reports[test_report_id]);
    setFormState(ccst_report.test_reports[test_report_id]);
  };
  useEffect(() => {
    if (!ccst_report) reloadData();
  }, []);

  const project_name =
    ccst_report && ccst_report.project ? ccst_report.project.name : '';
  const code =
    ccst_report && ccst_report.project ? ccst_report.project.code : '';
  const when =
    ccst_report && ccst_report.date_made
      ? full_month_day_year_for(ccst_report.date_made || ccst_report.logged_at)
      : '';
  const to = ccst_report ? `/ccst_reports/${ccst_report.id}` : '';

  const handle_save = () => {
    // load our form_state into our ccst_report
    // then percolate that up (onUpdateCCSTReport(ccst_report))
    ccst_report.test_reports[test_report_id] = _.pick(
      form_state,
      _.keys(form_keys)
    );
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    history.push(`/ccst_reports/${id}#test_reports`);
  };

  const handle_delete = () => {
    ccst_report.test_reports.splice(test_report_id, 1);
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    history.push(`/ccst_reports/${id}#test_reports`);
  };

  const handle_add_result = () => {
    const wip = save_wip({
      results: form_state.results.concat([new_result_with_prefill()]),
    });
    setFormState(wip);
    handleCCSTReportResultClick(wip.results.length - 1);
  };

  const handleCCSTReportResultClick = result_id =>
    history.push(
      `/ccst_reports/${id}/test_reports/${test_report_id}/results/${result_id}`
    );

  let content = '';
  if (!ccst_report_test_report) {
    content = <Loading small={false} active={true} />;
  } else if (!ccst_report.id && offline) {
    content = (
      <p>
        You are offline, and we do not have this Concrete Report cached, sorry.
      </p>
    );
  } else if (!ccst_report.id && !offline) {
    content = (
      <p>
        You do not have access to that Concrete Report, or it does not exist.
      </p>
    );
  } else {
    content = (
      <>
        <ToolHeadline
          title={`${!editable ? 'Viewing' : 'Editing'} Test Report`}
        />

        <TextInput
          {...vfb('specified_strength')}
          labelText="Specified Strength"
        />

        <div className="bx--row">
          <div className="bx--col">
            <TextInput {...vfb('set_number')} labelText="Set Number" />
          </div>
          <div className="bx--col">
            <TextInput {...vfb('ticket_number')} labelText="Ticket Number" />
          </div>
        </div>
        <div className="bx--row">
          <div className="bx--col">
            <TextInput {...vfb('truck_number')} labelText="Truck Number" />
          </div>
          <div className="bx--col">
            <TextInput {...fvfb('truck_yardage')} labelText="Truck Yardage" />
          </div>
        </div>
        <TextInput {...vfb('time_left_plant')} labelText="Time Left Plant" />
        <TextInput
          {...nvfb('material_temperature')}
          labelText="Material Temperature"
        />
        <TextInput {...vfb('slump')} labelText="Slump" />
        <TextInput {...vfb('air_content')} labelText="Air Content" />
        <TextInput {...fvfb('water_added')} labelText="Water Added" />
        <TextInput {...fvfb('add_mix_added')} labelText="Add-Mix. Added" />
        <TextInput {...vfb('samples_cast_by')} labelText="Samples Cast By" />
        <hr className="bx--form-item" />

        <TdSelect
          {...vfb('sample_type')}
          labelText="Sample Type"
          select_list={ccst_report.select_lists.find(
            i => i.code === 'ccst-sample-type'
          )}
        />

        <TdSelect
          {...vfb('sample_size')}
          labelText="Sample Size"
          select_list={ccst_report.select_lists.find(
            i => i.code === 'ccst-sample-size'
          )}
        />

        <h2 id="results">Results</h2>
        <StructuredListWrapper selection>
          <StructuredListHead>
            <StructuredListRow head>
              <StructuredListCell head>Age</StructuredListCell>
              <StructuredListCell head>Date</StructuredListCell>
              <StructuredListCell head />
            </StructuredListRow>
          </StructuredListHead>

          <StructuredListBody>
            {ccst_report_test_report.results.map((result, result_id) => (
              <StructuredListRow
                key={result_id}
                onClick={() => {
                  save_wip();
                  handleCCSTReportResultClick(result_id);
                }}
              >
                <StructuredListCell>
                  {result.age_tested || 'No Age Set'}
                </StructuredListCell>
                <StructuredListCell>
                  {result.date_tested || 'No Date Tested Set'}
                </StructuredListCell>
                <StructuredListCell>
                  <ChevronRight16 />
                </StructuredListCell>
              </StructuredListRow>
            ))}
          </StructuredListBody>
        </StructuredListWrapper>

        {editable && (
          <div className="terradon--align-right">
            <Button
              className="terradon--form-item"
              renderIcon={Add16}
              onClick={handle_add_result}
            >
              Add Result
            </Button>
          </div>
        )}

        <RichTextArea {...vfb('remarks')} labelText="Remarks" />
        <TextInput {...vfb('broke_by')} labelText="Broke By" />

        {editable && (
          <>
            <Button kind="ghost" onClick={handle_delete}>
              Delete Test Report
            </Button>
            <Button className="update-test-report" onClick={handle_save}>
              Update Test Report
            </Button>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="ccst_reports"
          obj={ccst_report}
          second="Concrete Reports"
          third="Concrete Test Report"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(CCSTReportTestReport);
