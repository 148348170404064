import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import moment from 'moment';
import { full_redux_state } from '../../redux/connectors';

import {
  Button,
  TextArea,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent,
} from 'carbon-components-react';

import { Checkmark16 } from '@carbon/icons-react';
import { ExpandableTile } from '../carbon/ExpandableTile';

class PhotoLogImage extends Component {
  form_keys = {
    description: '',
  };

  form_labels = {
    description: 'Change the description below.',
  };

  form_handlers = {};

  constructor(props) {
    super(props);

    // the form fields
    this.state = _.clone(this.form_keys);

    // non-visible Project properties and other internal-use state properites (if any)
    this.state.id = null; // if id is present, we're an Update form, not a Create

    this.state.expanded = false; // we control the expanded state so we can close when an update has been made

    // if we're passed defaults, load them
    if (this.props.photo_log_image) {
      _.extend(this.state, this.props.photo_log_image);
    }

    this.state.description = this.state.description || ''; // TODO: when we simplify all this crap, work this in there somewhere

    // mark all fields as valid, for now
    this.state.invalids = _.mapObject(this.form_keys, (k, v) => {
      return false;
    });
  }

  // return true if our stored state is valid for submitting to the API, false if not (and update state.invalids if it isn't)
  valid = () => {
    const { state } = this;

    // when we validate, everything starts as assumed valid
    let invalids = _.mapObject(this.form_keys, (k, v) => false);

    // TODO: encode validation rules into some sort of code so we can DRY this up, and put the validation message in the form_keys struct above

    // go through our validation rules, mark things invalid that don't match the rules
    if (!state.description)
      invalids['description'] = 'Must provide a description';

    // if we didn't pass validation, update the state and return false
    let invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (invalid) {
      this.setState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  handleStandardInput = e => {
    let invalids = this.state.invalids;
    invalids[e.target.name] = false;
    this.setState({ [e.target.name]: e.target.value, invalids });
  };

  // helper: these are the standard props we pass to most input components
  field_boilerplate = id => {
    return {
      id: this.id_for(id),
      name: id,
      onChange: this.form_handlers[id]
        ? this.form_handlers[id]
        : this.handleStandardInput,
      ...this.invalid_for(id),
      ...(this.form_labels[id] ? { labelText: this.form_labels[id] } : {}),
    };
  };

  text_field_boilerplate = id => {
    return {
      ...this.field_boilerplate(id),
      value: this.state[id],
    };
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (this.valid()) {
      try {
        const { photo_log, photo_log_image } = this.props;
        let photo_log_image_to_put = _.pick(this.state, _.keys(this.form_keys));
        let endpoint = `photo_logs/${photo_log.id}/images/${
          photo_log_image.id
        }.json`;

        photo_log_image_to_put = api.user_audit_at_helper(
          photo_log_image_to_put,
          'photo_log_image'
        );

        const resp = await api.put(endpoint, null, {
          photo_log_image: photo_log_image_to_put,
        });

        if (this.is_ok_response(resp)) {
          const { handleSavePhotoLogImage } = this.props;
          this.setState({ expanded: false }); // close expanded tile on save
          handleSavePhotoLogImage && handleSavePhotoLogImage(resp);
        } else {
          alert(this.create_response_error_to_string(resp));
        }
      } catch (err) {
        alert('Sorry, there was an unexpected error.');
        console.log(err);
      }
    }
  };

  // helper: so we can namespace the IDs if necessary
  id_for = id => {
    return id;
  };

  // helper: is the API response body for the action an OK?
  is_ok_response = response => {
    return response && response.id;
  };

  // helper: unpack our validity data structure to map to what the carbon components are expecting
  invalid_for = id => {
    if (!!this.state.invalids[id]) {
      return {
        invalid: !!this.state.invalids[id],
        invalidText: this.state.invalids[id],
      };
    } else {
      return {};
    }
  };

  // helper: convert the create response body to an error string
  create_response_error_to_string = response => {
    return _.map(_.keys(response), key => `${key} ${response[key]}`).join('\n');
  };

  handleImageLoaded = () => this.setState({ imageLoaded: true });

  render() {
    const { photo_log_image: image, removeImage } = this.props;

    const { imageLoaded, expanded } = this.state;

    const text_field_props = {
      ...this.text_field_boilerplate('description'),
      labelText: `${this.form_labels['description']} ${
        (this.state.description || '').length
      } / 100`,
    };

    return (
      <ExpandableTile
        key={image.id}
        terradonSimplifyClose={true}
        expanded={expanded}
        handleClick={e => this.setState({ expanded: true })}
        terradonContentStillLoading={!imageLoaded}
        className="photo_log_image"
        tileExpandedLabel="Edit Log Entry"
        tileCollapsedLabel="Edit Log Entry"
      >
        <TileAboveTheFoldContent>
          <img src={api.fixup_url(image.url)} onLoad={this.handleImageLoaded} />
          <p>{image.description || 'No Description Set'}</p>
        </TileAboveTheFoldContent>
        <TileBelowTheFoldContent>
          <TextArea {...text_field_props} />
          <div className="buttons">
            <Button onClick={removeImage} kind="ghost">
              Delete Entry
            </Button>
            <Button onClick={this.handleSubmit} renderIcon={Checkmark16}>
              Update Entry
            </Button>
          </div>
        </TileBelowTheFoldContent>
      </ExpandableTile>
    );
  }
}

export default connect(full_redux_state)(PhotoLogImage);
