import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s } from '../../common';
import { cl, clresponse } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for, month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import { mark_unsaved_change } from '../../redux/actions';
import * as fh from '../../form_helpers';
import PointNav from './Nav';
import TdSelect from '../shared/Select';
import ToolHeadline from '../shared/ToolHeadline';
import Banner from './Banner';
import { point_name_for } from './shared';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
  Loading,
  TextInput,
  MultiSelect,
} from 'carbon-components-react';

import {
  ChevronRight16,
  Camera16,
  ArrowLeft16,
  Add16,
} from '@carbon/icons-react';

// define this fn outside the component so it doesn't get redefined every render, which makes the debouncing useless (a newly debounced function gets created each time)
const handle_draft_submit = _.debounce(
  (e, boring_log, handle_submit, newstate, dispatch) => {
    if (boring_log && boring_log.state == 'draft') {
      return handle_submit(e, newstate);
    } else {
      dispatch(mark_unsaved_change());
      return false;
    }
  },
  1000
);
const dummy_event = { preventDefault: () => false };

function Lithologies({
  id,
  point_id,
  boring_log,
  boring_log_point,
  history,
  offline,
  onUpdateBoringLogPoint,
  dispatch,
}) {
  let boring_log_point_lithologies = null;
  if (boring_log_point && Array.isArray(boring_log_point.lithologies)) {
    boring_log_point_lithologies = boring_log_point.lithologies;
  }

  // we implement this for when we're in draft mode and submitting on each change, and we're debouncing
  const handle_submit = async (e, state) => {
    return await fh.handle_submit({
      e,
      endpoint: `boring_logs/${boring_log.id}/points`,
      model: 'boring_log_point',
      state: state,
      fields: ['lithologies'].reduce((a, v) => ({ ...a, [v]: '' }), {}),
      valid: () => true,
      onCreate: null,
      onUpdate: onUpdateBoringLogPoint,
      clear_form: () => false, // noop for this form
    });
  };

  const handleNewLithologyClick = e => {
    boring_log_point.lithologies.push(boring_log_point.new_lithology);

    handle_draft_submit(
      e,
      boring_log,
      handle_submit,
      boring_log_point,
      dispatch
    );

    if (onUpdateBoringLogPoint) onUpdateBoringLogPoint(boring_log_point);
    history.push(
      `/boring_logs/${id}/points/${point_id}/lithologies/${boring_log_point
        .lithologies.length - 1}`
    );
  };

  const no_logs_message = offline
    ? `Sorry, you're offline and we don't have any Boring Log Points cached for this project.  You may add New Lithologies.`
    : `No lithologies for this Boring Log Point`;

  let content = <Loading small={false} active={true} />;
  if (!boring_log_point) {
    content = <Loading small={false} active={true} />;
  } else if (!boring_log_point && !offline) {
    content = (
      <p>You do not have access to that Boring Log, or it does not exist.</p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title={point_name_for(boring_log_point)}>
          <Button renderIcon={Add16} onClick={e => handleNewLithologyClick(e)}>
            New Lithology
          </Button>
        </ToolHeadline>
        <StructuredListWrapper selection>
          <StructuredListHead>
            <StructuredListRow head>
              <StructuredListCell head>Depth</StructuredListCell>
              <StructuredListCell className="mobile-hide" head>
                Bottom
              </StructuredListCell>
              <StructuredListCell head />
            </StructuredListRow>
          </StructuredListHead>

          <StructuredListBody>
            {boring_log_point_lithologies.length > 0 &&
              boring_log_point_lithologies.map((lithology, lithology_id) => (
                <StructuredListRow
                  key={lithology_id}
                  onClick={() => {
                    history.push(
                      `/boring_logs/${id}/points/${point_id}/lithologies/${lithology_id}`
                    );
                  }}
                >
                  <StructuredListCell>{lithology.depth}</StructuredListCell>
                  <StructuredListCell className="mobile-hide">
                    {lithology.bottom}
                  </StructuredListCell>
                  <StructuredListCell>
                    <ChevronRight16 />
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            {boring_log_point_lithologies.length === 0 && (
              <StructuredListRow>
                <StructuredListCell>{no_logs_message}</StructuredListCell>
              </StructuredListRow>
            )}
          </StructuredListBody>
        </StructuredListWrapper>{' '}
      </>
    );
  }

  const to = boring_log && boring_log.project ? `/boring_logs/${id}` : '';
  return (
    <>
      <Banner to={to} back_to="Boreholes" boring_log={boring_log} />
      <PointNav
        id={id}
        point_id={point_id}
        show="lithology"
        navigate_to={history.push}
      />
      {content}
    </>
  );
}

function Lithology({
  id,
  point_id,
  lithology_id,
  boring_log,
  boring_log_point: initial_boring_log_point,
  onUpdateBoringLogPoint,
  history,
  offline,
  dispatch,
}) {
  let initial_lithology = null;
  if (initial_boring_log_point) {
    initial_lithology = initial_boring_log_point.lithologies[lithology_id];
  }

  const form_keys = [
    'depth',
    'bottom',
    'graphic',
    'color',
    'material_description',
    'moisture',
    'consistency',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for Lithologies

  const [boring_log_point, setBoringLogPoint] = useState(
    initial_boring_log_point
  );

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    ...(initial_lithology ? initial_lithology : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = (updatedValues, event) =>
    _setFormState(prevState => {
      const newstate = { ...form_state, ...updatedValues };
      if (event) {
        // handle_draft_submit needs newstate include the full point, not this form's state
        boring_log_point.lithologies[lithology_id] = _.pick(
          newstate,
          _.keys(form_keys)
        );
        handle_draft_submit(
          event,
          boring_log,
          handle_submit,
          boring_log_point,
          dispatch
        );
      }
      return newstate;
    });

  if (!boring_log_point && initial_boring_log_point) {
    // lazy loading from parent has arrived, load up form state
    setBoringLogPoint(initial_boring_log_point);
    setFormState(initial_boring_log_point.lithologies[lithology_id]);
  }

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  // we implement this for when we're in draft mode and submitting on each change, and we're debouncing
  const handle_submit = async (e, state) => {
    return await fh.handle_submit({
      e,
      endpoint: `boring_logs/${boring_log.id}/points`,
      model: 'boring_log_point',
      state: state,
      fields: ['lithologies'].reduce((a, v) => ({ ...a, [v]: '' }), {}),
      valid: () => true,
      onCreate: null,
      onUpdate: onUpdateBoringLogPoint,
      clear_form: () => false, // noop for this form
    });
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: s => setFormState(s, event),
        }),
    });
  const nvfb = id => {
    return { ...vfb(id), type: 'number' };
  };

  // END form handling boilerplate and custom

  const handle_navigate_away = to => {
    // load our form_state into our boring_log_point
    // then percolate that up (onUpdateBoringLogPoint(boring_log_point))
    boring_log_point.lithologies[lithology_id] = _.pick(
      form_state,
      _.keys(form_keys)
    );
    if (onUpdateBoringLogPoint) onUpdateBoringLogPoint(boring_log_point);
    history.push(to);
  };

  const handle_delete = () => {
    boring_log_point.lithologies.splice(lithology_id, 1);
    handle_draft_submit(
      dummy_event,
      boring_log,
      handle_submit,
      boring_log_point,
      dispatch
    );
    if (onUpdateBoringLogPoint) onUpdateBoringLogPoint(boring_log_point);
    history.push(`/boring_logs/${id}/points/${point_id}/lithologies`);
  };

  let content = '';
  if (!boring_log_point) {
    content = <Loading small={false} active={true} />;
  } else if (!boring_log_point.id && offline) {
    content = (
      <p>You are offline, and we do not have this Boring Log cached, sorry.</p>
    );
  } else if (!boring_log_point.id && !offline) {
    content = (
      <p>You do not have access to that Boring Log, or it does not exist.</p>
    );
  } else {
    content = (
      <>
        <ToolHeadline title="Editing Lithology" />

        <TextInput {...nvfb('depth')} step="0.01" labelText="Depth (ft)" />
        <TextInput {...nvfb('bottom')} step="0.01" labelText="Bottom (ft)" />
        <TdSelect
          {...vfb('graphic')}
          labelText="Sample Material"
          select_list={boring_log.select_lists.find(
            i => i.code === 'boring-log-graphic'
          )}
        />
        <TdSelect
          {...vfb('color')}
          labelText="Color"
          select_list={boring_log.select_lists.find(
            i => i.code === 'boring-log-color'
          )}
        />
        <p>
          Material Description:{' '}
          {form_state.material_description ||
            'None.  Tap to select and/or add manually below'}
        </p>
        <TdMultiSelectWithManual
          {...vfb('material_description')}
          label="Material Description"
          labelText="Material Description"
          select_list={boring_log.select_lists.find(
            i => i.code === 'boring-log-material-description'
          )}
          form_state={form_state}
          setFormState={setFormState}
        />
        <TdSelect
          {...vfb('moisture')}
          labelText="Moisture (ft)"
          select_list={boring_log.select_lists.find(
            i => i.code === 'boring-log-moisture'
          )}
        />
        <TdSelect
          {...vfb('consistency')}
          labelText="Hardness"
          select_list={boring_log.select_lists.find(
            i => i.code === 'boring-log-consistency'
          )}
        />

        <Button kind="ghost" onClick={handle_delete}>
          Delete Lithology
        </Button>
        <Button
          onClick={() =>
            handle_navigate_away(
              `/boring_logs/${id}/points/${point_id}/lithologies`
            )
          }
        >
          Update Lithology
        </Button>
      </>
    );
  }

  const to = boring_log ? `/boring_logs/${id}` : '';
  return (
    <>
      <Banner to={to} back_to="Boreholes" boring_log={boring_log} />
      <PointNav
        id={id}
        point_id={point_id}
        show="lithology"
        navigate_to={handle_navigate_away}
      />
      {content}
    </>
  );
}

function TdMultiSelectWithManual({
  select_list,
  form_state,
  setFormState,
  ...props
}) {
  // LOGIC
  // we store in props.name, a string field, in the database.
  // what's stored in the DB is a concatenation of selected items, joined by ', ', plus whatever they add in the "manual" area
  // to render, we decompose that string into items seen in the dropdown, and the remainder go in the "manual" area
  // onChange, when we write to the state, we produce the concatenated string by joining what is selected and adding in the "manual" area

  // we can also switch from a multiselect to a series of tags https://react.carbondesignsystem.com/?path=/story/components-tag--filter

  const multiselect_initial_selected_items = [];
  const manual_text_items = [];
  const value_tokens = (props.value || '').split(', ');
  if (select_list) {
    value_tokens.forEach(token => {
      const standard_item = (select_list.options || []).find(
        i => Object.keys(i)[0] == token
      );
      if (standard_item) {
        multiselect_initial_selected_items.push(standard_item);
      } else {
        manual_text_items.push(token);
      }
    });
  }
  const [multiselect_cache, setMultiselectCache] = useState(
    multiselect_initial_selected_items.map(i => Object.keys(i)[0])
  );

  return (
    <>
      <MultiSelect
        {...props}
        labelText={props.labelText + ' (tap to select)'}
        label={props.label + ' (tap to select)'}
        items={select_list ? select_list.options : []}
        itemToString={i => Object.keys(i)[0]}
        initialSelectedItems={multiselect_initial_selected_items}
        onChange={e => {
          const these_selected_items = e.selectedItems.map(
            i => Object.keys(i)[0]
          );
          const store_to_form_state = these_selected_items
            .concat(manual_text_items)
            .filter(e => !!e)
            .join(', ');
          fh.handle_nonstandard_input({
            field: props.name,
            value: store_to_form_state,
            state: form_state,
            setState: s => setFormState(s, dummy_event),
          });
          // cache what's been selected so the textinput can correctly update form_state when it's changed
          setMultiselectCache(these_selected_items);
        }}
      />
      <TextInput
        id={props.id + '-manual'}
        value={manual_text_items.join(', ')}
        onChange={e => {
          // we have to prepend that which comes from the multiselect
          fh.handle_nonstandard_input({
            field: props.name,
            value: multiselect_cache
              ? multiselect_cache
                  .concat([e.target.value])
                  .filter(e => !!e)
                  .join(', ')
              : e.target.value,
            state: form_state,
            setState: s => setFormState(s, e),
          });
        }}
        labelText={`${props.labelText} (manual entry)`}
      />
    </>
  );
}

const lithologies = connect(full_redux_state)(Lithologies);
const lithology = connect(full_redux_state)(Lithology);

export { lithologies as Lithologies, lithology as Lithology };
