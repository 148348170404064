import React, { useState } from 'react';
import { connect } from 'react-redux';
import { full_redux_state } from '../../redux/connectors';
import { Link } from 'react-router-dom';
import { month_day_year_for } from '../../datetime';
import { to_s } from '../../common';
import api from '../../api';
import { cl } from '../../debug';
import ToolHeadline from '../shared/ToolHeadline';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
  Loading,
} from 'carbon-components-react';

import {
  ChevronRight16,
  Camera16,
  ArrowLeft16,
  Add16,
} from '@carbon/icons-react';
import Breadcrumbs from '../shared/Breadcrumbs';

//  /boring_logs/projects/:id -> ./ProjectBoringLogs
function ProjectBoringLogs({
  id /* :id in the matching url */,
  project,
  boring_logs,
  onDetailClick,
  onNewLogClick,
  offline,
  user,
}) {
  if (!Array.isArray(boring_logs))
    return <Loading small={false} active={true} />;

  project = project || {};
  boring_logs = boring_logs || [];
  boring_logs = boring_logs.filter(
    pl =>
      (pl.project && to_s(pl.project.id) === id) || to_s(pl.project_id) === id
  );

  const no_logs_message = offline
    ? `Sorry, you're offline and we don't have any Boring Logs cached for this project`
    : `No Boring Logs created for this project`;

  const banner = (
    <div className="tool-banner">
      <Breadcrumbs project_id={project.id} />
      <p className="project-code">{project.code}</p>
      <p className="name">{project.name}</p>
    </div>
  );

  return (
    <>
      {banner}
      <ToolHeadline title="Boring Logs">
        <Button renderIcon={Add16} onClick={() => onNewLogClick(id)}>
          New Boring Log
        </Button>
      </ToolHeadline>
      <StructuredListWrapper selection>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Date</StructuredListCell>
            <StructuredListCell className="mobile-hide" head>
              Boring Log
            </StructuredListCell>
            <StructuredListCell head />
          </StructuredListRow>
        </StructuredListHead>

        <StructuredListBody>
          {boring_logs.length > 0 &&
            boring_logs.map(boring_log => (
              <StructuredListRow
                key={boring_log.id}
                onClick={() => onDetailClick(boring_log.id)}
              >
                <StructuredListCell>
                  {month_day_year_for(boring_log.created_at)}
                  {boring_log.state == 'draft' && (
                    <>
                      <br />
                      <p className="draft">draft</p>
                    </>
                  )}
                </StructuredListCell>
                <StructuredListCell className="mobile-hide">
                  {boring_log.state == 'published' && (
                    <a
                      className="bx--btn bx--btn--primary"
                      href={`/api/v1/boring_logs/${boring_log.id}/gint.xlsx`}
                      onClick={e => e.stopPropagation()}
                    >
                      Export to gINT
                    </a>
                  )}
                </StructuredListCell>
                <StructuredListCell>
                  <ChevronRight16 />
                </StructuredListCell>
              </StructuredListRow>
            ))}
          {boring_logs.length === 0 && (
            <StructuredListRow>
              <StructuredListCell>{no_logs_message}</StructuredListCell>
            </StructuredListRow>
          )}
        </StructuredListBody>
      </StructuredListWrapper>
    </>
  );
}

export default connect(full_redux_state)(ProjectBoringLogs);
