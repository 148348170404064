import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s, date_to_s, date_formats } from '../../common';
import { cl } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';
import { useDidMount, scrollToTopOnMount } from '../shared/on_mount';

import {
  Loading,
  InlineNotification,
  Button,
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  TimePicker,
  Select,
  SelectItem,
  SelectItemGroup,
  Form,
} from 'carbon-components-react';
import { ArrowLeft16, Add16 } from '@carbon/icons-react';
import TdSelect from '../shared/Select';
import Breadcrumbs from '../shared/Breadcrumbs';

function IncidentReportStatement({
  id,
  statement_id,
  user,
  offline,
  incident_reports,
  onUpdateIncidentReport,
  history,
}) {
  let initial_incident_report = null;
  if (Array.isArray(incident_reports)) {
    initial_incident_report = incident_reports.find(pl => to_s(pl.id) == id);
  }
  let initial_incident_report_statement = null;
  if (
    initial_incident_report &&
    Array.isArray(initial_incident_report.statements)
  ) {
    initial_incident_report_statement =
      initial_incident_report.statements[statement_id];
  }

  const form_keys = [
    'employee_name',
    'employee_gender',
    'job_title',
    'where',
    'see',
    'hear',
    'conscious',
    'say',
    'say_what',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for Incident

  const [incident_report, setIncidentReport] = useState(
    initial_incident_report
  );
  const editable = true; // was !incident_report.approved
  const [incident_report_statement, setIncidentReportStatement] = useState(
    initial_incident_report_statement
  );
  const didMount = useDidMount();
  useEffect(() => scrollToTopOnMount(didMount), [
    incident_report_statement,
    didMount,
  ]);

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    ...(initial_incident_report_statement
      ? initial_incident_report_statement
      : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = updatedValues =>
    _setFormState(prevState => {
      return { ...form_state, ...updatedValues };
    });

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    invalids = fh.validate(
      invalids,
      'employee_name',
      form_state.employee_name,
      'Must provide an employee_name'
    );
    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: setFormState,
        }),
    });

  // END form handling boilerplate and custom

  const reloadData = async () => {
    // TODO: lots of floating this up with handleUpdateIncidentReport.... should put this in redux
    const incident_report = await api.get(`incident_reports/${id}`);
    if (onUpdateIncidentReport) onUpdateIncidentReport(incident_report);
    setIncidentReport(incident_report);
    setIncidentReportStatement(incident_report.statements[statement_id]);
    setFormState(incident_report.statements[statement_id]);
  };
  useEffect(() => {
    if (!incident_report) reloadData();
  }, []);

  const project_name =
    incident_report && incident_report.project
      ? incident_report.project.name
      : '';
  const code =
    incident_report && incident_report.project
      ? incident_report.project.code
      : '';
  const when =
    incident_report && incident_report.date_made
      ? incident_report.date_made
      : '';
  const to = incident_report ? `/incident_reports/${incident_report.id}` : '';

  const handle_save = () => {
    // load our form_state into our incident_report
    // then percolate that up (onUpdateIncidentReport(incident_report))
    incident_report.statements[statement_id] = _.pick(
      form_state,
      _.keys(form_keys)
    );
    if (onUpdateIncidentReport) onUpdateIncidentReport(incident_report);
    history.push(`/incident_reports/${id}#statements`);
  };

  const handle_delete = () => {
    incident_report.statements.splice(statement_id, 1);
    if (onUpdateIncidentReport) onUpdateIncidentReport(incident_report);
    history.push(`/incident_reports/${id}#statements`);
  };

  let content = '';
  if (!incident_report) {
    content = <Loading small={false} active={true} />;
  } else if (!incident_report.id && offline) {
    content = (
      <p>
        You are offline, and we do not have this Incident Report cached, sorry.
      </p>
    );
  } else if (!incident_report.id && !offline) {
    content = (
      <p>
        You do not have access to that Incident Report, or it does not exist.
      </p>
    );
  } else {
    content = (
      <>
        <ToolHeadline
          title={`${!editable ? 'Viewing' : 'Editing'} Statement`}
        />
        <TextInput {...vfb('employee_name')} labelText="Employee Name" />
        <TextInput {...vfb('employee_gender')} labelText="Employee Gender" />
        <TextInput {...vfb('job_title')} labelText="Job Title" />
        <TextInput
          {...vfb('where')}
          labelText="Where was the employee in relation to the incident?"
        />
        <TextInput {...vfb('see')} labelText="What did he/she see?" />
        <TextInput {...vfb('hear')} labelText="What did he/she hear?" />
        <TextInput
          {...vfb('conscious')}
          labelText="If there was an injury, was the injured employee conscious"
        />
        <TdSelect
          {...vfb('say')}
          labelText="Did the injured employee say anything to the witness?"
          select_list={incident_report.select_lists.find(
            i => i.code === 'incident-yesno'
          )}
        />
        {fh.is_true(form_state['say']) && (
          <TextInput
            {...vfb('say_what')}
            labelText="If so, what did they say?"
          />
        )}
        {editable && (
          <>
            <Button kind="ghost" onClick={handle_delete}>
              Delete Statement
            </Button>
            <Button onClick={handle_save}>Update Statement</Button>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="incident_reports"
          obj={incident_report}
          second="Incident Reports"
          third="Incident Report"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(IncidentReportStatement);
