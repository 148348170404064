import { getItem, getStoreItemIDs } from '../localforage';
import { cl } from '../debug';
import { is_uncommitted_object } from './temp_ids';
import store from '../redux/store';
import { body_to_object } from './handler_common';

// if we ever have models where singularization of them is not as simple as dropping the trailing s, add them here
const nonstandard_model_pluralizations = {
  'pluralized-model-name': 'what-it-would-be-singularized',
};

const object_decode_key = {
  photo_logs: {
    real_name: 'photo_logs',
    images: { real_name: 'photo_log_images' },
  },
  boring_logs: {
    real_name: 'boring_logs',
    points: { real_name: 'boring_log_points' },
  },
  incident_reports: {
    real_name: 'incident_reports',
    images: { real_name: 'incident_report_images' },
  },
};

function object_name_to_rails_model_name(primary, secondary) {
  let decoded = null;
  if (!primary) return '';
  if (primary && !secondary) {
    decoded = object_decode_key[primary];
    if (decoded) {
      return decoded.real_name || primary;
    } else {
      return primary;
    }
  }
  if (primary && secondary) {
    decoded = object_decode_key[primary];
    if (decoded) {
      if (decoded[secondary]) {
        return decoded[secondary].real_name || secondary;
      }
    }
    return secondary;
  }
}

function singularize(model_name) {
  if (nonstandard_model_pluralizations[model_name])
    return nonstandard_model_pluralizations[model_name];
  return model_name.replace(/s$/, '');
}

// when a parent object (like photo_logs) has a field in it for realized subobject (like images (photo_log_images)), make an entry here calling out the local name (so "images", not "photo_log_images").  Used in request replay processing to correctly sync up the two caches (both photo_logs and photo_log_images)
const object_subobject_fields = {
  photo_logs: ['images'],
  boring_logs: ['points'],
  incident_reports: ['images'],
};

// if an object comes down with select_lists, set this to the prefix used.  This assists in priming our cache of select_lists
const select_lists_model_map = {
  boring_logs: 'boring-log',
  ccst_reports: 'ccst',
  incident_reports: 'incident',
  asphalt_reports: 'asphalt',
  soil_reports: 'soil',
  incident_reports: 'incident',
  observation_reports: 'observation',
};

// /photo_logs/1/image/2
// returns { object: 'photo_logs', id: '1', subobject: 'images', subid: '2' }
// also adds the following flags:
// .collection
// .subcollection
// .individual
// .subindividual
// also decodes url parts to the rails model names because we use that as the datastore name in indexdb since it'll be unique

// DEBT: should probably rename `object` to `model`
function decode_api_url(url) {
  const parsed = new URL(url);
  const rtn = { url };
  let parts = parsed.pathname.replace('/api/v1/', '').split('/');
  rtn.kind =
    ['unknown', 'collection', 'individual', 'subcollection', 'subindividual'][
      parts.length
    ] || 'unknown';

  let part = null;
  if ((part = parts.shift())) {
    rtn.object = object_name_to_rails_model_name(part);
    rtn.object_local = part;
  }
  if ((part = parts.shift())) rtn.id = part;
  if (rtn.id) rtn.id = rtn.id.replace('.json', '');
  if ((part = parts.shift())) {
    rtn.subobject = object_name_to_rails_model_name(rtn.object, part);
    rtn.subobject_local = part;
  }
  if ((part = parts.shift())) rtn.subid = part;
  if (rtn.subid) rtn.subid = rtn.subid.replace('.json', '');

  if (rtn.subobject_local == 'approve_report') {
    // these return their full object in their response, so treat these like they're addressing their referenced individual
    rtn.kind = 'individual';
  }

  rtn.object_subobject_fields = object_subobject_fields[rtn.object] || [];
  rtn.object_has_select_lists = !!select_lists_model_map[rtn.object];
  rtn.object_select_list_model = select_lists_model_map[rtn.object];

  if (is_uncommitted_object(rtn.id) || is_uncommitted_object(rtn.subid)) {
    rtn.has_uncommitted_object_reference = true;
  }

  rtn[rtn.kind] = true;
  return rtn;
}

// sometimes we get objects embedded within objects back from the API, like photo_log_images come within photo_logs.
// we use these "specs" to define how those are extracted, and how their related objects are updated in the case of offline handling
const model_subobject_specs = {
  photo_logs: [{ model: 'photo_log_images', local: 'images' }],
  incident_reports: [{ model: 'incident_report_images', local: 'images' }],
  boring_logs: [{ model: 'boring_log_points', local: 'points' }],
};

function subobject_specs_for(model_name) {
  return model_subobject_specs[model_name] || [];
}

const model_superobject_specs = {
  photo_log_images: { photo_logs: 'images' },
  incident_report_images: { incident_reports: 'images' },
  boring_log_points: { boring_logs: 'points' },
};

function superobject_specs_for(model_name) {
  return model_superobject_specs[model_name] || {};
}

async function mock_object_for(target, id, body) {
  cl('mock_object_for', target, id, body);
  const thing = target.subobject || target.object;
  const now = now_string();

  // DEBT: maybe we can use body_to_object(body) out here if we figure out a standard way we want to deal with File objects in FormData.  For now, just use it where we know body is of the correct shape for it

  if (thing === 'photo_logs') {
    body = body_to_object(body);
    // body is an Object
    return {
      id,
      project: await fill('projects', { id: body.photo_log.project_id }),
      user: self(),
      logged_at: now,
      created_at: now,
      updated_at: now,
      images: [],
      // url, (TODO.  needed?)
      has_current_report: false,
      has_any_report: false,
      approved: false,
      // reports, (admin only, TODO.  needed?)
      // audits, (admin only, TODO.  needed?)
    };
  } else if (thing === 'photo_log_images') {
    // body is a FormData
    return {
      id,
      user: self(),
      description: body.get('photo_log_image[description]'),
      created_at: now,
      updated_at: now,
      url: await blob_to_data_url(body.get('photo_log_image[image]')),
    };
  } else if (thing === 'boring_logs') {
    body = body_to_object(body);
    // body is an Object
    return {
      id,
      project: await fill('projects', { id: body.boring_log.project_id }),
      user: self(),
      state: 'draft',
      logged_at: now,
      created_at: now,
      updated_at: now,
      points: [],
      // CONSIDER: instead of pulling from here in the react code, pull from our cache
      select_lists: await select_lists_for('boring_logs'),
      // url
      has_current_report: false,
      has_any_report: false,
      approved: false,
      // reports
      // audits
    };
  } else if (thing === 'boring_log_points') {
    body = body_to_object(body);
    // body is an Object
    return {
      id,
      user: self(),
      boring_log_id: body.boring_log_point.boring_log_id,
      point_id: body.boring_log_point.point_id,
      lithologies: body.boring_log_point.lithologies || [],
      new_lithology: {
        // also boring_log_point model.
        graphic: await default_value_for('boring-log-graphic'),
        color: await default_value_for('boring-log-color'),
        moisture: await default_value_for('boring-log-moisture'),
        consistency: await default_value_for('boring-log-consistency'),
      },
      new_sample: {
        type: await default_value_for('boring-log-sample-type'),
      },
      new_rmr: {
        rock_strength: await default_value_for('boring-log-rock-strength'),
        stratum_rqd: await default_value_for('boring-log-rqd'),
        joint_spacing: await default_value_for('boring-log-joint-spacing'),
        ground_water_condition: await default_value_for(
          'boring-log-ground-water-condition'
        ),
      },
      new_hcsi: {
        hcsi: await default_value_for('boring-log-hcsi'),
      },
      // Maybe later we re-think how these mocks are generated, and use the "field lists" encoded elsewhere for dual-purpose
      created_at: now,
      updated_at: now,
    };
  } else if (thing === 'ccst_reports') {
    body = body_to_object(body);
    // body is an Object
    return {
      id,
      project: await fill('projects', { id: body.ccst_report.project_id }),
      user: self(),
      tickets: [],
      test_reports: [],
      new_result: {
        // copied from rails model
        break_type: await default_value_for('ccst-break-type'),
      },
      new_ticket: {},
      new_test_report: {
        sample_type: await default_value_for('ccst-sample-type'),
        sample_size: await default_value_for('ccst-sample-size'),
        results: [],
      },
      logged_at: now,
      created_at: now,
      updated_at: now,
      // CONSIDER: instead of pulling from here in the react code, pull from our cache
      select_lists: await select_lists_for('ccst_reports'),
      // url
      has_current_report: false,
      has_any_report: false,
      approved: false,
      // reports
      // audits
    };
  } else if (thing === 'asphalt_reports') {
    body = body_to_object(body);
    console.log('y u no here', body);
    // body is an Object
    return {
      id,
      project: await fill('projects', { id: body.asphalt_report.project_id }),
      user: self(),
      tickets: [],
      materials: [],
      new_material: {},
      tests: [],
      new_test: {},
      logged_at: now,
      created_at: now,
      updated_at: now,
      // CONSIDER: instead of pulling from here in the react code, pull from our cache
      select_lists: await select_lists_for('asphalt_reports'),
      // url
      has_current_report: false,
      has_any_report: false,
      approved: false,
      // reports
      // audits
    };
  } else if (thing === 'soil_reports') {
    body = body_to_object(body);
    // body is an Object
    return {
      id,
      project: await fill('projects', { id: body.soil_report.project_id }),
      user: self(),
      tickets: [],
      materials: [],
      new_material: {},
      tests: [],
      new_test: {},
      logged_at: now,
      created_at: now,
      updated_at: now,
      // CONSIDER: instead of pulling from here in the react code, pull from our cache
      select_lists: await select_lists_for('soil_reports'),
      // url
      has_current_report: false,
      has_any_report: false,
      approved: false,
      // reports
      // audits
    };
  } else if (thing === 'incident_reports') {
    body = body_to_object(body);
    // body is an Object
    return {
      id,
      project: await fill('projects', { id: body.incident_report.project_id }),
      user: self(),
      tickets: [],
      equipment: [],
      new_equipment: {},
      statements: [],
      new_statement: {},
      images: [],
      logged_at: now,
      created_at: now,
      updated_at: now,
      // CONSIDER: instead of pulling from here in the react code, pull from our cache
      select_lists: await select_lists_for('incident_reports'),
      // url
      has_current_report: false,
      has_any_report: false,
      approved: false,
      // reports
      // audits
    };
  } else if (thing === 'incident_report_images') {
    // body is a FormData
    return {
      id,
      user: self(),
      created_at: now,
      updated_at: now,
      url: await blob_to_data_url(body.get('incident_report_image[image]')),
    };
  } else if (thing === 'observation_reports') {
    body = body_to_object(body);
    // body is an Object
    return {
      id,
      project: await fill('projects', {
        id: body.observation_report.project_id,
      }),
      user: self(),
      logged_at: now,
      created_at: now,
      updated_at: now,
      // CONSIDER: instead of pulling from here in the react code, pull from our cache
      select_lists: await select_lists_for('observation_reports'),
      // url
      has_current_report: false,
      has_any_report: false,
      approved: false,
      // reports
      // audits
    };
  } else {
    cl(`can't mock object for ${thing}`, target, id, body);
    throw `can't mock object for ${thing}`;
  }
}

function now_string() {
  return new Date().toISOString();
}

function self() {
  const state = store.getState();
  return state.user || {};
}

async function fill(model, data) {
  const cached_item = await getItem(model, data.id);
  return cached_item || data;
}

function blob_to_data_url(blob) {
  return new Promise(function(resolve, reject) {
    const fr = new FileReader();
    fr.onload = e => {
      resolve(e.target.result);
    };
    fr.readAsDataURL(blob);
  });
}

function wrap_array(id, val) {
  return { id, val };
}

function unwrap_array(o) {
  return o.val;
}

async function select_lists_for(model) {
  if (!select_lists_model_map[model]) return [];
  const rtn = [];
  for (const code of await getStoreItemIDs('select_lists')) {
    if (code.startsWith(select_lists_model_map[model])) {
      rtn.push(await getItem('select_lists', code));
    }
  }
  return rtn;
}

async function default_value_for(code) {
  const select_list = await getItem('select_lists', code);
  if (select_list && select_list.options && select_list.options[0]) {
    return Object.keys(select_list.options[0])[0];
  }
  return null;
}

export {
  decode_api_url,
  subobject_specs_for,
  mock_object_for,
  superobject_specs_for,
  singularize,
  wrap_array,
  unwrap_array,
};
