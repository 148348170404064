import { datastore } from '../localforage';

const get_cached_auth_data = async () => {
  const cached = await datastore('root').getItem('auth');
  return cached || { success: false }; // if we have nothing, we're logged out (no user object in response)
};
const write_cached_auth_data = async val => {
  return await datastore('root').setItem('auth', val);
};
const clear_cached_auth_data = async () => {
  return await write_cached_auth_data(null);
};

export { get_cached_auth_data, write_cached_auth_data, clear_cached_auth_data };
