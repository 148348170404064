import { cl } from '../debug';

function unhandled(request, target, msg = null) {
  let str = `don't yet know how to handle online ${target.kind} ${
    request.method
  } ${request.url}`;
  if (msg) {
    str = str + ` (${msg})`;
  }
  cl(str);
  throw str;
}

// coalesces the body data passed into an object
function body_to_object(body) {
  if (body && body.constructor && body.constructor.name) {
    switch (body.constructor.name) {
      case 'String':
        return json_string_to_object(body);
      case 'FormData':
        return formdata_to_object(body);
      case 'Object':
        return body;
      default:
        cl('body_to_object failure', body.constructor.name, body);
        throw `don't know how to deal with passed body of type ${
          body.constructor.name
        }`;
    }
  } else {
    cl('body_to_object failure', body);
    throw "don't know how to deal with passed body";
  }
}

function json_string_to_object(str) {
  try {
    return JSON.parse(str);
  } catch (ex) {
    cl('body passed is not a JSON string', str);
    return {};
  }
}

// DEBT: only handles one layer down
// foo[bar] = 1; foo[baz] = 'a'; bar = 'x'; baz[quux] = 'y';
// =>
// { foo: { bar: 1, baz: 'a'}, bar: 'x', baz: { quux: 'y' } }
function formdata_to_object(formdata) {
  const rtn = {};
  for (let [key, value] of formdata.entries()) {
    if (
      value &&
      value.constructor &&
      value.constructor.name &&
      !['String', 'Number'].includes(value.constructor.name)
    ) {
      cl(
        'formdata_to_object dont know how to handle',
        value.constructor.name,
        key,
        formdata
      );
      throw `cannot handle ${value.constructor.name} in key ${key}`;
    }
    if (key.includes('[')) {
      let parts = key.split('[');
      rtn[parts[0]] = Object.assign(rtn[parts[0]] || {}, {
        [parts[1].replace(']', '')]: value,
      });
    } else {
      rtn[key] = value;
    }
  }
  return rtn;
}

export { unhandled, body_to_object };
