import React, { useState, useEffect } from 'react';
import { Select, SelectItem } from 'carbon-components-react';

function TdSelect(props) {
  const select_list = props.select_list;
  return (
    <Select {...props}>
      {select_list.show_title_option && (
        <SelectItem
          disabled={true}
          value={'none'}
          text={`${select_list.value_title}   (${
            select_list.option_shown_title
          })`}
        />
      )}
      {select_list.options.map(item => {
        const value = Object.keys(item)[0];
        let text = Object.values(item)[0];
        if (select_list.show_text_and_value) {
          text = `${value}    (${text})`;
        }
        return <SelectItem value={value} text={text} key={value} />;
      })}
    </Select>
  );
}

export default TdSelect;
