import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import { full_redux_state } from '../../redux/connectors';
import { to_s } from '../../common';

import ProjectAsphaltReports from './ProjectAsphaltReports';
import AsphaltReport from '../AsphaltReport';
import AsphaltReportMaterial from '../AsphaltReportMaterial';
import AsphaltReportTest from '../AsphaltReportTest';

import { Loading, Button } from 'carbon-components-react';

function AsphaltReports({ user, offline, history }) {
  const [asphalt_reports, setAsphaltReports] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    (async () => {
      const projects = await api.get('projects');
      setProjects(projects);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const asphalt_reports = (await api.get('asphalt_reports')).sort(
        (a, b) => {
          // sort by .logged_at desc
          a = Date.parse(a.logged_at);
          b = Date.parse(b.logged_at);
          if (b < a) return -1;
          if (b > a) return 1;
          return 0;
        }
      );
      setAsphaltReports(asphalt_reports);
    })();
  }, []);

  const handleProjectClick = id =>
    history.push(`/asphalt_reports/projects/${id}`);
  const handleAsphaltReportClick = id => history.push(`/asphalt_reports/${id}`);

  const handleNewLogClick = async project_id => {
    const asphalt_report = api.user_audit_at_helper(
      { project_id },
      'asphalt_report'
    );
    const resp = await api.post('asphalt_reports', null, { asphalt_report });
    if (resp && resp.id) {
      setAsphaltReports([resp].concat(asphalt_reports));
      history.push(`/asphalt_reports/${resp.id}`);
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  };

  const handleUpdateAsphaltReport = new_asphalt_report => {
    if (new_asphalt_report && asphalt_reports)
      setAsphaltReports(
        asphalt_reports.map(asphalt_report =>
          asphalt_report.id == new_asphalt_report.id
            ? new_asphalt_report
            : asphalt_report
        )
      );
  };

  return (
    <Switch>
      <Route
        path="/asphalt_reports/:id/tests/:test_id"
        render={({ match }) => (
          <AsphaltReportTest
            id={match.params.id}
            test_id={match.params.test_id}
            asphalt_reports={asphalt_reports}
            onUpdateAsphaltReport={handleUpdateAsphaltReport}
            history={history}
          />
        )}
      />
      <Route
        path="/asphalt_reports/:id/materials/:material_id"
        render={({ match }) => (
          <AsphaltReportMaterial
            id={match.params.id}
            material_id={match.params.material_id}
            asphalt_reports={asphalt_reports}
            onUpdateAsphaltReport={handleUpdateAsphaltReport}
            history={history}
          />
        )}
      />
      <Route
        path="/asphalt_reports/projects/:id"
        render={({ match }) => {
          const project = (projects || []).find(
            p => to_s(p.id) === match.params.id
          );
          return (
            <ProjectAsphaltReports
              id={match.params.id}
              project={project}
              asphalt_reports={asphalt_reports}
              onDetailClick={handleAsphaltReportClick}
              onNewLogClick={handleNewLogClick}
              onUpdateAsphaltReport={handleUpdateAsphaltReport}
            />
          );
        }}
      />
      <Route
        path="/asphalt_reports/:id"
        render={({ match }) => (
          <AsphaltReport
            id={match.params.id}
            asphalt_reports={asphalt_reports}
            onUpdateAsphaltReport={handleUpdateAsphaltReport}
            history={history}
          />
        )}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export default connect(full_redux_state)(AsphaltReports);
