import React, { Component } from 'react';
// Router here instead of BrowserRouter as Router.  Because we need to create a custom history (for now) due to how logout is implemented outside the <Router>
import { Router, Route, Switch } from 'react-router-dom';
import Link from './components/shared/Link';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import api from './api';
import { v, cl } from './debug';
import { erase_local_storage } from './offline';
import { full_redux_state } from './redux/connectors';
import { logout } from './redux/actions';

import _ from 'underscore';

import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenu,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderContainer,
  SideNav,
  SideNavItems,
  SideNavMenuItem,
  SideNavDivider,
  HeaderSideNavItems,
  HeaderMenuButton,
  SkipToContent,
  Content,
} from 'carbon-components-react/lib/components/UIShell';
import { Button } from 'carbon-components-react';

import { ConnectionSignal16, Renew16 } from '@carbon/icons-react';

import TerradonLogo from '../img/terradon-logo-darkbg.svg';
import TerradonIcon from '../img/terradon-icon.svg';

import {
  Projects,
  PhotoLogs,
  CCSTReports,
  AsphaltReports,
  SoilReports,
  ObservationReports,
  IncidentReports,
  BoringLogs,
  Users,
  MySettings,
  AdminSettings,
  LoginModal,
} from './components';

const history = createBrowserHistory();

function allow_nav(unsaved) {
  if (
    unsaved &&
    !confirm(
      'Your changes will be lost if you leave this page without saving.  Select OK to leave this page and lose changes.'
    )
  ) {
    return false;
  } else return true;
}

class RootRouter extends Component {
  state = {
    showLoginModal: false,
  };

  onLoginClick() {
    this.setState({ showLoginModal: true });
  }

  tool_exposable_in_current_mode(code) {
    if (['projects', 'users'].includes(code)) {
      return this.props.mode === 'admin';
    }
    return true;
  }

  access_to(code) {
    const { user, logged_in, mode } = this.props;

    if (!logged_in) return false;

    if (!this.tool_exposable_in_current_mode(code)) return false;

    if (user.role === 'admin') return true;

    return user.tools && _.some(user.tools, tool => tool.code === code);
  }

  render() {
    const { showLoginModal } = this.state;
    const {
      user,
      logged_in,
      offline,
      syncing,
      unsaved,
      dispatch,
      quota,
    } = this.props;

    const can = code => this.access_to(code);
    const onLogoutClick = async () => {
      let response = await api.delete('auth');
      dispatch(logout());
      history.push('/');
    };
    const to_mb = i => `${Math.floor(i / (1024 * 1024))}MB`;
    const storage_stats = quota
      ? `${to_mb(quota.usage)} of ${to_mb(quota.quota)} used`
      : 'Used Storage Unknown';

    const Logo = (
      <img
        src={TerradonIcon}
        alt="Terradon Icon"
        style={{ height: '60%', marginRight: '1rem' }}
      />
    );
    const LogoText = (
      <img src={TerradonLogo} alt="Terradon Logo" style={{ height: '60%' }} />
    );

    let NetworkStatusIcon = <ConnectionSignal16 />;
    if (offline) {
      NetworkStatusIcon = <Renew16 />;
    }
    if (syncing) {
      NetworkStatusIcon = <Renew16 className="terradon-spin" />;
    }
    return (
      <Router history={history}>
        <HeaderContainer
          render={({ isSideNavExpanded, onClickSideNavExpand }) => {
            const maybeCloseSideNav = () =>
              isSideNavExpanded && onClickSideNavExpand();
            const make_menu_items = function(for_top_nav) {
              let key = 1; // since we loop over MenuItems in render, make sure they have a `key` property.  Increment before access detection so we always have the same numbers no matter which user is logged in
              const elems = [
                !for_top_nav && key++ && can('projects') && (
                  <HeaderMenuItem
                    key={key}
                    element={Link}
                    to="/projects"
                    onClick={maybeCloseSideNav}
                  >
                    All Projects
                  </HeaderMenuItem>
                ),
                // we no longer show individual tools in the nav, leaving here for reference for now
                // !for_top_nav && key++ && can('photo_log') && (
                //   <HeaderMenuItem
                //     key={key}
                //     element={Link}
                //     to="/photo_logs"
                //     onClick={maybeCloseSideNav}
                //   >
                //     Photo Logs
                //   </HeaderMenuItem>
                // ),
                // !for_top_nav && key++ && can('asphalt_report') && (
                //   <HeaderMenuItem
                //     key={key}
                //     element={Link}
                //     to="/asphalt_reports"
                //     onClick={maybeCloseSideNav}
                //   >
                //     Asphalt Reports
                //   </HeaderMenuItem>
                // ),
                // !for_top_nav && key++ && can('ccst_report') && (
                //   <HeaderMenuItem
                //     key={key}
                //     element={Link}
                //     to="/ccst_reports"
                //     onClick={maybeCloseSideNav}
                //   >
                //     Concrete Reports
                //   </HeaderMenuItem>
                // ),
                // !for_top_nav && key++ && can('soil_report') && (
                //   <HeaderMenuItem
                //     key={key}
                //     element={Link}
                //     to="/soil_reports"
                //     onClick={maybeCloseSideNav}
                //   >
                //     Soil and Aggregate Reports
                //   </HeaderMenuItem>
                // ),
                // !for_top_nav && key++ && can('observation_report') && (
                //   <HeaderMenuItem
                //     key={key}
                //     element={Link}
                //     to="/observation_reports"
                //     onClick={maybeCloseSideNav}
                //   >
                //     Observation Reports
                //   </HeaderMenuItem>
                // ),
                // !for_top_nav && key++ && can('incident_report') && (
                //   <HeaderMenuItem
                //     key={key}
                //     element={Link}
                //     to="/incident_reports"
                //     onClick={maybeCloseSideNav}
                //   >
                //     Incident Reports
                //   </HeaderMenuItem>
                // ),
                // !for_top_nav && key++ && can('boring_log') && (
                //   <HeaderMenuItem
                //     key={key}
                //     element={Link}
                //     to="/boring_logs"
                //     onClick={maybeCloseSideNav}
                //   >
                //     Boring Logs
                //   </HeaderMenuItem>
                // ),
                !for_top_nav && key++ && <SideNavDivider key={key} />,
                key++ && can('users') && (
                  <HeaderMenuItem
                    key={key}
                    element={Link}
                    to="/users"
                    onClick={maybeCloseSideNav}
                  >
                    Users
                  </HeaderMenuItem>
                ),
                key++ && can('admin-settings') && (
                  <HeaderMenuItem
                    key={key}
                    element={Link}
                    to="/admin_settings"
                    onClick={maybeCloseSideNav}
                  >
                    Global Presets
                  </HeaderMenuItem>
                ),
                key++ && logged_in && (
                  <HeaderMenuItem
                    key={key}
                    onClick={() => {
                      if (!allow_nav(unsaved)) return;
                      onLogoutClick();
                      maybeCloseSideNav();
                    }}
                  >
                    Log Out
                  </HeaderMenuItem>
                ),
                key++ && (
                  <HeaderMenuItem
                    key={key}
                    onClick={() => {
                      if (!allow_nav(unsaved)) return;
                      window.location.reload(true);
                    }}
                  >
                    Refresh App
                  </HeaderMenuItem>
                ),
                !for_top_nav && key++ && (
                  <HeaderMenuItem key={key}>{storage_stats}</HeaderMenuItem>
                ),
              ].filter(i => !!i);
              return elems;
            };
            const headerMenuItems = make_menu_items(true);
            const sideNavItems = make_menu_items(false);
            return (
              <>
                <Header aria-label="header">
                  <SkipToContent />
                  {logged_in && (
                    <HeaderMenuButton
                      aria-label="Open menu"
                      onClick={onClickSideNavExpand}
                      isActive={isSideNavExpanded}
                    />
                  )}
                  <HeaderName prefix="">
                    <Switch>
                      <Route path="/projects">{Logo}Projects</Route>
                      <Route path="/users">{Logo}Users</Route>
                      <Route path="/photo_logs">{Logo}Photo Logs</Route>
                      <Route path="/asphalt_reports">
                        {Logo}Asphalt Reports
                      </Route>
                      <Route path="/ccst_reports">{Logo}Concrete Reports</Route>
                      <Route path="/soil_reports">{Logo}Soil Reports</Route>
                      <Route path="/observation_reports">
                        {Logo}Observation Reports
                      </Route>
                      <Route path="/incident_reports">
                        {Logo}Incident Reports
                      </Route>
                      <Route path="/boring_logs">{Logo}Boring Logs</Route>
                      <Route path="/admin_settings">{Logo}Global Presets</Route>
                      <Route>{LogoText}</Route>
                    </Switch>
                  </HeaderName>
                  <HeaderNavigation
                    aria-label="Terradon"
                    className="td-header-nav"
                  >
                    {/* <HeaderMenuItem element={Link} to="/">
                      Home
                    </HeaderMenuItem> */}
                    {headerMenuItems}
                  </HeaderNavigation>
                  <HeaderGlobalBar>
                    {logged_in && (
                      <HeaderNavigation aria-label="user settings">
                        <HeaderMenuItem element={Link} to="/my-settings">
                          {this.props.user.name}
                        </HeaderMenuItem>
                      </HeaderNavigation>
                    )}
                    <HeaderGlobalAction aria-label="network status">
                      {NetworkStatusIcon}
                    </HeaderGlobalAction>
                  </HeaderGlobalBar>
                  <SideNav
                    aria-label="Side navigation"
                    expanded={isSideNavExpanded}
                    defaultExpanded={false}
                    isPersistent={false}
                    addFocusListeners={false}
                    addMouseListeners={false}
                    className="td-side-nav"
                  >
                    <SideNavItems>
                      <HeaderSideNavItems>
                        {/* <HeaderMenuItem
                          element={Link}
                          to="/"
                          onClick={onClickSideNavExpand}
                        >
                          Home
                        </HeaderMenuItem> */}
                        {sideNavItems}
                      </HeaderSideNavItems>
                    </SideNavItems>
                  </SideNav>
                </Header>
                <SideNav
                  aria-label="Side navigation rail"
                  className="td-side-nav-desktop mobile-hide"
                  isFixedNav
                  expanded={true}
                  isChildOfHeader={false}
                >
                  <SideNavItems>
                    {/* <HeaderMenuItem element={Link} to="/">
                      Home
                    </HeaderMenuItem> */}
                    {sideNavItems}
                  </SideNavItems>
                </SideNav>
              </>
            );
          }}
        />
        <Content id="main-content">
          <Route path="/" exact component={Projects} />
          <Switch>
            <Route path="/projects/" component={Projects} />
            {can('photo_log') && (
              <Route path="/photo_logs" component={PhotoLogs} />
            )}
            {can('asphalt_report') && (
              <Route path="/asphalt_reports" component={AsphaltReports} />
            )}
            {can('ccst_report') && (
              <Route path="/ccst_reports" component={CCSTReports} />
            )}
            {can('soil_report') && (
              <Route path="/soil_reports" component={SoilReports} />
            )}
            {can('observation_report') && (
              <Route
                path="/observation_reports"
                component={ObservationReports}
              />
            )}
            {can('incident_report') && (
              <Route path="/incident_reports" component={IncidentReports} />
            )}
            {can('boring_log') && (
              <Route path="/boring_logs" component={BoringLogs} />
            )}
            {can('users') && <Route path="/users/" component={Users} />}
            {can('admin-settings') && (
              <Route path="/admin_settings/" component={AdminSettings} />
            )}
            <Route path="/my-settings" component={MySettings} />
          </Switch>
        </Content>
      </Router>
    );
  }
}

export default connect(full_redux_state)(RootRouter);
