import React, { useState, useEffect } from 'react';

function ToolHeadline(props) {
  return (
    <header className="view-headline">
      <h2>
        {props.title}
        {props.children}
      </h2>
    </header>
  );
}

export default ToolHeadline;
