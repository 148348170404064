import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { full_month_day_year_for, month_day_year_for } from '../../datetime';
import { ArrowLeft16 } from '@carbon/icons-react';
import Breadcrumbs from '../shared/Breadcrumbs';

export default function Banner({ back_to, boring_log }) {
  const name = boring_log && boring_log.project ? boring_log.project.name : '';
  const code = boring_log && boring_log.project ? boring_log.project.code : '';
  const when =
    boring_log && boring_log.logged_at
      ? full_month_day_year_for(boring_log.logged_at)
      : '';
  const third = back_to === 'Boreholes' ? back_to : null;

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="boring_logs"
          obj={boring_log}
          second="Boring Logs"
          third={third}
        />

        <p className="project-code">
          {code} / {when}
          {boring_log && boring_log.state == 'draft' && (
            <>
              {' '}
              <span className="draft">draft</span>
            </>
          )}
        </p>
        <p className="name">{name}</p>
      </div>
    </>
  );
}
