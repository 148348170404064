import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import api from '../../api';
import { full_redux_state } from '../../redux/connectors';

import LoginForm from '../LoginForm';
import ProjectForm from './Form';
import ToolHeadline from '../shared/ToolHeadline';

import {
  Loading,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
  Select,
  SelectItem,
} from 'carbon-components-react';

import { Box16 } from '@carbon/icons-react';

class ProjectsIndex extends Component {
  state = {
    project_editing: null, // project being edited, null indicates none are being edited
    show_hidden: false, // show archived projects?
    sort: 'recent-activity', // which sort method (see select below)
  };

  clearEditingMode = () => {
    this.setState({ project_editing: null });
  };

  showArchivedProjects = () => {
    this.setState({ show_hidden: true });
  };

  setSort = e => {
    this.setState({ sort: e.target.value });
  };

  render() {
    const { project_editing, show_hidden, sort } = this.state;
    const {
      user,
      offline,
      logged_in,
      onDetailClick,
      projects,
      onProjectAdd,
      onProjectSave,
      onMarkHidden,
      onMarkUnhidden,
    } = this.props;
    if (!logged_in) return <LoginForm />;

    const is_admin = user.role === 'admin';

    const no_projects_message = offline
      ? `Sorry, you're offline and we don't have any Projects cached`
      : `No Projects created`;
    if (!Array.isArray(projects))
      return <Loading small={false} active={true} />;

    let displayed_projects = projects;
    if (!show_hidden) {
      displayed_projects = displayed_projects.filter(p => !p.hidden);
    }

    if (sort == 'recent-activity') {
      displayed_projects = displayed_projects.sort((a, b) => {
        const sortA = Date.parse(a.activity_at) || 0;
        const sortB = Date.parse(b.activity_at) || 0;
        if (sortA < sortB) {
          return 1;
        }
        if (sortA > sortB) {
          return -1;
        }

        return 0;
      });
    } else if (sort == 'alpha-by-project-name') {
      displayed_projects = displayed_projects.sort((a, b) => {
        const sortA = a.name.toUpperCase();
        const sortB = b.name.toUpperCase();
        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }

        return 0;
      });
    } else if (sort == 'newest') {
      displayed_projects = displayed_projects.sort((a, b) => {
        const sortA = Date.parse(a.created_at);
        const sortB = Date.parse(b.created_at);
        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }

        return 0;
      });
    } else if (sort == 'oldest') {
      displayed_projects = displayed_projects.sort((a, b) => {
        const sortA = Date.parse(a.created_at);
        const sortB = Date.parse(b.created_at);
        if (sortA < sortB) {
          return 1;
        }
        if (sortA > sortB) {
          return -1;
        }

        return 0;
      });
    }

    return (
      <>
        <header className="banner">
          <p className="welcome">Welcome, {user.name}.</p>
        </header>
        <Select
          id="td-sort-by"
          labelText="Sort By"
          className="td-sort-by"
          onChange={this.setSort}
        >
          <SelectItem value="recent-activity" text="Recent Activity" />
          <SelectItem value="alpha-by-project-name" text="A-Z (Project Name)" />
          <SelectItem value="newest" text="Newest Projects" />
          <SelectItem value="oldest" text="Oldest Projects" />
        </Select>
        <StructuredListWrapper className="project-list">
          <StructuredListHead>
            <StructuredListRow head>
              <StructuredListCell head>Client Name</StructuredListCell>
              <StructuredListCell head>Project Name</StructuredListCell>
              <StructuredListCell head>Location</StructuredListCell>
              <StructuredListCell head>Code</StructuredListCell>
              <StructuredListCell head>Actions</StructuredListCell>
            </StructuredListRow>
          </StructuredListHead>

          <StructuredListBody>
            {displayed_projects.length > 0 &&
              displayed_projects.map(project => (
                <StructuredListRow
                  className="pointer"
                  key={project.id}
                  onClick={e => {
                    onDetailClick(project.id);
                    e.stopPropagation();
                  }}
                >
                  <StructuredListCell>{project.client_name}</StructuredListCell>
                  <StructuredListCell>{project.name}</StructuredListCell>
                  <StructuredListCell>
                    {project.location} {project.county && '/ ' + project.county}
                  </StructuredListCell>
                  <StructuredListCell>{project.code}</StructuredListCell>
                  <StructuredListCell>
                    {is_admin && !project.hidden && (
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          this.setState({ project_editing: project });
                        }}
                      >
                        Edit Details
                      </Button>
                    )}
                    {is_admin && project.hidden && (
                      <Button
                        kind="danger"
                        renderIcon={Box16}
                        onClick={e => {
                          e.stopPropagation();
                          onMarkUnhidden(project);
                        }}
                      >
                        Un-Archive
                      </Button>
                    )}
                    {is_admin && !project.hidden && (
                      <Button
                        kind="danger"
                        renderIcon={Box16}
                        onClick={e => {
                          e.stopPropagation();
                          onMarkHidden(project);
                        }}
                      >
                        Archive
                      </Button>
                    )}
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            {displayed_projects.length === 0 && (
              <StructuredListRow>
                <StructuredListCell>{no_projects_message}</StructuredListCell>
              </StructuredListRow>
            )}
          </StructuredListBody>
        </StructuredListWrapper>
        {is_admin && !show_hidden && (
          <Button onClick={this.showArchivedProjects}>
            Show Archived Projects
          </Button>
        )}
        {is_admin && project_editing && (
          <>
            <ToolHeadline title="Edit Project" />
            <ProjectForm
              project={project_editing}
              saveButtonLabel="Save Project"
              cancelButtonLabel="Cancel Edits"
              handleSaveProject={p => {
                onProjectSave(p);
                this.clearEditingMode();
              }}
              handleCancelProjectEdit={this.clearEditingMode}
            />
          </>
        )}
        {is_admin && !project_editing && (
          <>
            <ToolHeadline title="Add New Project" />
            <ProjectForm
              handleAddedProject={p => {
                onProjectAdd(p);
                this.clearEditingMode();
              }}
            />
          </>
        )}
      </>
    );
  }
}

export default connect(full_redux_state)(ProjectsIndex);
