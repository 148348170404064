import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { cl } from '../../debug';
import { full_redux_state } from '../../redux/connectors';
import { to_s } from '../../common';

import ProjectCCSTReports from './ProjectCCSTReports';
import CCSTReport from '../CCSTReport';
import CCSTReportResult from '../CCSTReportResult';
import CCSTReportTicket from '../CCSTReportTicket';
import CCSTReportTestReport from '../CCSTReportTestReport';

import { Loading, Button } from 'carbon-components-react';

function CCSTReports({ user, offline, history }) {
  const [ccst_reports, setCCSTReports] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    (async () => {
      const projects = await api.get('projects');
      setProjects(projects);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const ccst_reports = (await api.get('ccst_reports')).sort((a, b) => {
        // sort by .logged_at desc
        a = Date.parse(a.logged_at);
        b = Date.parse(b.logged_at);
        if (b < a) return -1;
        if (b > a) return 1;
        return 0;
      });
      setCCSTReports(ccst_reports);
    })();
  }, []);

  const handleProjectClick = id => history.push(`/ccst_reports/projects/${id}`);
  const handleCCSTReportClick = id => history.push(`/ccst_reports/${id}`);

  const handleNewLogClick = async project_id => {
    const ccst_report = api.user_audit_at_helper({ project_id }, 'ccst_report');
    const resp = await api.post('ccst_reports', null, { ccst_report });
    if (resp && resp.id) {
      setCCSTReports([resp].concat(ccst_reports));
      history.push(`/ccst_reports/${resp.id}`);
    } else {
      alert(_.map(_.keys(resp), key => `${key} ${resp[key]}`).join('\n'));
    }
  };

  const handleUpdateCCSTReport = new_ccst_report => {
    if (new_ccst_report && ccst_reports)
      setCCSTReports(
        ccst_reports.map(ccst_report =>
          ccst_report.id == new_ccst_report.id ? new_ccst_report : ccst_report
        )
      );
  };

  return (
    <Switch>
      <Route
        path="/ccst_reports/:id/test_reports/:test_report_id/results/:result_id"
        render={({ match }) => (
          <CCSTReportResult
            id={match.params.id}
            test_report_id={match.params.test_report_id}
            result_id={match.params.result_id}
            ccst_reports={ccst_reports}
            onUpdateCCSTReport={handleUpdateCCSTReport}
            history={history}
          />
        )}
      />
      <Route
        path="/ccst_reports/:id/test_reports/:test_report_id"
        render={({ match }) => (
          <CCSTReportTestReport
            id={match.params.id}
            test_report_id={match.params.test_report_id}
            ccst_reports={ccst_reports}
            onUpdateCCSTReport={handleUpdateCCSTReport}
            history={history}
          />
        )}
      />
      <Route
        path="/ccst_reports/:id/tickets/:ticket_id"
        render={({ match }) => (
          <CCSTReportTicket
            id={match.params.id}
            ticket_id={match.params.ticket_id}
            ccst_reports={ccst_reports}
            onUpdateCCSTReport={handleUpdateCCSTReport}
            history={history}
          />
        )}
      />
      <Route
        path="/ccst_reports/projects/:id"
        render={({ match }) => {
          const project = (projects || []).find(
            p => to_s(p.id) === match.params.id
          );
          return (
            <ProjectCCSTReports
              id={match.params.id}
              project={project}
              ccst_reports={ccst_reports}
              onDetailClick={handleCCSTReportClick}
              onNewLogClick={handleNewLogClick}
              onUpdateCCSTReport={handleUpdateCCSTReport}
            />
          );
        }}
      />
      <Route
        path="/ccst_reports/:id"
        render={({ match }) => (
          <CCSTReport
            id={match.params.id}
            ccst_reports={ccst_reports}
            onUpdateCCSTReport={handleUpdateCCSTReport}
            history={history}
          />
        )}
      />
      <Redirect to="/" />
    </Switch>
  );
}

export default connect(full_redux_state)(CCSTReports);
