import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import api from '../../api';
import { full_redux_state } from '../../redux/connectors';

import { Edit16 } from '@carbon/icons-react';

import {
  Loading,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
} from 'carbon-components-react';

function AdminSettings({ select_lists, user, offline, history }) {
  if (!user.role === 'admin') return <p>Unauthorized</p>;
  if (select_lists && select_lists.length) {
    return (
      <>
        <h2>Customizable Select Lists</h2>
        <StructuredListWrapper>
          <StructuredListHead>
            <StructuredListRow head>
              <StructuredListCell head>Name</StructuredListCell>
              <StructuredListCell head>Code</StructuredListCell>
              <StructuredListCell head>Actions</StructuredListCell>
            </StructuredListRow>
          </StructuredListHead>

          <StructuredListBody>
            {select_lists.map(select_list => (
              <StructuredListRow key={select_list.id}>
                <StructuredListCell>{select_list.name}</StructuredListCell>
                <StructuredListCell>{select_list.code}</StructuredListCell>
                <StructuredListCell>
                  <Button
                    renderIcon={Edit16}
                    onClick={() =>
                      history.push(
                        `/admin_settings/select_lists/${select_list.code}`
                      )
                    }
                  >
                    Edit
                  </Button>
                </StructuredListCell>
              </StructuredListRow>
            ))}
          </StructuredListBody>
        </StructuredListWrapper>
      </>
    );
  } else if (select_lists) {
    if (offline) {
      return <p>Offline, so Global Presets cannot be loaded</p>;
    } else {
      return <p>Zero select lists</p>;
    }
  } else {
    return <Loading small={false} active={true} />;
  }
}

export default connect(full_redux_state)(AdminSettings);
