import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import api from '../../api';
import { to_s, date_to_s, date_formats } from '../../common';
import { cl } from '../../debug';
import moment from 'moment';
import { full_month_day_year_for } from '../../datetime';
import { full_redux_state } from '../../redux/connectors';
import * as fh from '../../form_helpers';
import ToolHeadline from '../shared/ToolHeadline';
import { useDidMount, scrollToTopOnMount } from '../shared/on_mount';

import {
  Loading,
  InlineNotification,
  Button,
  TextInput,
  TextArea,
  NumberInput,
  DatePicker,
  DatePickerInput,
  TimePicker,
  Select,
  SelectItem,
  SelectItemGroup,
  Form,
} from 'carbon-components-react';
import { ArrowLeft16, Add16 } from '@carbon/icons-react';
import TdSelect from '../shared/Select';
import Breadcrumbs from '../shared/Breadcrumbs';

function CCSTReportResult({
  id,
  test_report_id,
  result_id,
  user,
  offline,
  ccst_reports,
  onUpdateCCSTReport,
  history,
}) {
  let initial_ccst_report = null;
  if (Array.isArray(ccst_reports)) {
    initial_ccst_report = ccst_reports.find(pl => to_s(pl.id) == id);
  }
  let initial_ccst_test_report = null;
  if (initial_ccst_report && Array.isArray(initial_ccst_report.test_reports)) {
    initial_ccst_test_report = initial_ccst_report.test_reports[test_report_id];
  }
  let initial_ccst_report_result = null;
  if (
    initial_ccst_report &&
    initial_ccst_test_report &&
    Array.isArray(initial_ccst_test_report.results)
  ) {
    initial_ccst_report_result = initial_ccst_test_report.results[result_id];
  }

  const form_keys = [
    'laboratory_number',
    'age_tested',
    'date_tested',
    'weight',
    'diameter1',
    'diameter2',
    'area',
    'total_load',
    'comp_strength',
    'specified_strength',
    'break_type',
  ].reduce((a, v) => ({ ...a, [v]: '' }), {});

  const form_handlers = {}; // used in complex form elements, like checkboxes.  None needed for CCST

  const [ccst_report, setCCSTReport] = useState(initial_ccst_report);
  const editable = true; // was !ccst_report.approved
  const [ccst_report_test_report, setCCSTReportTestReport] = useState(
    initial_ccst_test_report
  );
  const [ccst_report_result, setCCSTReportResult] = useState(
    initial_ccst_report_result
  );
  const didMount = useDidMount();
  useEffect(() => scrollToTopOnMount(didMount), [ccst_report_result, didMount]);

  // BEGIN form handling boilerplate and custom
  const [form_state, _setFormState] = useState({
    ..._.clone(form_keys),
    id: null,
    ...(initial_ccst_report_result ? initial_ccst_report_result : {}),
    invalids: _.mapObject(form_keys, (k, v) => false),
  });

  // behave like old class-based state management: only update keys included in updatedValues
  const setFormState = updatedValues =>
    _setFormState(prevState => {
      return { ...form_state, ...updatedValues };
    });

  const valid = () => {
    // start with everything valid
    let invalids = _.mapObject(form_keys, (k, v) => false);

    // TODO: actual validations here.  Currently there are not supposed to be any.

    const something_invalid = _.chain(_.values(invalids))
      .map(x => !!x)
      .some()
      .value();
    if (something_invalid) {
      setFormState({ invalids });
      return false;
    } else {
      return true;
    }
  };

  const vfb = id =>
    fh.value_field_boilerplate({
      id,
      state: form_state,
      handlers: form_handlers,
      handle_change: event =>
        fh.handle_standard_input({
          event,
          state: form_state,
          setState: s => {
            if (id == 'age_tested') {
              // date_tested computed when age_tested changes as follows
              // if age_tested == 'H' then date_tested = 'H'
              // else if age_tested is parseFloat-able, then add that many days to ccst_report.date_made
              // excel was: =IF(D33="","",IF(D33="H","H",D33+$K$10))  ($K$10 is ccst_report.date_made)
              if (s.age_tested == 'H' || s.age_tested == 'h') {
                s.date_tested = 'H';
              } else {
                const add_days = parseFloat(s.age_tested);
                const date_made = moment(ccst_report.date_made);
                if (date_made.isValid() && (add_days === 0 || add_days)) {
                  s.date_tested = date_made
                    .add(add_days, 'd')
                    .format('YYYY/MM/DD');
                }
              }
            } else if (id == 'area' || id == 'total_load') {
              // =IF(C39="","",ROUND(C39/C38,-1))
              const denominator = parseFloat(s.area || form_state.area);
              const numerator = parseFloat(
                s.total_load || form_state.total_load
              );
              if (!isNaN(numerator) && !isNaN(denominator)) {
                s.comp_strength = (
                  Math.round(numerator / denominator / 10) * 10
                ).toString();
              }
            }
            return setFormState(s);
          },
        }),
    });

  // END form handling boilerplate and custom

  const reloadData = async () => {
    // TODO: lots of floating this up with handleUpdateCCSTReport.... should put this in redux
    const ccst_report = await api.get(`ccst_reports/${id}`);
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    setCCSTReport(ccst_report);
    setCCSTReportTestReport(ccst_report.test_reports[test_report_id]);
    setCCSTReportResult(
      ccst_report.test_reports[test_report_id].results[result_id]
    );
    setFormState(ccst_report.test_reports[test_report_id].results[result_id]);
  };
  useEffect(() => {
    if (!ccst_report) reloadData();
  }, []);

  const project_name =
    ccst_report && ccst_report.project ? ccst_report.project.name : '';
  const code =
    ccst_report && ccst_report.project ? ccst_report.project.code : '';
  const when =
    ccst_report && ccst_report.date_made
      ? full_month_day_year_for(ccst_report.date_made || ccst_report.logged_at)
      : '';
  const to = ccst_report
    ? `/ccst_reports/${ccst_report.id}/test_reports/${test_report_id}`
    : '';

  const handle_save = () => {
    // load our form_state into our ccst_report
    // then percolate that up (onUpdateCCSTReport(ccst_report))
    ccst_report.test_reports[test_report_id].results[result_id] = _.pick(
      form_state,
      _.keys(form_keys)
    );
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    history.push(`/ccst_reports/${id}/test_reports/${test_report_id}#results`);
  };

  const handle_delete = () => {
    ccst_report.test_reports[test_report_id].results.splice(result_id, 1);
    if (onUpdateCCSTReport) onUpdateCCSTReport(ccst_report);
    history.push(`/ccst_reports/${id}/test_reports/${test_report_id}#results`);
  };

  let content = '';
  if (!ccst_report_test_report) {
    content = <Loading small={false} active={true} />;
  } else if (!ccst_report.id && offline) {
    content = (
      <p>
        You are offline, and we do not have this Concrete Report cached, sorry.
      </p>
    );
  } else if (!ccst_report.id && !offline) {
    content = (
      <p>
        You do not have access to that Concrete Report, or it does not exist.
      </p>
    );
  } else {
    content = (
      <>
        <ToolHeadline
          title={`${!editable ? 'Viewing' : 'Editing'} Test Result`}
        />
        <TextInput
          {...vfb('laboratory_number')}
          labelText="Laboratory Number"
        />
        <TextInput {...vfb('age_tested')} labelText="Age Tested" />
        <TextInput {...vfb('date_tested')} labelText="Date Tested" />
        <TextInput {...vfb('weight')} labelText="Weight, lbs" />
        <TextInput {...vfb('diameter1')} labelText="Diameter/Width, in" />
        <TextInput {...vfb('diameter2')} labelText="Diameter/Width, in" />
        <TextInput {...vfb('area')} labelText="Area, sq in" />
        <TextInput {...vfb('total_load')} labelText="Total Load, lbs" />
        <TextInput
          {...vfb('comp_strength')}
          labelText="Comp. Strength, psi (computed)"
          disabled={true}
        />
        <TextInput
          {...vfb('specified_strength')}
          labelText={`Specified Strength (leave blank to use value from Test Report: ${ccst_report_test_report.specified_strength ||
            '<not set>'})`}
        />

        <TdSelect
          {...vfb('break_type')}
          labelText="Break Type"
          select_list={ccst_report.select_lists.find(
            i => i.code === 'ccst-break-type'
          )}
        />
        {editable && (
          <>
            <Button kind="ghost" onClick={handle_delete}>
              Delete Test Result
            </Button>
            <Button onClick={handle_save}>Update Test Result</Button>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="tool-banner">
        <Breadcrumbs
          obj_name="ccst_reports"
          obj={ccst_report}
          second="Concrete Reports"
          third="Concrete Test Report"
        />
        <p className="project-code">
          {code} / {when}
        </p>
        <p className="name">{project_name}</p>
      </div>
      {content}
    </>
  );
}

export default connect(full_redux_state)(CCSTReportResult);
