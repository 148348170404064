import React, { useState } from 'react';
import { connect } from 'react-redux';
import { full_redux_state } from '../../redux/connectors';
import { Link } from 'react-router-dom';
import { month_day_year_for } from '../../datetime';
import { to_s } from '../../common';
import api from '../../api';
import { cl } from '../../debug';
import ToolHeadline from '../shared/ToolHeadline';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Button,
  Loading,
} from 'carbon-components-react';

import {
  ChevronRight16,
  Camera16,
  ArrowLeft16,
  Add16,
} from '@carbon/icons-react';
import Breadcrumbs from '../shared/Breadcrumbs';

// WIP: re-introduce the "View / Make Report" button on desktop

//  /incident_reports/projects/:id -> ./ProjectIncidentReports
function ProjectIncidentReports({
  id /* :id in the matching url */,
  project,
  incident_reports,
  onDetailClick,
  onNewLogClick,
  offline,
  user,
  onUpdateIncidentReport,
}) {
  if (!Array.isArray(incident_reports))
    return <Loading small={false} active={true} />;

  project = project || {};
  incident_reports = incident_reports || [];
  incident_reports = incident_reports.filter(
    pl =>
      (pl.project && to_s(pl.project.id) === id) || to_s(pl.project_id) === id
  );

  const [incident_report_reporting, setIncidentReportReporting] = useState({}); // keys are IDs of IncidentReport with report being generated, empty indicates none are being generated right now

  const [
    approval_modal_incident_report_id,
    setApprovalModalIncidentReportId,
  ] = useState(null);

  const no_logs_message = offline
    ? `Sorry, you're offline and we don't have any Incident Reports cached for this project`
    : `No Incident Reports created for this project`;

  const reporting_button_for = incident_report => {
    if (user.role !== 'admin') return '';

    if (incident_report_reporting[incident_report.id]) {
      return <Loading small={true} active={true} />;
    } else {
      return (
        <>
          {incident_report.has_any_report && (
            <a
              href={api.fixup_url(
                `incident_reports/${incident_report.id}/report`
              )}
              onClick={evt => {
                evt.stopPropagation();
              }}
            >
              Download Approved Report
            </a>
          )}
          {!incident_report.has_current_report && (
            <Button
              onClick={evt => {
                evt.stopPropagation();
                setApprovalModalIncidentReportId(incident_report.id);
              }}
            >
              Preview Report
            </Button>
          )}
        </>
      );
    }
  };

  const handleApproveReport = async report_id => {
    setIncidentReportReporting({
      ...incident_report_reporting,
      [report_id]: true,
    });
    setApprovalModalIncidentReportId(null);

    const response = await api.post(
      `incident_reports/${report_id}/approve_report`
    );

    // TODO: use redux to update the global incident_reports
    onUpdateIncidentReport(response);

    setIncidentReportReporting({
      ...incident_report_reporting,
      [report_id]: false,
    });
  };

  const banner = (
    <div className="tool-banner">
      <Breadcrumbs project_id={project.id} />
      <p className="project-code">{project.code}</p>
      <p className="name">{project.name}</p>
    </div>
  );
  if (approval_modal_incident_report_id) {
    return (
      <>
        {banner}
        <h2>Review and Approve Report</h2>
        <iframe
          className="terradon--preview-modal"
          src={api.fixup_url(
            `incident_reports/${approval_modal_incident_report_id}/preview_report`
          )}
        />
        <div className="terradon--align-right">
          <Button
            kind="ghost"
            onClick={() => setApprovalModalIncidentReportId(null)}
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleApproveReport(approval_modal_incident_report_id)
            }
          >
            Approve
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      {banner}
      <ToolHeadline title="Reports">
        <Button renderIcon={Add16} onClick={() => onNewLogClick(id)}>
          New Report
        </Button>
      </ToolHeadline>
      <StructuredListWrapper selection>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Date</StructuredListCell>
            <StructuredListCell className="mobile-hide" head>
              Report
            </StructuredListCell>
            <StructuredListCell head />
          </StructuredListRow>
        </StructuredListHead>

        <StructuredListBody>
          {incident_reports.length > 0 &&
            incident_reports.map(incident_report => (
              <StructuredListRow
                key={incident_report.id}
                onClick={() => onDetailClick(incident_report.id)}
              >
                <StructuredListCell>
                  {month_day_year_for(incident_report.logged_at)}
                </StructuredListCell>
                <StructuredListCell className="mobile-hide">
                  {reporting_button_for(incident_report)}
                </StructuredListCell>
                <StructuredListCell>
                  <ChevronRight16 />
                </StructuredListCell>
              </StructuredListRow>
            ))}
          {incident_reports.length === 0 && (
            <StructuredListRow>
              <StructuredListCell>{no_logs_message}</StructuredListCell>
            </StructuredListRow>
          )}
        </StructuredListBody>
      </StructuredListWrapper>
    </>
  );
}

export default connect(full_redux_state)(ProjectIncidentReports);
