import { cl } from '../debug';
import { datastore, getItem, setItem } from '../localforage';
import { to_s } from '../common';

// temp IDs are prefixed with the following
const uncommitted_object_prefix = 'tmp-';

const store_name = 'temp_ids';
const last_used_storage_key = 'last_used';

// cache stuff in memory (we load at app start) so we can retrieve data syncronously
const store_cache = {};

function initialize_store() {
  datastore(store_name).iterate((value, key, i) => {
    if (to_s(key).startsWith(uncommitted_object_prefix)) {
      store_cache[key] = value;
    }
  });
}

// given a temp_id, what is the real ID that this resolves to?
// if there isn't one, just return the temp_id
function real_id_for(temp_id) {
  if (to_s(temp_id).startsWith(uncommitted_object_prefix)) {
    return store_cache[temp_id] || temp_id;
  } else {
    return temp_id;
  }
}

// returns the next temp id
async function generate_temp_id() {
  // DEBT: could make this a transaction in indexdb instead of using localforage wrapper
  const last = (await getItem(store_name, last_used_storage_key)) || 0;
  await setItem(store_name, last_used_storage_key, last + 1);
  return `${uncommitted_object_prefix}${last + 1}`;
}

// given a temp id and an id, permanently stores that temp id maps to id
async function resolve_temp_id_to(temp_id, id) {
  await setItem(store_name, temp_id, id);
  store_cache[temp_id] = id;
}

// given an object with an id, or a string, tests whether or not that string or id is prefixed with `uncommitted_object_prefix`
function is_uncommitted_object(thing) {
  if (!thing) return false;
  if (thing && thing.constructor && thing.constructor.name === 'Object') {
    thing = thing.id;
  }
  return to_s(thing).startsWith(uncommitted_object_prefix);
}

function update_url_temp_ids(url) {
  return url
    .split('/')
    .map(p =>
      p
        .split('?')
        .map(sp =>
          sp
            .split('.')
            .map(q => real_id_for(q))
            .join('.')
        )
        .join('?')
    )
    .join('/');
}

export {
  initialize_store,
  real_id_for,
  generate_temp_id,
  resolve_temp_id_to,
  is_uncommitted_object,
  update_url_temp_ids,
};
