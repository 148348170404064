import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { settings } from 'carbon-components';
import {
  CheckmarkFilled16 as CheckmarkFilled,
  ChevronDown16,
} from '@carbon/icons-react';
import { matches } from './internal/keyboard';
import { Enter, Space } from './internal/keyboard/keys';
const keys = { Enter, Space };
// import { composeEventHandlers } from '../../tools/events';

const composeEventHandlers = fns => (event, ...args) => {
  for (let i = 0; i < fns.length; i++) {
    if (event.defaultPrevented) {
      break;
    }
    if (typeof fns[i] === 'function') {
      fns[i](event, ...args);
    }
  }
};

const { prefix } = settings;

/* copied from Carbon Components (Tile/Tile.js) as-of 10.7.4 / 7.7.4, modified as follows:

 * use keys.Enter, keys.Space directly from ./internal/keyboard/keys because we couldn't get parcel to build with "export * as keys from './keys';" due to "support for the experimental syntax 'exportNamespaceFrom' isn't currently enabled (8:10)"

 * will open by clicking / keyboarding anywhere
 * when terradonSimplifyClose prop set, once open, only closes by clicking or keyboarding on chevron
 * terradonContentStillLoading prop which, if set to true, will cause a recomputation of the contained object height each time a render() happens, until the parent component flips this to False.  Default false.  Useful with images where we don't know the height until it's loaded

 NOTE: as of this writing, using the keyboard on the close chevron doesn't work on their implementation, either

 * updated 2021/01/18 for Carbon v10.26.0 (`internal` needed updates, ExpandableTile.js did not)
 * updated 2021/06/03 for Carbon v10.36.0 (minor updates to carbon version allowed us to back out a terradon* property)
 
 Update Notes:
 $ cd carbon-components ; git pull origin main
 $ cd packages/react/src ; diff -bBr ~/terradon/frontend/js/components/carbon/internal internal  
 * as-of now, the only change we made is commenting out an export in internal/keyboard/index.js
 $ cp -a internal ~/terradon/frontend/js/components/carbon 
 $ vi ~/terradon/frontend/js/components/carbon/internal/keyboard/index.js 
 $ cd ~/terradon/ ; cp ~/carbon-components/packages/react/src/components/Tile/Tile.js Tile-carbon.js
 $ prettier Tile-carbon.js > Tile-carbon-pretty.js  # use our formatting rules, not theirs
 $ bbdiff --ignore-spaces Tile-carbon-pretty.js frontend/js/components/carbon/ExpandableTile.js  # manually confirm there are no additions to ExpandableTile we need to add
*/

export class ExpandableTile extends Component {
  state = {};

  static propTypes = {
    /**
     * The child nodes.
     */
    children: PropTypes.node,

    /**
     * The CSS class names.
     */
    className: PropTypes.string,

    /**
     * `true` if the tile is expanded.
     */
    expanded: PropTypes.bool,

    /**
     * Specify the function to run when the ExpandableTile is clicked
     */
    handleClick: PropTypes.func,

    /**
     * An ID that can be provided to aria-labelledby
     */
    id: PropTypes.string,

    /**
     * `true` to use the light version. For use on $ui-01 backgrounds only.
     * Don't use this to make tile background color same as container background color.
     */
    light: PropTypes.bool,

    /**
     * optional handler to decide whether to ignore a click. returns false if click should be ignored
     */
    onBeforeClick: PropTypes.func,

    /**
     * optional handler to trigger a function the Tile is clicked
     */
    onClick: PropTypes.func,

    /**
     * optional handler to trigger a function when a key is pressed
     */
    onKeyUp: PropTypes.func,

    /**
     * The `tabindex` attribute.
     */
    tabIndex: PropTypes.number,

    /**
     * The description of the "collapsed" icon that can be read by screen readers.
     */
    tileCollapsedIconText: PropTypes.string,

    /**
     * When "collapsed", a label to appear next to the chevron (e.g., "View more").
     */
    tileCollapsedLabel: PropTypes.string,

    /**
     * The description of the "expanded" icon that can be read by screen readers.
     */
    tileExpandedIconText: PropTypes.string,

    /**
     * When "expanded", a label to appear next to the chevron (e.g., "View less").
     */
    tileExpandedLabel: PropTypes.string,

    /**
     * `true` to not capture mouse or keyboard close outside of the close handle.  This supports input elements like buttons etc inside the expanded area
     */
    terradonSimplifyClose: PropTypes.bool,

    /**
     * set to `false` when all content in tile has been loaded.  Allows us to trigger re-render when a contained image has been loaded, so we can compute height correctly
     */
    terradonContentStillLoading: PropTypes.bool,
  };

  static defaultProps = {
    tabIndex: 0,
    expanded: false,
    tileMaxHeight: 0,
    tilePadding: 0,
    onBeforeClick: () => true,
    handleClick: () => {},
    tileCollapsedIconText: 'Interact to expand Tile',
    tileExpandedIconText: 'Interact to collapse Tile',
    light: false,
    terradonSimplifyClose: false,
    terradonContentStillLoading: false,
  };

  static getDerivedStateFromProps(
    // eslint-disable-next-line react/prop-types
    { expanded, tileMaxHeight, tilePadding },
    state
  ) {
    const {
      prevExpanded,
      prevTileMaxHeight,
      prevTilePadding,
      expanded: currentExpanded,
      tileMaxHeight: currentTileMaxHeight,
      tilePadding: currentTilePadding,
    } = state;
    const expandedChanged = prevExpanded !== expanded;
    const tileMaxHeightChanged = prevTileMaxHeight !== tileMaxHeight;
    const tilePaddingChanged = prevTilePadding !== tilePadding;
    return !expandedChanged && !tileMaxHeightChanged && !tilePaddingChanged
      ? null
      : {
          expanded: !expandedChanged ? currentExpanded : expanded,
          tileMaxHeight: !tileMaxHeightChanged
            ? currentTileMaxHeight
            : tileMaxHeight,
          tilePadding: !tilePaddingChanged ? currentTilePadding : tilePadding,
          prevExpanded: expanded,
          prevTileMaxHeight: tileMaxHeight,
          prevTilePadding: tilePadding,
        };
  }

  componentDidMount = () => {
    if (this.tile) {
      const getStyle = window.getComputedStyle(this.tile, null);

      if (this.aboveTheFold) {
        this.setState({
          tileMaxHeight: this.aboveTheFold.getBoundingClientRect().height,
          tilePadding:
            parseInt(getStyle.getPropertyValue('padding-top'), 10) +
            parseInt(getStyle.getPropertyValue('padding-bottom'), 10),
        });
      }
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.expanded !== this.props.expanded) this.setMaxHeight();
  };

  setMaxHeight = () => {
    if (this.state.expanded ? this.tileContent : this.aboveTheFold) {
      this.setState({
        tileMaxHeight: this.state.expanded
          ? this.tileContent.getBoundingClientRect().height
          : this.aboveTheFold.getBoundingClientRect().height,
      });
    }
  };

  terradonHandleEvent = (evt, handler) => {
    if (this.state.expanded && this.props.terradonSimplifyClose) return;
    handler(evt);
  };

  handleClick = evt => {
    if (!this.props.onBeforeClick(evt) || evt.target.tagName === 'INPUT')
      return;
    evt.persist();
    this.setState(
      {
        expanded: !this.state.expanded,
      },
      () => {
        this.setMaxHeight();
        this.props.handleClick(evt);
      }
    );
  };

  handleKeyUp = evt => {
    if (evt.target !== this.tile) {
      if (matches(evt, [keys.Enter, keys.Space])) {
        evt.preventDefault();
        evt.stopPropagation();
        evt.persist();
        this.setState(
          {
            expanded: !this.state.expanded,
          },
          () => {
            this.setMaxHeight();
            this.props.handleClick(evt);
          }
        );
      }
    }
  };

  getChildren = () => {
    return React.Children.toArray(this.props.children);
  };

  render() {
    const {
      tabIndex,
      className,
      expanded, // eslint-disable-line
      tileMaxHeight, // eslint-disable-line
      tilePadding, // eslint-disable-line
      handleClick, // eslint-disable-line
      onClick,
      onKeyUp,
      tileCollapsedIconText,
      tileExpandedIconText,
      tileCollapsedLabel,
      tileExpandedLabel,
      onBeforeClick, // eslint-disable-line
      light,
      terradonSimplifyClose,
      terradonContentStillLoading,
      ...other
    } = this.props;

    const { expanded: isExpanded } = this.state;

    const classes = classNames(
      `${prefix}--tile`,
      `${prefix}--tile--expandable`,
      {
        [`${prefix}--tile--is-expanded`]: isExpanded,
        [`${prefix}--tile--light`]: light,
      },
      className
    );

    if (terradonContentStillLoading && this.tileContent) {
      // force recomputation of tileMaxHeight if we've already loaded, and we're re-rendered while content is still loading
      setTimeout(() => this.setMaxHeight(), 100);
    }

    const tileStyle = {
      maxHeight: isExpanded
        ? null
        : this.state.tileMaxHeight + this.state.tilePadding,
    };

    const childrenAsArray = this.getChildren();

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <button
        type="button"
        ref={tile => {
          this.tile = tile;
        }}
        style={tileStyle}
        className={classes}
        aria-expanded={isExpanded}
        title={isExpanded ? tileExpandedIconText : tileCollapsedIconText}
        {...other}
        onKeyUp={composeEventHandlers([
          onKeyUp,
          evt => this.terradonHandleEvent(evt, this.handleKeyUp),
        ])}
        onClick={composeEventHandlers([
          onClick,
          evt => this.terradonHandleEvent(evt, this.handleClick),
        ])}
        tabIndex={tabIndex}
      >
        <div
          ref={tileContent => {
            this.tileContent = tileContent;
          }}
        >
          <div
            ref={aboveTheFold => {
              this.aboveTheFold = aboveTheFold;
            }}
            className={`${prefix}--tile-content`}
          >
            {childrenAsArray[0]}
          </div>
          <div className={`${prefix}--tile__chevron`}>
            <span className={`terradon--tile-content--expand-icon-text`}>
              {isExpanded ? tileExpandedLabel : tileCollapsedLabel}
            </span>
            <ChevronDown16 />
          </div>
          <div className={`${prefix}--tile-content`}>{childrenAsArray[1]}</div>
        </div>
      </button>
    );
  }
}
