import _ from 'underscore';
import api from './api';
import { cl } from './debug';

function handle_standard_input({ event, state, setState }) {
  return handle_nonstandard_input({
    field: event.target.name,
    value: event.target.value,
    state,
    setState,
  });
}

function handle_nonstandard_input({ field, value, state, setState }) {
  const invalids = state.invalids || {};
  invalids[field] = false;
  setState({ [field]: value, invalids });
}

function clear_form(form_keys, setState) {
  setState({ ...form_keys });
}

function validate(invalids, field, bool, msg) {
  if (!bool) {
    invalids[field] = msg;
  }
  return invalids;
}

// helper: unpack our validity data structure to map to what the carbon components are expecting
function invalid_for(state, id) {
  if (!!state.invalids[id]) {
    return {
      invalid: !!state.invalids[id],
      invalidText: state.invalids[id],
    };
  } else {
    return {};
  }
}

// helper: these are the standard props we pass to most input components
function field_boilerplate({ id, state, handlers, id_for, handle_change }) {
  handlers = handlers || [];
  id_for = id_for || (id => id); // pass a better function if, for instance, you want to namespace

  return {
    id: id_for(id),
    name: id,
    onChange: handlers[id] ? handlers[id] : handle_change,
    ...invalid_for(state, id),
  };
}

// helper: covers most carbon react components that need just a simple `value`.
// TODO: add boilerplate for checkboxes
function value_field_boilerplate({
  id,
  state,
  handlers,
  id_for,
  handle_change,
  default_value,
}) {
  return {
    ...field_boilerplate({ id, state, handlers, id_for, handle_change }),
    value: state[id] || default_value || '',
  };
}

// helper: convert the create response body to an error string
function create_response_error_to_string(response) {
  return _.map(_.keys(response), key => `${key} ${response[key]}`).join('\n');
}

// helpers: are we creating or updating an object?
function is_create(state) {
  return !state.id;
}
function is_update(state) {
  return !!state.id;
}

// helper: is the API response body for the action an OK?
function is_ok_response(response) {
  return response && response.id;
}

async function handle_submit({
  e,
  endpoint,
  model,
  state,
  fields,
  valid,
  onCreate,
  onUpdate,
  clear_form,
  onSuccess,
}) {
  e.preventDefault();
  if (valid()) {
    try {
      let api_method = api.post;
      let data_to_post = _.pick(state, _.keys(fields));
      if (is_update(state)) {
        api_method = api.put;
        endpoint = `${endpoint}/${state.id}.json`;
      }
      data_to_post = api.user_audit_at_helper(data_to_post, model);

      const resp = await api_method(endpoint, null, {
        [model]: data_to_post,
      });
      if (is_ok_response(resp)) {
        clear_form();
        if (is_create(state)) {
          onCreate && onCreate(resp);
          onSuccess && onSuccess();
        } else if (is_update(state)) {
          onUpdate && onUpdate(resp);
          onSuccess && onSuccess();
        } else {
          alert('internal error');
        }
      } else {
        alert(create_response_error_to_string(resp));
      }
    } catch (err) {
      alert('Sorry, there was an unexpected error.');
      cl(err);
    }
  }
}

function is_true(x) {
  return x === true || x === 'true';
}

export {
  handle_standard_input,
  clear_form,
  validate,
  create_response_error_to_string,
  is_create,
  is_update,
  is_ok_response,
  handle_submit,
  invalid_for,
  value_field_boilerplate,
  field_boilerplate,
  handle_nonstandard_input,
  is_true,
};
